<template>
  <vx-card>
    <div class="vx-col mb-6 mt-3">
      <div class="vx-row w-1/2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="clRequestId" type="text" disabled />
        </div>
      </div>
      <div class="vx-row w-1/2 mt-2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="territory" type="text" disabled />
        </div>
      </div>
      <div class="vx-row w-1/2 mt-2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group1</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="customerGroup1" type="text" disabled />
        </div>
      </div>
    </div>

    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data.credit_limit_reguler_customers"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      style="overflow-y: auto"
    >
      <template slot="header"> </template>
      <template slot="thead">
        <vs-th sort-key="option">Customer Codes</vs-th>
        <vs-th sort-key="so_data">SO Data </vs-th>
        <vs-th sort-key="ar_data">Active Month Transaction</vs-th>
        <vs-th sort-key="billing_data">Billing Data</vs-th>
        <vs-th sort-key="payment_data">Payment Data</vs-th>
        <vs-th sort-key="current_cl">Current CL</vs-th>
        <vs-th sort-key="suggested_cl">Suggested CL</vs-th>
        <vs-th sort-key="new_cl">New CL</vs-th>
        <vs-th sort-key="exclude">Exclude</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.customer_code }}</vs-td>
          <vs-td>
            <ul>
              <li>Total Sales : {{ tr.total_sales | numberFormat }}</li>
              <li>
                Average Sales : {{ tr.total_average_sales | numberFormat }}
              </li>
            </ul></vs-td
          >
          <vs-td>
            <ul
              :data="ts"
              :key="i"
              v-for="(ts, i) in tr.credit_limit_reguler_average_sales"
            >
              <li>
                {{ ts.month_average | monthFormat }} :
                {{ ts.month_average_total | numberFormat }}
              </li>
            </ul>
          </vs-td>
          <vs-td
            >Active Transaction :{{ tr.active_transaction }} <br />
            AR Overdue :{{ tr.ar_overdue | numberFormat }}</vs-td
          >
          <vs-td>
            Average Payment:{{ tr.average_payment }}<br />
            Number Late Payment:{{ tr.num_of_late_payment }}<br />
            Average Late Payment:{{ tr.average_late_payment }}
          </vs-td>
          <vs-td>{{ tr.current_credit_limit | numberFormat }}</vs-td>
          <vs-td>{{ tr.suggested_credit_limit | numberFormat }}</vs-td>
          <vs-td>
            <!-- <vs-input
              v-if="show == false"
              v-model="data[indextr].credit_limit_request"
              type="number"
              min="1"
            /> -->
            <NumberInput
              v-if="show == false"
              v-model="data[indextr].credit_limit_request"
              min="1"
            />

            <span v-else>{{ tr.credit_limit_request }}</span>
          </vs-td>

          <vs-td>
            <vs-checkbox
              v-if="show == false"
              v-model="data[indextr].is_exclude"
              type="checkbox"
              placeholder="Exclude"
            />
            <vs-checkbox
              v-else
              v-model="data[indextr].is_exclude"
              type="checkbox"
              placeholder="Exclude"
              disabled
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-12" v-if="show == false">
      <div class="vx-col w-full flex items-center space-x-2">
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-save"
          @click="handleSave()"
          >Save</vs-button
        >
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-printer"
          @click="handlePrint()"
          >Print</vs-button
        >
        <vs-button
          class="mt-2"
          color="success"
          type="border"
          icon-pack="feather"
          icon="icon-download"
          @click="exportData()"
          >Export</vs-button
        >
        <vs-button
          class="mt-2"
          color="danger"
          type="border"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="closeDetail()"
          >Back</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12" v-else>
      <div class="vx-col w-full flex items-center space-x-2">
        <vs-button
          class="mt-2"
          color="danger"
          type="border"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="closeDetail()"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import NumberInput from "@/components/master/NumberInput.vue";

export default {
  components: { NumberInput },
  props: {
    id: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteId: null,
      clRequestId: "",
      territory: "",
      customerGroup1: "",
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      table: this.tableDefaultState(),
    };
  },
  mounted() {
    this.getData();
    //set default all data[indextr].credit_limit_request is current_credit_limit
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
      this.$emit("closeDetail", params);
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-regulers-id", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            // sort: this.table.sort,
            id: this.id,
            is_done: this.done,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data;
            this.clRequestId = resp.data.code;
            this.territory = resp.data.territory_name;
            if (resp.data.credit_limit_customer_group != null) {
              this.customerGroup1 = "";
              for (
                let i = 0;
                i < resp.data.credit_limit_customer_group.length;
                i++
              ) {
                if (
                  resp.data.credit_limit_customer_group[i].customer_group_id !=
                  0
                ) {
                  this.customerGroup1 +=
                    resp.data.credit_limit_customer_group[i].code + ", ";
                } else {
                  this.customerGroup1 = "ALL";
                }
              }
            } else {
              this.customerGroup1 = "ALL";
            }
            // for (
            //   let i = 0;
            //   i < resp.data.credit_limit_reguler_customer_group.length;
            //   i++
            // ) {
            //   if (
            //     resp.data.credit_limit_reguler_customer_group[i]
            //       .customer_group_id != 0
            //   ) {
            //     this.customerGroup1 +=
            //       resp.data.credit_limit_reguler_customer_group[i].code + ", ";
            //   } else {
            //     this.customerGroup1 = "ALL";
            //   }
            // }

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    exportData() {
      if (this.id != null) {
        this.$http
          .get("/api/v1/master/credit-limit-regulers-export", {
            params: {
              title:
                "Credit_Limit_Reguler" +
                "OMS" +
                "_" +
                this.dateStart +
                "_-_" +
                this.dateEnd,
              type_tab: "WMS",
              credit_limit_reguler_id: this.id,
            },
          })
          .then((resp) => {
            console.log("rrrrrrr", resp);
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "Export data success",
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-check-circle",
              });
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select data first",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
    // handleSave() {
    //   let creditLimitRegulerCustomer =
    //     this.table.data.credit_limit_reguler_customers;
    //   let isExclude = creditLimitRegulerCustomer.filter(
    //     (v) => v.is_exclude == false
    //   );
    //   let isExcludeCreditLimitRequest = isExclude.filter(
    //     (v) => v.credit_limit_request == v.current_credit_limit
    //   );
    //   let newCLisNullOrZero = creditLimitRegulerCustomer.filter(
    //     (v) => v.credit_limit_request == 0 || v.credit_limit_request == null
    //   );
    //   let newCLisLessThanCurrent = creditLimitRegulerCustomer.filter(
    //     (v) => v.credit_limit_request > v.current_credit_limit
    //   );
    //   if (newCLisNullOrZero.length > 0) {
    //     this.$vs.loading.close();
    //     this.$vs.notify({
    //       title: "Error",
    //       text: "New Credit Limit cant be 0 or null",
    //       color: "danger",
    //       iconPack: "feather",
    //       position: "top-right",
    //       icon: "icon-alert-circle",
    //     });
    //     return;
    //   }
    //   if (newCLisLessThanCurrent.length > 0) {
    //     this.$vs.loading.close();
    //     this.$vs.notify({
    //       title: "Error",
    //       text: "New Credit Limit cant be bigger than Current Credit Limit",
    //       color: "danger",
    //       iconPack: "feather",
    //       position: "top-right",
    //       icon: "icon-alert-circle",
    //     });
    //     return;
    //   }
    //   if (isExcludeCreditLimitRequest.length > 0) {
    //     this.$vs.loading.close();
    //     this.$vs.notify({
    //       title: "Error",
    //       text: "Credit Limit Request cant same with Current Credit Limit",
    //       color: "danger",
    //       iconPack: "feather",
    //       position: "top-right",
    //       icon: "icon-alert-circle",
    //     });
    //     return;
    //   }
    //   this.$vs.loading();
    //   this.table.data.credit_limit_reguler_customers.forEach((element) => {
    //     element.credit_limit_reguler_id = Number(
    //       element.credit_limit_reguler_id
    //     );
    //     element.is_exclud = element.is_exclude;
    //
    //     element.credit_limit_request = Number(element.credit_limit_request);
    //     delete element.is_exclude;
    //   });
    //   this.$http
    //     .put("/api/v1/master/credit-limit-regulers", {
    //       credit_limit_customer: this.table.data.credit_limit_reguler_customers,
    //       id: this.id,
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           title: "Success",
    //           text: resp.message,
    //           color: "success",
    //           iconPack: "feather",
    //           position: "top-right",
    //           icon: "icon-check-circle",
    //         });
    //         this.$vs.loading.close();
    //         this.closeDetail(false);
    //       } else {
    //         this.$vs.loading.close();
    //         this.$vs.notify({
    //           title: "Error",
    //           text: resp.message,
    //           color: "danger",
    //           iconPack: "feather",
    //           icon: "icon-alert-circle",
    //         });
    //       }
    //     });
    // },
    handleSave() {
      let creditLimitRegulerCustomer =
        this.table.data.credit_limit_reguler_customers;
      let newCLisNullOrZero = creditLimitRegulerCustomer.filter(
        (v) => v.credit_limit_request == 0 || v.credit_limit_request == null
      );

      if (newCLisNullOrZero.length > 0) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "New Credit Limit can't be 0 or null",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle",
        });
        return;
      }

      for (let customer of creditLimitRegulerCustomer) {
        // if (customer.current_credit_limit > customer.suggested_credit_limit) {
        //   if (customer.credit_limit_request > customer.current_credit_limit) {
        //     this.$vs.loading.close();
        //     this.$vs.notify({
        //       title: "Error",
        //       text: "New Credit Limit can't be greater than Current Credit Limit when Current Credit Limit is greater than Suggested Credit Limit",
        //       color: "danger",
        //       iconPack: "feather",
        //       position: "top-right",
        //       icon: "icon-alert-circle",
        //     });
        //     return;
        //   }
        // } else {
        if (customer.credit_limit_request > customer.suggested_credit_limit) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "New Credit Limit can't be greater than Suggested Credit Limit when Suggested Credit Limit is greater than Current Credit Limit",
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
          });
          return;
        }
        // }
      }

      this.$vs.loading();
      this.table.data.credit_limit_reguler_customers.forEach((element) => {
        element.credit_limit_reguler_id = Number(
          element.credit_limit_reguler_id
        );
        element.is_exclud = element.is_exclude;
        element.credit_limit_request = Number(element.credit_limit_request);
        delete element.is_exclude;
      });

      this.$http
        .put("/api/v1/master/credit-limit-regulers", {
          credit_limit_customer: this.table.data.credit_limit_reguler_customers,
          id: this.id,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: resp.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check-circle",
            });
            this.$vs.loading.close();
            this.closeDetail(false);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }
        });
    },
    // handlePrint for print pdf using jsPDF:
    handlePrint() {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `CREDIT_LIMIT_REGULER${currentDate}`;
      this.$http
        .get("/api/v1/master/credit-limit-regulers-id", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            // sort: this.table.sort,
            id: this.id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();

          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            let data = resp.data;
            const doc = new jsPDF("l", "mm", [297, 210]);
            console.log("YYYY", resp);

            let creditLimitRegulerCustomer =
              data.credit_limit_reguler_customers;

            // const createdAt = this.dateFormat(collectionPlan.date);
            let date = moment(data.valid_from).format("DD MMM YYYY");

            let fontSize = 9;

            doc.setFont("helvetica").setFontSize(fontSize);

            doc.text("Credit Limit Reguler", 10, 10);

            doc.text("Date : " + date, 10, 15);

            doc.text("CL Request ID : " + data.code, 10, 20);

            doc.text("Territory : " + data.territory_name, 10, 25);

            doc.text(
              "Customer Group1 : " + data.customer_group_name != ""
                ? data.customer_group_name
                : "ALL",
              10,
              30
            );

            fontSize = 9;
            doc.setFont("helvetica").setFontSize(fontSize);

            const head = [
              [
                "no",
                "Customer Code",
                "Total Sales",
                "Average Sales",
                "Active Transaction",
                "AR Overdue",
                "Average Payment",
                "Num Of Late Payment",
                "Average Late Payment",
                "Current Credit Limit",
                "Suggested Credit Limit",
                "New Credit Limit",
                "Exclude",
              ],
            ];

            let line = [];

            // console.log("v1", writeOffLines[0])
            // console.log("v2", writeOffLines[1])

            let no = 1;
            creditLimitRegulerCustomer.map((v, i) => {
              console.log("LLL", v);
              line.push([
                no++,
                v.customer_code,
                v.total_sales,
                v.total_average_sales,
                v.active_transaction,
                v.ar_overdue,
                v.average_payment,
                v.num_of_late_payment,
                v.average_late_payment,
                v.current_credit_limit,
                v.suggested_credit_limit,
                v.credit_limit_request,
                v.is_exclude,
              ]);
            });
            autoTable(doc, {
              startY: 45,
              head: head,
              body: line,
              margin: { right: 10, left: 10 },
              theme: "grid",
              columnStyles: {
                0: {
                  fontSize: 8,
                  cellWidth: 8,
                },
                1: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 24,
                },
                2: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 25,
                },
                3: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 15,
                },
                4: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 20,
                },
                5: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 25,
                },
                6: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 24,
                },
                7: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 24,
                },
                8: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 22,
                },
                9: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 20,
                },
                10: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 15,
                },
                11: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 25,
                },
                12: {
                  halign: "middle",
                  fontSize: 8,
                  cellWidth: 30,
                },
              },
            });

            doc.save("credit-limit-reguler" + fileTitle + ".pdf");
            this.$vs.loading.close();
          }
        });
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    table: {
      handler() {
        this.table.data.credit_limit_reguler_customers.forEach((element) => {
          if (element.credit_limit_request < 0) {
            element.credit_limit_request = 1;
          }
        });
      },
      deep: true,
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    numberFormat(value) {
      if (value) {
        if (value > 0) {
          value = Math.round(value);
          return Intl.NumberFormat("en", {
            minimumFractionDigits: 0,
          }).format(value);
        } else {
          return 0;
        }
        // return Intl.NumberFormat("en", {
        //   minimumFractionDigits: 0,
        // }).format(value);
      }
    },
    monthFormat(value) {
      if (value) {
        return moment()
          .month(value - 1)
          .format("MMMM");
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
