<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Date"
          name="Invoice Date"
          v-model="table.inv_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          :value="formatDate(dueDate)"
          readonly
        />
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        :value="formatDate(createdDate)"
        readonly
      />
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          v-model="table.payment_term_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          v-model="table.bank_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Invoice Number"
          name="Tax Invoice Number"
          v-model="tr_number"
          placeholder="Tax Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Invoice Date"
          name="Tax Invoice Date"
          v-model="tr_date"
          placeholder="Tax Invoice Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty Inv</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>WH Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].quantity }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].net }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].discount }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].subtotal }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].wh_tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider style="width: 100%; margin-left: 0%"
      ><h5>Summary</h5></vs-divider
    >
    <div class="flex flex-row gap-3 justify-start w-full my-4">
      <vs-checkbox :disabled="true" v-model="vat_expired"
        >Vat Expired</vs-checkbox
      >
      <vs-checkbox :disabled="true" v-model="is_skb">SKB</vs-checkbox>
    </div>
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value PO"
        name="Item Value PO"
        v-model="table.item_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value PO"
        name="Charges Value PO"
        v-model="table.charges_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value PO"
        name="Tax Value PO"
        v-model="table.tax_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value PO"
        name="Discount Value PO"
        v-model="table.discount_value_po"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value PO"
        name="Total Invoice Value PO"
        v-model="table.total_po_value"
        readonly
      />
    </div>

    <!-- received -->
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value Received"
        name="Item Value Received"
        v-model="table.item_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value Received"
        name="Charges Value Received"
        v-model="table.charges_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value Received"
        name="Tax Value Received"
        v-model="table.tax_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value Received"
        name="Discount Value Received"
        v-model="table.discount_value_received"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value Received"
        name="Total Invoice Value Received"
        v-model="table.total_received_value"
        readonly
      />
    </div>

    <!-- invoice -->
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value Inv"
        name="Item Value Inv"
        v-model="table.item_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value Inv"
        name="Charges Value Inv"
        v-model="table.charges_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value Inv"
        name="Tax Value Inv"
        v-model="table.tax_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value Inv"
        name="Discount Value Inv"
        v-model="table.discount_value"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value Inv"
        name="Total Invoice Value Inv"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value Faktur"
        name="Item Value Faktur"
        v-model="table.item_value_faktur"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value Faktur"
        name="Charges Value Faktur"
        v-model="table.charges_value_faktur"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value Faktur"
        name="Tax Value Faktur"
        v-model="table.tax_faktur"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value Faktur"
        name="Discount Value Faktur"
        v-model="table.discount_value_faktur"
        readonly
      />
      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value Faktur"
        name="Total Invoice Value Faktur"
        v-model="table.total_value_faktur"
        readonly
      />
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>Payment Bank</b></vs-divider
    >
    <div>
      Paid Value : {{ total_paid }}<br />
      <template v-if="total_unpaid.toString().replace(/,/g, '') >= 0">
        Unpaid Value : {{ total_unpaid }}<br />
      </template>
      <template v-else>
        Unpaid Value : 0<br />
        Overpayment :
        {{
          (total_unpaid.toString().replace(/,/g, "") * -1)
            .toString()
            .replace(/,/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }}<br />
      </template>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_payment">
      <template slot="thead">
        <vs-th>Payment Code</vs-th>
        <vs-th>Type</vs-th>
        <vs-th>Bank Name</vs-th>
        <vs-th>Account Number</vs-th>
        <vs-th>Payment Voucher</vs-th>
        <vs-th>Total Paid</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.payment_code }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.type }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.bank_name }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.account_number }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.reference_code }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ tr.total_paid }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>Proposal Payment History </b></vs-divider
    >
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data_payment_proposal_with_dn"
    >
      <template slot="thead">
        <vs-th>Code</vs-th>
        <vs-th>Debit Note</vs-th>
        <vs-th>Plan Date Payment</vs-th>
        <vs-th>Paid</vs-th>
        <vs-th>Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            {{tr.code}}
          </vs-td>
          <vs-td>
            {{tr.debit_note_code}}
          </vs-td>
          <vs-td>
            <template v-if="tr.debit_note_code != ''">
              {{dateFormat(tr.clearing_date)}}
            </template>
            <template v-else-if="dateFormat(tr.purpose_payment_date) != '0001-01-01'">
              {{dateFormat(tr.purpose_payment_date)}}
            </template>
            <template v-else>
              {{dateFormat(tr.clearing_date)}}
            </template>
          </vs-td>
          <vs-td>
            <template v-if="tr.debit_note_code != ''">
              {{tr.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </template>
            <template v-else>
              {{tr.paid_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </template>
          </vs-td>
          <vs-td>
            <template v-if="tr.status == 0">
              Waiting
            </template>
            <template v-else-if="tr.status == 1">
              Approved, Waiting Execute
            </template>
            <template v-else-if="tr.status == 2">
              Rejected
            </template>           
            <template v-else-if="tr.status == 3">
              Done
            </template>           
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>List Attachment</b></vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.doc_type }}</td>
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        notes: "",
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          due_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          total_value_faktur: 0,
          item_value: 0,
          item_value_faktur: 0,
          charges_value: 0,
          charges_value_faktur: 0,
          discount_value_faktur: 0,
          tax_value: 0,
          tax_faktur: 0,
          data: [],
          data_charges: [],
          data_payment: [],
          data_payment_proposal: [],
          data_payment_proposal_with_dn: [],
        },
        bank: null,
        total_paid: 0,
        total_unpaid: 0,
        account_number: null,
        total_value: 0,
        purchase_payment_id: 0,
        reference_code: null,
        vat_expired: false,
        is_skb: false,
        whTaxTotal: 0,
        tr_date: "",
        tr_number: "",
      };
    },
    number_format(text) {
      return text
        .toFixed(2)
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateFormat(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.doSubmit();
        }
      });
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        this.downloadFileAwsS3(selected.path);
      }
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
      // console.log(this.table.file);
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/payment-proposal-close-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format(
                    "YYYY-MM-DD"
                  )
                : null;
            this.table.due_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.due_date))).format(
                    "YYYY-MM-DD"
                  )
                : null;
            this.table.payment_term_name = resp.data.payment_term_name;
            this.table.bank_name =
              resp.data.bank_name +
              " - " +
              resp.data.account_number +
              " " +
              resp.data.account_name;
            this.tr_date =
              moment(String(resp.data.tr_date)).format("YYYY-MM-DD") ==
              "0001-01-01"
                ? ""
                : moment(String(new Date(resp.data.tr_date))).format(
                    "YYYY-MM-DD"
                  );
            this.tr_number = resp.data.tr_number;

            this.vat_expired = resp.data.vat_expired === 0 ? false : true;
            this.is_skb = resp.data.is_skb === 1 ? true : false;

            this.table.item_value_po = this.number_format(
              resp.data.item_value_po
            );
            this.table.charges_value_po = this.number_format(
              resp.data.charge_value_po
            );
            this.table.tax_value_po = this.number_format(
              resp.data.tax_value_po
            );
            this.table.discount_value_po = this.number_format(
              resp.data.discount_value_po
            );
            this.table.total_po_value = this.number_format(
              resp.data.total_po_value
            );

            this.table.item_value_received = this.number_format(
              resp.data.item_value_received
            );
            this.table.charges_value_received = this.number_format(
              resp.data.charge_value_received
            );
            this.table.tax_value_received = this.number_format(
              resp.data.tax_value_received
            );
            this.table.discount_value_received = this.number_format(
              resp.data.discount_value_received
            );
            this.table.total_received_value = this.number_format(
              resp.data.total_received_value
            );
            // console.log("charge faktur",resp.data.charges_value_faktur)
            this.table.item_value_faktur = this.number_format(
              resp.data.item_value_faktur
            );
            this.table.charges_value_faktur = this.number_format(
              resp.data.charge_value_faktur
            );
            this.table.tax_faktur = this.number_format(resp.data.tax_faktur);
            this.table.discount_value_faktur = this.number_format(
              resp.data.discount_value_faktur
            );
            this.table.total_value_faktur = this.number_format(
              resp.data.total_value_faktur
            );

            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_paid = resp.data.paid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            const whTax = resp.data.data_line.reduce((acc, item) => {
              if (resp.data.is_skb === 1) {
                return acc;
              } else {
                return acc + item.wh_tax;
              }
            }, 0);

            this.total_unpaid = (resp.data.unpaid_value - whTax)
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;

            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                wh_tax: element.wh_tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                qty_received: element.qty_received,
                subtotal: (element.net - element.discount)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: element.value
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
            });
            this.table.data_payment_proposal =
              resp.data.payment_proposal_proses;
            this.table.data_payment_proposal_with_dn =
              resp.data.payment_proposal_proses_with_dn;
            console.log("DATA DN",this.table.data_payment_proposal_with_dn)
            resp.data.data_payment.forEach(function (element, index) {
              _this.table.data_payment.push({
                total_paid: element.total_paid
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                bank_name: element.bank_name,
                account_number: element.account_number,
                reference_code: element.reference_code,
                payment_code: element.payment_code,
                type: element.type,
              });
            });

          

            resp.data.data_attachment.map((r, i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              });
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    formatDate(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
