<template>
  <vx-card title="Customer Deposit">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Posting Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="depositDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.depositDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Deposit Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="collectDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.collectDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <vs-button class="mb-2 mr-2" @click="handleOpenForm">Create</vs-button>
    <vs-button class="mb-2" @click="handleExport">Export</vs-button>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs :color="colorx">
          <!-- <vs-tab @click="colorx = 'danger'" label="Proposal Approved">
            <div class="con-tab-ejemplo">
              <proposal />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'danger'" label="Draft">
            <div class="con-tab-ejemplo">
              <open
                ref="open"
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'warning'" label="Execution">
            <div class="con-tab-ejemplo">
              <execution
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'success'" label="Mapping">
            <div class="con-tab-ejemplo">
              <mapping :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'primary'" label="Waiting Reconcile">
            <div class="con-tab-ejemplo">
              <waiting
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Reconciled">
            <div class="con-tab-ejemplo">
              <recon :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'primary'" label="Failed">
            <div class="con-tab-ejemplo">
              <failed :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'primary'" label="Canceled">
            <div class="con-tab-ejemplo">
              <cancel
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'primary'"  label="Reverse">
            <div class="con-tab-ejemplo">
              <reverse :collectionDate="collectDate" :depositDate="depositDate" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'primary'" label="By Customer">
            <div class="con-tab-ejemplo">
              <by-customer
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Waiting Reverse">
            <div class="con-tab-ejemplo">
              <waiting-reverse
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Reversed">
            <div class="con-tab-ejemplo">
              <reverse
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'primary'" label="Rejected">
            <div class="con-tab-ejemplo">
              <rejected
                :collectionDate="collectDate"
                :depositDate="depositDate"
              />
            </div>
          </vs-tab> -->
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import * as XLSX from "xlsx";

import open from "./open/open.vue";
import byCustomer from "./show_by_customer/index.vue";
import execution from "./execution/execution.vue";
import cancel from "./cancel/cancel.vue";
import mapping from "./mapping/mapping.vue";
import waiting from "./waiting_recon/waiting.vue";
import recon from "./recon/recon.vue";
import failed from "./failed/failed.vue";
import create from "./form-create.vue";
import reverse from "./reverse/reverse.vue";
import rejected from "./rejected/index.vue";
import watingReverse from "./waiting_reverse/index.vue";

export default {
  components: {
    open,
    create,
    execution,
    cancel,
    mapping,
    recon,
    waiting,
    failed,
    byCustomer,
    reverse,
    DateRangePicker,
    rejected,
    "waiting-reverse": watingReverse,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate: {},
      depositDate: {
        startDate: null,
        endDate: null,
      },
      collectDate: {
        startDate: null,
        endDate: null,
      },
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      status: ["Draft", "Execution", "", "Waiting Reconcile", "Canceled", "Reconciled", "", "", "Reversed"],
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
      this.$refs.open.closeDetail();
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    handleExport() {
      if (this.depositDate.startDate == null && this.collectDate.startDate == null) {
        this.$vs.notify({
          title: "Error",
          text: "Date cannot be blank",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });

        return
      }
      this.$vs.loading();
      let param = {
        start_collect_date: this.collectDate.startDate
          ? moment(this.collectDate.startDate).format("YYYY-MM-DD")
          : null,
        end_collect_date: this.collectDate.endDate
          ? moment(this.collectDate.endDate).format("YYYY-MM-DD")
          : null,
        start_deposit_date: this.depositDate.startDate
          ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
          : null,
        end_deposit_date: this.depositDate.endDate
          ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
          : null,
      };

      console.log("jalan");
      this.$http.get(`/api/v1/cash-bank/export-data`, {
          params: param,
        }).then((r) => {
        this.$vs.loading.close();
        if (r.code == 200) {
          console.log("data export", r.data);
          
          this.$vs.loading.close();
          const data = r.data.cashBank;
          console.log(data);
          const wb = XLSX.utils.book_new();
          var ws = XLSX.utils.aoa_to_sheet([]);

          // if (!ws["!merges"]) ws["!merges"] = [];
          // ws["!merges"].push(XLSX.utils.decode_range("A1:B2"));
          // if (!ws["!cols"]) ws["!cols"] = [];

          // /* create column metadata object if it does not exist */
          // for (let COL_INDEX = 0; COL_INDEX < 7; COL_INDEX++) {
          //   if (!ws["!cols"][COL_INDEX]) {
          //     ws["!cols"][COL_INDEX] = { wpx: 150 };
          //   }
          // }

          // Set the range of the worksheet
          // ws["!ref"] = "A1:C4";
          // XLSX.utils.sheet_add_aoa(ws, [["Customer Deposit"]], {
          //   origin: "A1",
          // });
          // // Set raw text in a cell
          // XLSX.utils.sheet_add_aoa(ws, [["Customer Name : "]], {
          //   origin: "A4",
          // });

          // XLSX.utils.sheet_add_aoa(ws, [[this.selectedData.PersonName]], {
          //   origin: "B4",
          // });

          const header = [
            "Code",
            "Bank",
            "Deposit Value",
            "Method",
            "Deposit Number",
            "Customer",
            "Posting Date",
            "Deposit Date",
            "Status",
            "Source Transaction"
          ];

          // XLSX.utils.sheet_add_aoa(
          //   ws,
          //   header.map((v) => [v]), // Add an array of arrays to the worksheet
          //   { origin: "A6" }
          // );

          XLSX.utils.sheet_add_aoa(ws, [header], {
            origin: "A1",
          });

          for (let i = 0; i < data.length; i++) {
            const row = [
              data[i].Code,
              data[i].Method != 1 ? data[i].BankName + " - " + data[i].BankBranchName + " (" + data[i].AccountNumber + ") " : "",
              this.priceFormat(data[i].DepositValue),
              this.methods[data[i].Method],
              data[i].DepositNumber,
              "(" + data[i].CustomerCode + ") " + data[i].CustomerName,
              this.globalDateFormat(data[i].PostingDate),
              this.globalDateFormat(data[i].DepositDate),
              this.status[data[i].Status],
              data[i].SourceTransaction
            ];
            XLSX.utils.sheet_add_aoa(ws, [row], {
              origin: `A${2 + i}`,
            });
          }

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, "Customer Deposit");

          // Generate and download the Excel file
          // XLSX.writeFile(wb, "example.xlsx");

          const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

          // Function to convert the binary string to an ArrayBuffer

          // Create a Blob from the ArrayBuffer
          const blob = new Blob([this.s2ab(wbout)], {
            type: "application/octet-stream",
          });

          // Create a link element
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = ""
          if (this.collectDate.startDate != null && this.depositDate.startDate != null) {
            link.download = `Customer Deposit - ${moment(this.collectDate.startDate).format("YYYY-MM-DD")} - ${moment(this.collectDate.endDate).format("YYYY-MM-DD")}.xlsx`;
          } else {
            link.download = `Customer Deposit - ${moment(this.depositDate.startDate).format("YYYY-MM-DD")} - ${moment(this.depositDate.endDate).format("YYYY-MM-DD")}.xlsx`;
          }

          // Append the link to the document body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);

        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to load payment",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      });
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
