import Repository from "./repository";

const resourceV2 = "/api/v2/sales-return-management";
const resourceSalesman = "/api/v2/sales-return-management/salesman-history";

export default {
  getCNReturnTypes() {
    return Repository.get(`${resourceV2}/cn-return-types`);
  },
  storeSalesReturn(params) {
    return Repository.post(`${resourceV2}`, params);
  },
  updateSalesReturn(id, params) {
    return Repository.put(`${resourceV2}/${id}`, params);
  },
  createUpdateSalesReturn(params) {
    return Repository.post(`${resourceV2}/create-update`, params);
  },
  getList(params) {
    return Repository.get(`${resourceV2}`, params);
  },
  getListHistory(params) {
    return Repository.get(`${resourceSalesman}`, params);
  },
  getDetail(id) {
    return Repository.get(`${resourceV2}/${id}`);
  },
  getApprovalDetail(id) {
    return Repository.get(`${resourceV2}/approval/${id}`);
  },
  doApprovalStatus(id, status, feedback) {
    return Repository.put(`${resourceV2}/${id}/approval-status`, {
      status: status,
      feedback: feedback,
    });
  },
  getPriceItem(params) {
    return Repository.post(`${resourceV2}/price-item`, params);
  },
  getItemHU(params) {
    return Repository.get(`${resourceV2}/item-hu`, {
      params,
    });
  },
  export(id, type) {
    return Repository.get(`${resourceV2}/${id}/export/${type}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/octet-stream",
      },
    });
  },
  softDelete(id) {
    return Repository.get(`${resourceV2}/delete/${id}`);
  },
  
};
