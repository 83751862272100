<template>
  <div>
    <!-- <div class="vs-component vs-con-table stripe vs-table-primary">
      <header class="header-table vs-table--header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
        <div class="con-input-search vs-table--search">
          <input type="text" class="input-search vs-table--search-input" /><i
            class="vs-icon notranslate icon-scale material-icons null"
            >search</i
          >
        </div>
      </header>
      <ag-grid-vue
        style="height: 100%"
        ref="agGridTable"
        :gridOptions="agGrid.options"
        class="ag-theme-material ag-grid-table"
        :columnDefs="agGrid.columnDefs"
        :defaultColDef="agGrid.defaultColDef"
        :rowData="agGrid.data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :onGridReady="onGridReady"
      ></ag-grid-vue>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div> -->
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              >{{ data[indextr][head.field] }}</vs-td
            >
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>
<script>
// import { AgGridVue } from "ag-grid-vue";
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
export default {
  components: {
    // AgGridVue,
  },
  props: {
    baseUrl: {
      type: String,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      // agGrid: {
      //   api: null,
      //   data: [],
      //   options: {},
      //   columnDefs: [
      //     {
      //       headerName: "Total",
      //       field: "total_target",
      //       width: 125,
      //       // pinned: "left",
      //       // valueFormatter: this.formatPrice,
      //     },
      //   ],
      //   defaultColDef: {
      //     resizable: true,
      //   },
      // },
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            field: "so_doc_status",
            title: "SO Doc Status",
          },
          {
            field: "do_doc_status",
            title: "DO Doc Status",
          },
          {
            field: "pod_doc_status",
            title: "POD Doc Status",
          },
          {
            field: "bill_doc_status",
            title: "Bill Doc Status",
          },
          {
            field: "purchase_order_no",
            title: "Purchase Order No",
          },
          {
            field: "po_date",
            title: "PO Date",
          },
          {
            field: "so_number",
            title: "SO Number",
          },
          {
            field: "do_number",
            title: "DO Number",
          },
          {
            field: "gigr_number",
            title: "GIGR Number",
          },
          {
            field: "pod_number",
            title: "POD Number",
          },
          {
            field: "billing_number",
            title: "Billing Number",
          },
          {
            field: "reject_code",
            title: "Reject Code",
          },
          {
            field: "rejection_desc",
            title: "Rejection Desc",
          },
          {
            field: "order_type",
            title: "Order Type",
          },
          {
            field: "order_type_desc",
            title: "Order Type Desc",
          },
          {
            field: "dist_channel",
            title: "Dist Channel",
          },
          {
            field: "div_code",
            title: "Div Code",
          },
          {
            field: "div_desc",
            title: "Div Desc",
          },
          {
            field: "sales_office",
            title: "Sales Office",
          },
          {
            field: "sales_office_desc",
            title: "Sales Office Desc",
          },
          {
            field: "sold_to_party",
            title: "Sold To Party",
          },
          {
            field: "sold_to_party_name",
            title: "Sold To Party Name",
          },
          {
            field: "sold_to_party_name_2",
            title: "Sold To Party Name 2",
          },
          {
            field: "ship_to_party",
            title: "Ship To Party",
          },
          {
            field: "ship_to_party_name",
            title: "Ship To Party Name",
          },
          {
            field: "ship_to_party_name_2",
            title: "Shipto Party Name 2",
          },
          {
            field: "city",
            title: "City",
          },
          {
            field: "district",
            title: "District",
          },
          {
            field: "postal_code",
            title: "Postal Code",
          },
          {
            field: "village",
            title: "Village",
          },
          {
            field: "street",
            title: "Street",
          },
          {
            field: "driver_name",
            title: "Driver Name",
          },
          {
            field: "incoterm",
            title: "Incoterm",
          },
          {
            field: "material_code",
            title: "Material Code",
          },
          {
            field: "material_desc",
            title: "Material Desc",
          },
          {
            field: "product_category",
            title: "Product Category",
          },
          {
            field: "sales_code",
            title: "Sales Code",
          },
          {
            field: "sales_code_desc",
            title: "Sales Code Desc",
          },
          {
            field: "po_date",
            title: "PO Date",
          },
          {
            field: "so_doc_date",
            title: "SO Doc Date",
          },
          {
            field: "so_posting_date",
            title: "SO Posting Date",
          },
          {
            field: "so_creation_date",
            title: "SO Creation Date",
          },
          {
            field: "so_creation_time",
            title: "SO Creation Time",
          },
          {
            field: "so_amount",
            title: "SO Amount",
          },
          {
            field: "so_discount",
            title: "SO Discount",
          },
          {
            field: "so_item",
            title: "SO Item",
          },
          {
            field: "so_qty",
            title: "SO Qty",
          },
          {
            field: "so_unit",
            title: "SO Unit",
          },
          {
            field: "so_unit_price",
            title: "SO Unit Price",
          },
          {
            field: "so_volume",
            title: "SO Volume",
          },
          {
            field: "so_volume_unit",
            title: "SO Volume Unit",
          },
          {
            field: "do_creation_date",
            title: "DO Creation Date",
          },
          {
            field: "do_amount",
            title: "DO Amount",
          },
          {
            field: "do_item",
            title: "DO item",
          },
          {
            field: "do_qty",
            title: "DO Qty",
          },
          {
            field: "do_unit",
            title: "DO Unit",
          },
          {
            field: "so_rdd_date",
            title: "SO RDD Date",
          },
          {
            field: "actual_gigr_date",
            title: "Actual GI GR Date",
          },
          {
            field: "gigr_amt",
            title: "GIGR Amt",
          },
          {
            field: "gigr_qty",
            title: "GIGR Qty",
          },
          {
            field: "gigr_uom",
            title: "GIGR Uom",
          },
          {
            field: "gigr_volume_unit",
            title: "GIGR Volume Unit",
          },
          {
            field: "gigr_volume",
            title: "GIGR Volume",
          },
          {
            field: "billing_type",
            title: "Billing Type",
          },
          {
            field: "billing_date",
            title: "Billing Date",
          },
          {
            field: "billing_creation_date",
            title: "Billing Creation Date",
          },
          {
            field: "bill_amount",
            title: "Bill Amount",
          },
          {
            field: "bill_qty",
            title: "Bill Qty",
          },
          {
            field: "bill_unit",
            title: "Bill Unit",
          },
          {
            field: "bill_item",
            title: "Bill Item",
          },
          {
            field: "cust_grp",
            title: "Cust Grp",
          },
          {
            field: "cust_grp_desc",
            title: "Cust Grp Desc",
          },
          {
            field: "cust_grp_1",
            title: "Cust Grp 1",
          },
          {
            field: "cust_grp_1_desc",
            title: "Cust Grp 1 Desc",
          },
          {
            field: "cust_grp_2",
            title: "Cust Grp 2",
          },
          {
            field: "cust_grp_2_desc",
            title: "Cust Grp 2 Desc",
          },
          {
            field: "cust_grp_3",
            title: "Cust Grp 3",
          },
          {
            field: "cust_grp_3_desc",
            title: "Cust Grp 3 Desc",
          },
          {
            field: "cust_grp4",
            title: "Cust Grp4",
          },
          {
            field: "cust_grp_4_desc",
            title: "Cust Grp 4 Desc",
          },
          {
            field: "mat_grp_1",
            title: "Mat Grp1",
          },
          {
            field: "mat_grp_1_desc",
            title: "Mat Grp 1 Desc",
          },
          {
            field: "mat_grp_2",
            title: "Mat Grp 2",
          },
          {
            field: "mat_grp_2_desc",
            title: "Mat Grp 2 Desc",
          },
          {
            field: "mat_grp_3",
            title: "Mat Grp 3",
          },
          {
            field: "mat_grp_3_desc",
            title: "Mat Grp 3 Desc",
          },
          {
            field: "mat_grp_4",
            title: "Mat Grp 4",
          },
          {
            field: "mat_grp_4_desc",
            title: "Mat Grp 4 Desc",
          },
          {
            field: "mat_grp_5",
            title: "Mat Grp 5",
          },
          {
            field: "mat_grp_5_desc",
            title: "Mat Grp 5 Desc",
          },
          {
            field: "pod_date",
            title: "POD Date",
          },
          {
            field: "pod_verification_date",
            title: "POD Verification Date",
          },
        ],
      };
    },

    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        console.log(this.draw)
        console.log("getData============");
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              status: this.status,
              territory_ids: this.territoryIDs,
              start_date: this.startDate
                ? moment(this.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.endDate
                ? moment(this.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "SALES_ORDER_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all") +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs,
            start_date: moment(this.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.endDate).format("YYYY-MM-DD"),
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            // var fileURL = window.URL.createObjectURL(new Blob([resp]));
            // var fileLink = document.createElement("a");
            // fileLink.href = fileURL;

            // if (file == "excel") {
            //   fileLink.setAttribute("download", fileTitle + ".xlsx");
            // } else {
            //   fileLink.setAttribute("download", fileTitle + ".pdf");
            // }
            // document.body.appendChild(fileLink);
            // fileLink.click();
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startDate() {
    //   this.getData();
    // },
    // endDate() {
    //   this.getData();
    // },
    // territoryIDs() {
    //   this.getData();
    // },
    draw(val) {
      if (val > 0) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>