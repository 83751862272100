const state = {
  selectedDebitNotes: [],
  proposalPaidNows: [],
  debitNotePaidValues: [],
  totalSelectedDebitNotesValue: 0,
  totalSelectedPaidNows: 0,
  totalDebitNotesMinusPaidNows: 0,
  // isDebitNotesInitialized: false,
};

const mutations = {
  setSelectedDebitNotes(state, payload) {
    state.selectedDebitNotes = payload;
  },
  setProposalPaidNows(state, payload) {
    state.proposalPaidNows = payload;
  },
  updateProposalPaidNow(state, payload) {
    const temp = [...state.proposalPaidNows];
    temp[payload.index].value = payload.newValue;
    state.proposalPaidNows = temp;
  },
  setTotalSelectedDebitNotesValue(state, payload) {
    state.totalSelectedDebitNotesValue = payload;
  },
  setTotalSelectedPaidNows(state, payload) {
    state.totalSelectedPaidNows = payload;
  },
  setTotalDebitNotesMinusPaidNows(state, payload) {
    state.totalDebitNotesMinusPaidNows = payload;
  },
  setDebitNotePaidValues(state, payload) {
    state.debitNotePaidValues = payload;
    // const temp = [...state.debitNotePaidValues];
  },
  clearState(state) {
    state.selectedDebitNotes = [];
    state.proposalPaidNows = [];
    state.totalSelectedDebitNotesValue = 0;
    state.totalSelectedPaidNows = 0;
    state.totalDebitNotesMinusPaidNows = 0;
    state.debitNotePaidValues = [];
    // state.isDebitNotesInitialized = false;
  },
};

const getters = {
  getSelectedDebitNotesCount: (state) => {
    return state.selectedDebitNotes.length.toString();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
