<template>
    <div>
        <vs-button
            color="green"
            icon-pack="feather"
            icon="icon-plus"
            @click="handleAddItem">ADD
        </vs-button>
        <vs-table 
            description
            search
            :sst="true"
            :max-items="params.length"
            :total="params.total"
            :data="dataitems"
            @search="handleSearch"
            @change-page="handleChangePage"
            stripe border>
            <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ 1+(params.length * (params.page-1)) }} - {{ (params.length * params.page)>this.params.total?this.params.total:params.length * params.page }} of
                  {{ this.params.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in params.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          
            <template slot="thead">
                <vs-th style="width: 5%;" sort-key="">No</vs-th>
                <vs-th style="width: 15%;" sort-key="sku_code">Sku Code</vs-th>
                <vs-th sort-key="name">Name</vs-th>
                <!-- <vs-th >Type</vs-th> -->
                <vs-th style="width: 20%;" >Handling Unit</vs-th>
                <vs-th style="width: 5%;"></vs-th>
            </template>
            <template slot-scope="{ data }">
                <vs-tr :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data">
                    <vs-td>{{ indextr+1+(params.length * (params.page-1)) }}</vs-td>
                    <vs-td>{{ tr.sku_code }}</vs-td>
                    <vs-td>{{ tr.name }}</vs-td>
                    <!-- <vs-td>Reguler</vs-td> -->
                    <vs-td>{{ tr.unit_name }}</vs-td>
                    <vs-td align="center">
                        <vs-checkbox v-model="tr.is_check" @input="function(v){
                            handleSelect(v,indextr)
                        }"></vs-checkbox>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            style="padding-top: 5px"
            :total="params.totalPage"
            v-model="setPage"/>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
    components: {
        Datepicker,
    },
    props: ["dataitems","params"],

    data() {
        return {
            selectItem:[],
            unSelectItem:[],
            title: "",
            priceStructure: []
        };
    },
    methods: {
        formatCurrency(value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        handleSelect(v,i){
            let temp_datas = this.selectItem.filter((el)=>{
                return this.dataitems[i].item_unit_id!=el.item_unit_id
            })
            console.log("let temp_datas",temp_datas)
            console.log("this.selectItem(before)",this.unSelectItem)
            if (this.dataitems[i].is_check) {
                if (this.dataitems[i].item_unit_id>0) {
                    temp_datas.push(this.dataitems[i])
                    this.unSelectItem = this.unSelectItem.filter((el)=>{
                        return el.item_unit_id != this.dataitems[i].item_unit_id
                    })
                }else{
                    this.$vs.notify({
                        title: "Danger",
                        text: "please select Handling Unit first",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check",
                    });
                    this.dataitems[i].is_check = false
                }
            }else{
                this.unSelectItem.push(this.dataitems[i])
            }
            this.selectItem = temp_datas
            console.log("this.selectItem",this.selectItem)
            console.log("this.selectItem(after)",this.unSelectItem)
            // this.$emit("list-select", v);
        },
        handleChangeHu(i){
            this.handleSelect(true,i)
        },
        handleAddItem(v){
            let tempSelectItem = this.selectItem
            this.selectItem = []
            this.$emit("list-select", tempSelectItem,this.groupByItemUnitID(this.unSelectItem));
        },
        handleChangePage(page) {
            this.params.page = page;
            this.$emit("reload-data", this.params);
        },
        handleSearch(searching){
            this.$emit("search", searching);
        },
        handleChangelength(val) {
            this.params.length = val == "All" ? this.params.total : val;
            this.params.page = 1;
            this.$emit("reload-data", this.params);
            // this.reloadData(this.params);
        },
        groupByItemUnitID(arr=[]){
            let is_push = false
            let temp_num = 0
            let tempArray = []
            arr.forEach((num)=>{
                is_push = temp_num == num.item_unit_id
                console.log(temp_num , "<<<<",is_push,">>>>" , num.item_unit_id)
                if (!is_push) {
                    temp_num = num.item_unit_id
                    tempArray.push(num)
                }
            })
            return tempArray
        },

    },
    filters: {
        formatCurrency(value) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
    },
    watch:{
        dataitems:function(v){
            let tempDT =[]
            let tempIds = []
            this.selectItem.forEach((el)=>{
                tempIds.push(el.item_unit_id)
            })
            console.log("tempIds",tempIds)
            v.forEach((el,i)=>{
                if (!el.is_check) {
                    // console.log("this.tempIds.includes(",el.item_unit_id,")",tempIds.includes(el.item_unit_id))
                    if (tempIds.includes(el.item_unit_id)) {
                        el.is_check = true
                        tempDT.push(el)
                    }
                }else{
                    tempDT.push(el)
                }
            })
            if (v&&v.length>0) {
                this.selectItem.push(...tempDT)
            }
            console.log("this.selectItem before grouping",this.selectItem)
            this.selectItem = this.groupByItemUnitID(this.selectItem)
            console.log("this.selectItem after grouping",this.selectItem)
        }
    },
    mounted() {

    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>

<style scoped>
.badge.badge-success {
    background-color: #28c76f;
}

.badge {
    font-weight: 400;
    color: #fff;
    background-color: #7367f0;
}

.badge-success {
    color: #fff;
    background-color: #28c76f;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 800px;
    padding: 5px;
}

.vs-table--thead {
    z-index: -1 !important;
    display: none;
}

.table-expend:hover,
.table-expend-combo:hover td.combo-hover {
    background: #e9e9e9;
    cursor: pointer;
}

.table-expend:active,
.table-expend-combo td.combo-hover:active {
    background: #e7e5e5;
}
.vs-dropdown-menu{
     z-index: 100000;
}
/* .vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
} */

</style>
