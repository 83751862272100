<template>
    <div>
        <!-- <div class="vx-row mb-12">
            <vs-button v-on:click="create()" icon="done" color="primary">Create Payment</vs-button>
        </div> -->
        <div class="vx-row mb-12">
            <div v-bind:class="[
              detail? detailShow + ' vertical-divider' : '',
              detailHide,
            ]">
                <data-table :responseData="responseData" :propsParams="params" :header="header" @reloadDataFromChild="reloadData">
                    <template slot="thead">
                        <!-- <th width="15%">
                  <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
                </th> -->
                    </template>
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
                            <vs-td class="whitespace-no-wrap">
                                <div class="mt-4 flex mr-1">
                                    <!-- <vx-tooltip text="Settlement" class="mr-4">
                                        <vs-button color="primary" type="line" icon-pack="feather" @click="onClickRequest(tr)" icon="icon-file-text" />
                                    </vx-tooltip> -->
                                    <vx-tooltip text="Print" class="mr-4">
                                        <vs-button
                                            color="primary"
                                            type="line"
                                            icon-pack="feather"
                                            @click="printPDF(tr)"
                                            icon="icon-printer"
                                        />
                                    </vx-tooltip>
                                    <vx-tooltip text="Show" class="mr-4">
                                        <vs-button color="green" type="line" icon-pack="feather" @click="edit(tr)" icon="icon-eye" />
                                    </vx-tooltip>
    
                                    <!-- <vx-tooltip text="Cancel" class="mr-4">
                                        <vs-button color="red" type="line" icon-pack="feather" @click="promptCancel(tr)" icon="icon-x-square" />
                                    </vx-tooltip> -->
                                </div>
                            </vs-td>
                            <vs-td>
                                {{ tr.shipment_number }}
                            </vs-td>
                            <vs-td>
                                {{ dateFormat(tr.delivery_plan_date) }}
                            </vs-td>
                            <vs-td>
                                {{ tr.count_do_pickup }}
                            </vs-td>
                            <vs-td>
                                {{ tr.driver_name }}
                            </vs-td>
                            <vs-td>
                                {{ tr.vehicle_name }}
                            </vs-td>
                            <vs-td>
                                {{ tr.shipment_cost_code }}
                            </vs-td>
                            <vs-td>
                                {{ ((tr.vehicle_type === '' || tr.vehicle_type === null || typeof tr.vehicle_type  === null )?'OnCall':(tr.vehicle_type.toLowerCase() == 'internal'?'Internal':'Oncall'))}}
                            </vs-td>
                            <vs-td>
                                {{ tr.advance_cash_value==null?"":formatPrice(tr.advance_cash_value) }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </data-table>
                <vs-popup
                    fullscreen
                    title="fullscreen"
                    :active.sync="showPdf"
                    :button-close-hidden="false"
                >
                    <div class="flex flex-col gap-6 h-full">
                    <iframe :src="pdfUrlPopup" class="w-full" style="min-height: 80vh" />
                    <div class="flex gap-3 w-full justify-end">
                        <vs-button color="danger" @click="handleClosePopUp"
                        >Cancel</vs-button
                        >
                        <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
                    </div>
                    </div>
                </vs-popup>
            </div>
            <vs-prompt title="Confirmation" color="primary" @accept="confirmAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="confirmPrompt">
                <div class="con-exemple-prompt">
                    AR Clearing
                    <br />
                    Are you sure to confirm <b>{{this.selectedData.Code}}</b> AR-Clearing ?
                    <br />
                    <br />
                    <!-- <vs-textarea v-model="notes"/> -->
                </div>
            </vs-prompt>
            <vs-prompt title="Confirmation" color="primary" @accept="cancelAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="cancelPrompt">
                <div class="con-exemple-prompt">
                    AR Clearing
                    <br />
                    Are you sure to cancel <b>{{this.selectedData.Code}}</b> AR-Clearing ?
                    <br />
                    <br />
                    <!-- <vs-textarea v-model="notes"/> -->
                </div>
            </vs-prompt>
            <transition name="detail-fade">
                <div v-if="detail" v-bind:class="[
                    detail? ' nonfixed vx-col md:w-2/3 w-full mb-base' : '',
                    detailHide,
                ]">
                    <div>
                        <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth" vs-w="4">
                            <vs-button size="small" v-on:click="closeDetail" color="grey" icon-pack="feather" icon="icon-x-square">Close</vs-button>
                        </vs-col>
                        <template v-if="show == 1">
                            <edit :selected="selectedData" @closeDetail="closeDetail" />
                        </template>
                        <template v-else>
                            <advanceRequest :selected="selectedData" @closeDetail="closeDetail" />
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    </template>
    
    <script>
    import create from "../form-create.vue";
    import edit from "../form-view.vue";
    import advanceRequest from "../form-request.vue";
    import moment from "moment";
    import { jsPDF } from "jspdf";
    import autoTable from "jspdf-autotable";
    export default {
        props: {
            selected: Object,
            option: Object,
            shipmentDate: Object,
            filterDriver: Object,
            filterVehicle: Object,
            filterType: Object,
        },
        components: {
            create,
            edit,
            advanceRequest
        },
        data() {
            return {
                typePayment: ["", "Giro", "Cheque", "Transfer"],
                confirmPrompt: false,
                cancelPrompt: false,
                status: 3,
                show: 0,
                params: {
                    search: "",
                    length: 10,
                    page: 1,
                    order: "desc",
                    sort: "op.id",
                },
                header: [{
                        text: "Action",
                        sortable: false,
                    },
                    {
                        text: "Shipment Doc",
                        value: "shipment_number",
                        // width: '5%'
                    },
                    {
                        text: "Shipment Date",
                        value: "delivery_plan_date",
                    },
                    {
                        text: "Number of DO",
                        value: "count_do_pickup",
                    },
                    {
                        text: "Driver",
                        value: "driver_name",
                    },
                    {
                        text: "Vehicle",
                        value: "vehicle_name",
                    },
                    {
                        text: "Advance Cash Doc",
                        value: "shipment_number",
                    },
                    {
                        text: "Shipment Cost Type",
                        value: "type",
                    },
                    {
                        text: "Value",
                        // value: "type",
                    },
                ],
                responseData: {},
                detailShow: "vx-col md:w-1/3 w-full mb-base",
                detailHide: "core vx-col md:w-1/1 w-full mb-base",
                detail: 0,
                selectedData: {},
                driver_ids: [],
                vehicle_ids: [],
                type_name: [],
                pdfUrlPopup: "",
                showPdf: false,
                pdfProxyClass: null,
            };
        },
        computed: {},
        watch: {
            shipmentDate() {
                this.reloadData(this.params);
            },
            filterDriver() {
                this.reloadData(this.params);
            },
            filterVehicle() {
                this.reloadData(this.params);
            },
            filterType() {
                this.reloadData(this.params);
            },
        },
        mounted() {
            // this.reloadData(this.params);
    
            // this.getData();
            // this.page(1);
            // this.dataId = this.data[0].id
        },
        methods: {
            create() {
                this.selectedData = {
                    ID: 0
                }
                this.detail = 1;
            },
            edit(selected) {
                this.selectedData = selected
                console.log(selected)
                console.log(this.detail, "detailnya disini");
                this.detail = true;
                this.show = 1;
            },
            onClickRequest(selected) {
                this.selectedData = selected
                console.log(selected)
                console.log(this.detail, "detailnya disini");
                this.detail = true;
                this.show = 2;
            },
            promptConfirm(data) {
                this.confirmPrompt = true
                this.status = 2
                this.cancelPrompt = false
                this.selectedData = data
                console.log("confirm")
            },
            promptCancel(data) {
                this.confirmPrompt = false
                this.status = 4
                this.cancelPrompt = true
                this.selectedData = data
                console.log("confirm")
            },
            handleClosePopUp() {
                this.showPdf = false;
                this.pdfUrlPopup = "";
                this.pdfProxyClass = null;
            },
            handlePrintPDFPreview() {
                try {
                    // this.$vs.loading();

                    console.log(this.pdfProxyClass);
                    const name = "shipment-cost.pdf";
                    this.pdfProxyClass.save(name);

                    this.showPdf = false;
                    this.pdfUrlPopup = "";
                    this.pdfProxyClass = null;
                    // this.checked = [];
                    // this.checkedCode = [];
                    // this.usingCloseButton = true;
                    // this.proposals = [];
                    // this.$vs.loading.close();
                } catch (e) {
                    console.log(e);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Print",
                        text: "Failed to print",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    // this.checked = [];
                    // this.checkedCode = [];
                }
            },
            dateFormat(value) {
                return moment(String(value)).format("DD/MM/YYYY");
            },
            closeDetail(params = false) {
                console.log(params)
                window.scrollTo(0, 0);
                this.detail = false;
                console.log(this.detail, "detailnya disini");
                this.confirmPrompt = false
                this.show = 0
                this.status = 2
                this.cancelPrompt = false
                if (params == true || this.selectedData.ID != 0) {
                    this.reloadData(this.params);
                }
                this.selectedData = {
                    ID: 0,
                };
            },
            confirmAdjustment() {
                let params = {
                    id: this.selectedData.ID,
                    status: this.status
                }
                this.$vs.loading();
                this.$http.post('/api/v1/finance/adjustment/confirm', params).then((result) => {
                    this.$vs.loading.close();
                    if (result.code == 200) {
                        this.closeDetail(true)
                        this.$vs.notify({
                            title: "Success",
                            text: "",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Error",
                            text: result.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                }).catch((e) => {
                    console.log(e)
    
                })
            },
            cancelAdjustment() {
                let params = {
                    id: this.selectedData.ID,
                    status: this.status
                }
                this.$vs.loading();
                this.$http.post('/api/v1/finance/adjustment/cancel', params).then((result) => {
                    this.$vs.loading.close();
                    if (result.code == 200) {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Success",
                            text: "",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.closeDetail()
                        this.$vs.notify({
                            title: "Error",
                            text: result.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                }).catch((e) => {
                    console.log(e)
    
                })
            },
            dataTable(params) {
                return new Promise((resolve, reject) => {
                    this.$http.get('/api/v1/uang-jalan/transport-plan/datatable', {
                        params: {
                            status: 2,
                            type: 4,
                            view_only: true,
                            search: params.search,
                            length: params.length,
                            page: params.page,
                            order: params.order,
                            sort: params.sort,
                            start_shipment_date:this.shipmentDate.startDate ? moment(this.shipmentDate.startDate).format("YYYY-MM-DD") : null,
                            end_shipment_date:this.shipmentDate.endDate ? moment(this.shipmentDate.endDate).format("YYYY-MM-DD") : null,
                            driver_ids:this.driver_ids,
                            vehicle_ids:this.vehicle_ids,
                            type_names:this.type_names,
                        }
                    }).then((r) => {
                        resolve(r)
                    }).catch((e) => {
                        reject(e)
                    })
                })
            },
            reloadData(params) {
                this.driver_ids = this.filterDriver.map(x => x.id),
                this.vehicle_ids = this.filterVehicle.map(x => x.id),
                this.type_names =  this.filterType.map(x => x.name),
                this.params = params;
                console.log(params);
                this.$vs.loading();
                const dataTable = this.dataTable(params);
                dataTable.then((r) => {
                    console.log(r);
                    if (r.code == 500) {
                        this.$vs.loading.close();
                    } else if (r.code == 200) {
                        this.$vs.loading.close();
                        this.data = r.data.records;
                        this.responseData = r.data;
                        // this.responseData.length = r.data.otherPayment.length;
                        // this.checkedAll = false;
                    } else {
                        this.$vs.loading.close();
                    }
                });
    
            },
            async printPDF(tr) {
                try {
                    // // this.$vs.loading();
                    // if (tr == null) {
                    //     this.$vs.notify({
                    //         color: "danger",
                    //         title: "Print",
                    //         text: "Please select at least one proposal to be printed",
                    //         position: "top-right",
                    //         iconPack: "feather",
                    //         icon: "icon-x-circle",
                    //     });
                    //     return;
                    // }
                    // this.$vs.loading();
                    // const padding = 1;
                    // const height = 29.7;
                    // const width = 21;
                    // const pageWidth = width - padding * 2;
                    // const pageHeight = height - padding * 2;

                    const data = JSON.parse(JSON.stringify(tr));
                    console.log(data, "PRINT DATA");
                    // const proposals = JSON.parse(JSON.stringify(this.checkedCode));
                    const doc = new jsPDF({
                        orientation: 'potrait',
                        format: 'a4',
                        unit: 'mm',
                    });

                    var headerText = "FORM PENGAMBILAN SURAT JALAN DAN PERINCIAN BIAYA DELIVERY";
                    var headerFontSize = 16;
                    var headerTextWidth = doc.getStringUnitWidth(headerText) * headerFontSize / doc.internal.scaleFactor;
                    var pageWidth = doc.internal.pageSize.getWidth();
                    var pageHeight = doc.internal.pageSize.getHeight();
                    var headerX = (pageWidth - headerTextWidth) / 2;
                    var headerY = 20;
                    let startX = (pageWidth - headerTextWidth) / 2.5
                    const addHeader = () => {

                        startX = (pageWidth - headerTextWidth) / 2.5
                        doc.setFontSize(headerFontSize);
                        doc.text(headerText, headerX, headerY);
    
                        let fontSize = 12
                        doc.setFont("times").setFontSize(fontSize,'bold')//.setFontStyle("bold");
                        doc.text('Cabang',startX+10, 30, ); //(text,x,y)
                        doc.text(': ' + data.cabang,startX+40, 30, ); //(text,x,y)
                        doc.text('Driver',startX+10, 35, ); //(text,x,y)
                        doc.text(': ' + data.driver_name,startX+40, 35, ); //(text,x,y)
                        doc.text('Vehicle No.',startX+10, 40, ); //(text,x,y)
                        doc.text(': ' + data.vehicle_number,startX+40, 40, ); //(text,x,y)
    
                        startX = pageWidth / 2 - 5;
                        let startY = startX;
                        doc.setFont("times").setFontSize(fontSize)//.setFontStyle("bold");
                        doc.text('Shipment Document',startX+10, 30, ); //(text,x,y)
                        doc.text(': ' + data.shipment_number,startX+50, 30, ); //(text,x,y)
                        doc.text('Cash Adv. Document',startX+10, 35, ); //(text,x,y)
                        doc.text(': ' + data.shipment_cost_code,startX+50, 35, ); //(text,x,y)
                        doc.text('Tgl Pengajuan',startX+10, 40, ); //(text,x,y)
                        doc.text(': ' + moment(data.shipment_plan_date).format("DD MMMM YYYY"),startX+50, 40, ); //(text,x,y)
                    }
                    // doc.text('Berangkat Jam',startX+10, 35, ); //(text,x,y)
                    // doc.text(': ' + "",startX+40, 35, ); //(text,x,y)
                    // doc.text('Kembali Jam',startX+10, 40, ); //(text,x,y)
                    // doc.text(': ' + "",startX+40, 40, ); //(text,x,y)

                    startX += 50

                    let cursorY = this.generateData(doc,tr,50,(pageWidth - 500) * 2, 283);
                    const pageCount = doc.internal.getNumberOfPages();
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        addHeader();
                    }

                    this.generateFooter(doc, pageHeight-50, (pageWidth - 500) * 2, pageHeight);

                    // doc.save("shipment-cost-"+data.code+".pdf");
                    // this.pdfProxyClass = doc;
                    // this.pdfUrlPopup = doc.output("dataurlstring");
                    // this.pdfUrlPopup += "#toolbar=0";
                    // this.showPdf = true;

                    const pdfBlob = doc.output('blob');
                    const blobUrl = URL.createObjectURL(pdfBlob);
                    window.open(blobUrl, '_blank');

                    this.$vs.loading.close();
                } catch (e) {
                    console.log(e);
                    this.$vs.loading.close();
                }
            },
            setPos(pageWidth, pos, offset) {
                if (pos >= pageWidth) {
                    return pageWidth;
                } else {
                    if (pos <= 0) {
                    return offset;
                    } else {
                    return (pos += offset);
                    }
                }
            },
            generateFooter(doc, startY, pageWidth, pageHeight) {
                const header = [
                    { header: "Dibuat oleh:", dataKey: "a" },
                    { header: "Diperiksa oleh:", dataKey: "b" },
                    { header: "Diserahkan oleh:", dataKey: "c" },
                    { header: "Diterima oleh:", dataKey: "d" },
                ];
                const width = this.setPos(pageWidth, pageWidth, 1) / 4;
                autoTable(doc, {
                    startY: startY,
                    columns: header,
                    body: [{ a: "", b: "", c: "", d: ""},
                    { a: "Admin Traffic", b: "BLC/Spv. Log", c: "Kasir", d: "Driver"}
                    ],
                    styles: {
                        lineWidth: 0.01,
                        lineColor: [0, 0, 0],
                        fontSize: 12,
                        cellPadding: 0.2,
                        font: "times",
                    },
                    headStyles: {
                        fillColor: [219, 219, 219],
                        lineWidth: 0.01,
                        lineColor: [0, 0, 0],
                        fontStyle: "bold",
                        valign: "middle",
                        halign: "center",
                        cellPadding: 0.1,
                        minCellHeight: 0.1,
                        font: "times",
                    },
                    columnStyles: {
                        1: { cellWidth: width },
                        2: { cellWidth: width },
                        3: { cellWidth: width },
                        4: { cellWidth: width },
                    },
                    theme: "plain",
                    margin: { left: 15, right: 15 },
                    didParseCell(data) {
                        data.cell.styles.fontSize = 11;

                        if (data.row.index === 1) {
                            console.log(data, "pageWidth last");
                            const customRowHeight = 5; // Set the desired custom height for the last row
                            data.row.height = customRowHeight;
                            data.cell.height = customRowHeight;
                            data.cell.contentHeight = customRowHeight;
                            data.cell.styles.valign = "middle";
                            data.cell.styles.halign = "center";
                        } else if (data.row.index === 0 && data.section == "body")  {
                            console.log(data, "pageWidth not");
                            const customRowHeight = 25; // Set the desired custom height for the last row
                            data.row.height = customRowHeight;
                            data.cell.height = customRowHeight;
                            data.cell.contentHeight = customRowHeight;
                            data.cell.styles.minCellHeight = customRowHeight;
                        }
                    },
                });
            },

            generateData(doc, data, startY, pageWidth, pageHeight) {
                const deliveryOrder = JSON.parse(data.delivery_orders);
                console.log(deliveryOrder, "print");

                const header = [
                    { header: "PI No.", dataKey: "a" },
                    { header: "Nama Pelanggan", dataKey: "b" },
                    { header: "Biaya", dataKey: "d" },
                    { header: "Keterangan", dataKey: "e" },
                ];
                let body = [];
                
                deliveryOrder.forEach(el => {
                    console.log(el, "print");
                    body.push({a: el.code, b: el.customer_name, d: "", e: ""})
                });

                body.push({a: "", b: "Shipment Cost Item", d: "Rp. "+this.formatPrice(data.advance_cash_value), e: "SDN-Uang Jalan"})
                let cursorY = startY + 0.2;
                let colHeight = 0;
                let isShowHeader = false;

                for (let i = 0; i < body.length; i++) {
                    const innerBody = [body[i]];
                    console.log(cursorY + colHeight, "cursorY + colHeight");
                    console.log(cursorY, "cursorY");
                    console.log(colHeight, "colHeight");
                    console.log(pageHeight, "pageHeight");
                    console.log(cursorY + colHeight >= pageHeight, "pageHeight");
                    console.log(innerBody, "pageHeight");

                    if (cursorY + colHeight >= pageHeight - 40) {
                        console.log("disini jalan");
                        doc.addPage();
                        doc.setPage(doc.internal.getNumberOfPages());
                        cursorY = startY + 0.2;
                        isShowHeader = true;
                    }

                    const width = this.setPos(pageWidth, pageWidth, 1) / 4;
                    autoTable(doc, {
                        startY: cursorY,
                        columns: header,
                        body: innerBody,
                        styles: {
                            lineWidth: 0,
                            lineColor: [0, 0, 0],
                            fontSize: 12,
                            cellPadding: 0.5,
                            minCellHeight: 4,
                            font: "times",
                            halign: "center",
                        },
                        showHead: i === 0 || isShowHeader ? "firstPage" : "never",
                        headStyles: {
                            lineWidth: 0,
                            lineColor: [0, 0, 0],
                            fontStyle: "bold",
                            valign: "middle",
                            halign: "center",
                            cellPadding: 0.1,
                            minCellHeight: 0.1,
                            font: "times",
                        },
                        columnStyles: {
                            a: { cellWidth: this.setPos(pageWidth, 72, 1) },
                            b: { cellWidth: this.setPos(pageWidth, 77, 1), halign: "left" },
                            d: { cellWidth: this.setPos(pageWidth, 25, 1) },
                            e: { cellWidth: this.setPos(pageWidth, 63, 1) },
                        },
                        theme: "plain",
                        margin: { left: 25, right: 25 },
                        didParseCell(data) {
                            data.cell.styles.fontSize = 11;
                            data.cell.styles.cellPadding = 1;
                        },
                        didDrawCell(data) {
                            // const rowIndex = data.row.index;
                            const columnIndex = data.column.index;
                            const cellX = data.cell.x;
                            const cellY = data.cell.y;
                            if (i === 0 || i === body.length - 1) {
                                doc.setLineWidth(0.01);
                                doc.setDrawColor(0, 0, 0);
                                doc.line(cellX, cellY, cellX + data.cell.width, cellY);
                            }

                            if (columnIndex === 0) {
                                // const cellIncm = data.cell.y * toCentiMeter;
                                cursorY += data.cell.height;
                                colHeight = data.cell.height;
                                isShowHeader = false;
                            }
                        },
                    });
                }

                return doc.lastAutoTable.finalY;
            },
            formatPrice(angka, prefix = "") {
                return angka
                    .toString()
                    .replace(/,/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
    };
    </script>
     
    <style scoped>
    .nonfixed {
        position: inherit;
        padding-left: 20px;
    }
    
    .core-enter-active {
        transition: all 0.3s ease;
    }
    
    .core-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    
    .core-enter,
    .core-leave-to
    
    /* .slide-fade-leave-active below version 2.1.8 */
        {
        transform: translateX(10px);
        opacity: 0;
    }
    
    .core {
        -webkit-transform-origin-y: all 1s ease;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }
    
    .vs-con-table .vs-con-tbody .vs-table--tbody-table {
        font-size: 12px;
    }
    
    .vertical-divider {
        border-right: 1px solid #7367f0;
        /* min-height: 800px; */
        /* height: 100%; */
        -webkit-mask-position-y: fixed;
        /* padding: 5px; */
    }
    
    .vs-con-table.stripe .tr-values:nth-child(2n) {
        background: beige;
    }
    
    .colored {
        border: 1px solid #7367f0;
        position: fixed;
        left: 4%;
        top: 40%;
        max-width: 45%;
        z-index: 999999999999;
        background: antiquewhite;
        background-color: antiquewhite;
    }
    </style>
    