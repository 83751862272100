<template>
  <div class="vx-col sm:w-1/1 w-full">
    <div class="vs-component vs-con-table stripe vs-table-primary">
      <header class="header-table vs-table--header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
        <div class="con-input-search vs-table--search">
          <input
            type="text"
            class="input-search vs-table--search-input"
            v-model="table.search"
            @keyup="handleSearch"
          /><i class="vs-icon notranslate icon-scale material-icons null"
            >search</i
          >
        </div>
      </header>
    </div>
    <div style="overflow-x: auto" class="tableFixHead">
      <table class="table table-hover table-stripped">
        <thead style="background-color: lightgray">
          <tr>
            <slot name="thead"></slot>
            <template v-for="(dataTh, index) in table.header">
              <vs-th
                :key="index"
                :class="index <= FixedColumnNumber ? 'fixed-left' : ''"
                :width="dataTh.width"
              >
                <a
                  v-if="dataTh.sortable != false"
                  href="javascript:void(0);"
                  v-on:click="handleSort(dataTh.value, 'active')"
                  ><div>
                    <b>{{ dataTh.text }}</b>
                    <i class="fa fa-fw fa-sort-amount-desc"></i></div
                ></a>
                <b v-else>{{ dataTh.text }}</b>
              </vs-th>
            </template>
          </tr>
        </thead>
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in table.data">
            <vs-td
              v-for="(head, index) in table.header"
              :key="index"
              :data="table.data[indextr][head.field]"
              >{{ format(head, table.data[indextr][head.field]) }}</vs-td
            >
          </vs-tr>
        </tbody>
      </table>
    </div>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";
export default {
  components: {
    vSelect
  },
  props: {
    FixedLeft: {
      type: Boolean,
      default: true
    },
    FixedColumnNumber: {
      type: Number,
      default: 0
    },
    stripe: {
      type: Boolean,
      default: true
    },
    baseUrl: {
      type: String
    },
    docDate: {
      type: Object,
      default: null
    },
    postingDate: {
      type: Object,
      default: null
    },
    dueDate: {
      type: Object,
      default: null
    },
    operatingUnitID: {
      type: Number
    },
    operatingUnitName: {
      type: Number
    },
    operatingUnit: {
      type: Object
    },
    openKeyDate: {
      type: Date
    },
    statusBalance: {
      type: String
    },
    source: {
      type: String
    },
    sources: {
      type: Array
    },
    draw: {
      type: Number
    }
  },
  watch: {
    draw() {
      this.getData();
    }
    // statusBalance() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // operatingUnitID() {
    //   this.getData();
    // },
    // docDate: {
    //   handler() {
    //     this.getData();
    //   },
    //   deep: true,
    // },
    // dueDate: {
    //   handler() {
    //     this.getData();
    //   },
    //   deep: true,
    // },
    // postingDate: {
    //   handler() {
    //     this.getData();
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.table.data = [];
  },
  data() {
    return {
      table: this.tableDefaultState()
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        header: [
          {
            field: "dist_channel",
            text: "Dist Channel",
            sortable: false
          },
          {
            field: "sales_office",
            text: "Sales Office",
            sortable: false
          },
          {
            field: "sales_office_desc",
            text: "Sales Office Desc",
            sortable: false
          },
          {
            field: "principal_code",
            text: "Principal Code",
            sortable: false
          },
          {
            field: "principal_name",
            text: "Principal Name",
            sortable: false
          },
          {
            field: "cust_grp",
            text: "Cust Grp",
            sortable: false
          },
          {
            field: "cust_grp_name",
            text: "Cust Grp Name",
            sortable: false
          },
          {
            field: "sold_to_party",
            text: "Sold To Party",
            sortable: false
          },
          {
            field: "sold_to_party_name",
            text: "Sold To Party Name",
            sortable: false
          },
          {
            field: "top_code_sys",
            text: "TOP Code Sys",
            sortable: false
          },
          {
            field: "sales_code",
            text: "Sales Code",
            sortable: false
          },
          {
            field: "sales_code_desc",
            text: "Sales Code Desc",
            sortable: false
          },
          {
            field: "po_number",
            text: "PO Number",
            sortable: false
          },
          {
            field: "doc_no",
            text: "Doc No",
            sortable: false
          },
          {
            field: "billing_number",
            text: "Invoice Number",
            sortable: false
          },
          {
            field: "doc_type",
            text: "Doc Type",
            sortable: false
          },
          {
            field: "doc_date",
            text: "Doc Date",
            sortable: false
          },
          {
            field: "posting_date",
            text: "Posting Date",
            sortable: false
          },
          {
            field: "due_date",
            text: "Due Date",
            sortable: false
          },
          {
            field: "tolerance_days",
            text: "Tolerance Days",
            sortable: false
          },
          {
            field: "gl_account",
            text: "GL Account",
            sortable: false
          },
          {
            field: "gl_account_name",
            text: "GL Account Names",
            sortable: false
          },
          {
            field: "fiscal_year",
            text: "Fiscal Year",
            sortable: false
          },
          {
            field: "total_open_item",
            text: "Total Open Item",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "round_amount",
            text: "Round Amount",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "days_past_due",
            text: "Days Past Due",
            sortable: false
          },
          {
            field: "status_balance",
            text: "Status Balance",
            sortable: false
          },
          {
            field: "current",
            text: "Current",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due",
            text: "Total Due",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_15",
            text: "Total Due 15",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_30",
            text: "Total Due 30",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_60",
            text: "Total Due 60",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_90",
            text: "Total Due 90",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_180",
            text: "Total Due 180",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_365",
            text: "Total Due 365",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_730",
            text: "Total Due 730",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "total_due_more_than_730",
            text: "Total Due More Than 730",
            sortable: false,
            format: this.formatPrice
          },
          {
            field: "assignment_number",
            text: "Assignment Number",
            sortable: false
          },
          {
            field: "reference",
            text: "Reference",
            sortable: false
          },
          {
            field: "text",
            text: "Text",
            sortable: false
          },
          {
            field: "updated_by",
            text: "Updated By",
            sortable: false
          },
          {
            field: "trans_type_desc",
            text: "Trans Type Desc",
            sortable: false
          }
        ]
      };
    },
    handleSearch() {
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = active;
      if (key !== null) {
        this.table.sort = key;
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              status_balance: this.statusBalance,
              source: this.source,
              sources: this.sources,
              territory_id: this.operatingUnit.Territory.ID,
              start_due_date: this.dueDate.startDate
                ? moment(this.dueDate.startDate).format("YYYY-MM-DD")
                : null,
              end_due_date: this.dueDate.endDate
                ? moment(this.dueDate.endDate).format("YYYY-MM-DD")
                : null,
              start_doc_date: this.docDate.startDate
                ? moment(this.docDate.startDate).format("YYYY-MM-DD")
                : null,
              end_doc_date: this.docDate.endDate
                ? moment(this.docDate.endDate).format("YYYY-MM-DD")
                : null,
              start_posting_date: this.postingDate.startDate
                ? moment(this.postingDate.startDate).format("YYYY-MM-DD")
                : null,
              end_posting_date: this.postingDate.endDate
                ? moment(this.postingDate.endDate).format("YYYY-MM-DD")
                : null,
              open_key_date: moment(this.openKeyDate).format("YYYY-MM-DD")
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.table.total = 0;
              this.table.totalPage = 0;
              this.table.totalSearch = 0;
              this.table.length = 0;
              this.table.data = [];
              this.setStartEnd();
              this.$vs.loading.close();
              if (resp.status == "error") {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle"
                });
              }
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "ARAGING_REPORT_" +
        this.source +
        "_" +
        this.operatingUnit.Territory.code +
        "_" +
        this.operatingUnit.Territory.name.replace(" ", "_") +
        "_";

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          "DOC_DATE_" +
          moment(this.startDocDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDocDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
          moment(this.startPostingDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endPostingDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startDueDate || this.endDueDate) {
        "DUE_DATE_" +
          moment(this.startDueDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDueDate).format("YYYY-MM-DD") +
          "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD") + "_";

      fileTitle += "STATUS_BALANCE_" + this.statusBalance;

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            source: this.source,
            sources: this.sources,
            territory_id: this.operatingUnit.Territory.ID,
            file: file,
            start_due_date: this.dueDate.startDate
              ? moment(this.dueDate.startDate).format("YYYY-MM-DD")
              : null,
            end_due_date: this.dueDate.endDate
              ? moment(this.dueDate.endDate).format("YYYY-MM-DD")
              : null,
            start_doc_date: this.docDate.startDate
              ? moment(this.docDate.startDate).format("YYYY-MM-DD")
              : null,
            end_doc_date: this.docDate.endDate
              ? moment(this.docDate.endDate).format("YYYY-MM-DD")
              : null,
            start_posting_date: this.postingDate.startDate
              ? moment(this.postingDate.startDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.postingDate.endDate
              ? moment(this.postingDate.endDate).format("YYYY-MM-DD")
              : null,
            open_key_date: moment(this.openKeyDate).format("YYYY-MM-DD"),
            status_balance: this.statusBalance,
            title: fileTitle
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },

    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
};
</script>
<style scoped>
.table {
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
.table tbody {
  /* max-height: 150px;       Just for the demo          */
  overflow-y: auto; /* Trigger vertical scroll    */
  /* overflow-x: hidden;  Hide the horizontal scroll */
  white-space: nowrap;
}
.tableFixHead {
  overflow-y: auto;
  max-height: 450px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
}
.tableFixHead,
/* .tableFixHead td {
  box-shadow: inset 1px 1px #000;
} */
.tableFixHead th {
  box-shadow: inset 1px 1px #000, 1 1px #000;
}
.tableFixHead .fixed-left {
  z-index: 2;
}
</style>
