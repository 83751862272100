<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto">
                        <vs-button class="ml-4 mt-2" color="primary" icon-pack="feather" icon="icon-prin" @click="downloadExcel()">Download Excel</vs-button>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataSalesOrder" stripe border>
                    <template slot="thead">
                        <vs-th>Sales Office</vs-th>
                        <vs-th>Sales Office Desc</vs-th>
                        <vs-th>Order Type</vs-th>
                        <vs-th>PO Number</vs-th>
                        <vs-th>PO Date</vs-th>
                        <vs-th>SO Number</vs-th>
                        <vs-th>SO Date</vs-th>
                        <vs-th>SO Creation Date</vs-th>
                        <vs-th>DO Number</vs-th>
                        <vs-th>DO Date</vs-th>
                        <vs-th>GI Number</vs-th>
                        <vs-th>GI Date</vs-th>
                        <vs-th>GI DPP Amt</vs-th>
                        <vs-th>GI ficodoc Ref ID</vs-th>
                        <vs-th>GI Posting Journal Status</vs-th>
                        <vs-th>GI Remarks</vs-th>
                        <vs-th>FG_GI Posted</vs-th>
                        <vs-th>Bill Number</vs-th>
                        <vs-th>Bill ficodoc Ref ID</vs-th>
                        <vs-th>Bill Posting Journal Status</vs-th>
                        <vs-th>Bill Remarks</vs-th>
                        <vs-th>ARPosted</vs-th>
                        <vs-th>Updated By</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <!-- Turquoise -->
                        <!-- Violet -->
                        <!-- LightSeaGreen -->
                        <!-- Tomato -->
                        <!-- Brown -->
                        <!-- SeaGreen -->
                        <!-- approval_status: 0,
                        approval_manager: 0,
                        approval_release: 0,
                        validation_status: 0,
                        allocation_status: 0,
                        0: (default) belom di allocation
                        1: complete => terpenuhi semua qty & item
                        2: partial - redeliver => create SO baru sesuai Qty yg tidak terpenuhi
                        3: partial - confirmation => Qty req tidak terpenuhi semua =>  -->
                        <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
                            <vs-td>{{dt.OperatingUnit ? dt.OperatingUnit.Code : '-'}}</vs-td>
                            <vs-td>{{dt.OperatingUnit ? dt.OperatingUnit.Name : '-'}}</vs-td>
                            <vs-td>ZDSO</vs-td>
                            <vs-td>{{dt.SoReferenceCode}}</vs-td>
                            <vs-td>{{dateFormat(dt.Date)}}</vs-td>
                            <vs-td>{{dt.Code}}</vs-td>
                            <vs-td>{{dateFormat(dt.Date)}}</vs-td>
                            <vs-td>{{(dt.DefaultAttribute) ? dateFormat(dt.DefaultAttribute.CreatedAt) : '-'}}</vs-td>
                            <vs-td>{{(dt.DeliveryOrder) ? dt.DeliveryOrder.Code : '-'}}</vs-td>
                            <vs-td>{{(dt.DeliveryOrder) ? dateFormat(dt.DeliveryOrder.Date) : '-'}}</vs-td>
                            <vs-td>{{(dt.ProofOfDelivery) ? dt.ProofOfDelivery.GiNumber : '-'}}</vs-td>
                            <vs-td>{{(dt.ProofOfDelivery) ? dateFormat(dt.ProofOfDelivery.Date) : '-'}}</vs-td>
                            <vs-td align="right">{{dt.ProofOfDelivery && dt.ProofOfDelivery.Code ? formatCurrency(dt.totalMAP) : ''}}</vs-td>
                            <vs-td>{{(dt.ProofOfDelivery) ? dt.ProofOfDelivery.Code : '-'}}</vs-td>
                            <vs-td>{{(dt.JournalGI.id != 0) ? dt.JournalGI.result_status == 0 ? "Error" : dt.JournalGI.result_message : '-'}}</vs-td>
                            <vs-td>{{(dt.ProofOfDelivery) ? dt.ProofOfDelivery.Notes : '-'}}</vs-td>
                            <vs-td>{{(dt.JournalGI.id != 0) ? formatCurrency(dt.JournalGI.total) : '-'}}</vs-td>
                            <vs-td>{{(dt.Invoice) ? dt.Invoice.Code : '-'}}</vs-td>
                            <vs-td>{{(dt.Invoice) ? dt.Invoice.InvoiceReferenceCode : '-'}}</vs-td>
                            <vs-td>{{(dt.JournalCI.id != 0) ? dt.JournalCI.result_status == 0 ? "Error" : dt.JournalCI.result_message : '-'}}</vs-td>
                            <vs-td></vs-td>
                            <vs-td>{{(dt.Invoice) ? formatCurrency(dt.Invoice.Total) : 0}}</vs-td>
                            <vs-td>{{dt.Salesman.Name}}</vs-td>
                            
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
        <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
                </div>
            </div>
        </vs-popup>
    </div>
</template>


<script>
import detailSelectedSo from "../component/detail_selected_so.vue";
import moment from 'moment'

export default {
    components: {
        detailSelectedSo
    },
    props: 
        ["progress", "progressWarehouse", "progressRestriction", "isCancel","operatingUnit", "customerCategory", "isClosed", "isBilled", "salesPersonal", "labelString", "territory", "filterDate", "filterPostingDate"],
        mounted() {
            this.selectedSO = this.dataSalesOrder
        },
        data() {
            return {
                selectedSO: {},
                dataSalesOrder: [{
                    SalesOrderLine: [{}],
                    Customer: {
                        Code: "",
                        Name: "",
                        Address: "",
                        Phone: "",
                        CustomerCategory: {
                            Name: ""
                        }
                    },
                    Company: {
                        Code: "",
                        Name: "",
                    },
                    BillAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    ShipAddress: {
                        Address: "",
                        City: "",
                        Country: "",
                    },
                    Salesman: {
                        Name: "",
                    },
                    PaymentTerm: {
                        Name: "",
                    }
                }],
                salesOrderCode: "",

                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
            };
        },
    watch: {
        watchedProperties: function() {
            // this.getData();
        }
    },
    computed: {
        watchedProperties() {
            return `${this.territory}||${this.customerCategory}||${this.search}||${this.salesPersonal.id}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}`;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        async downloadExcel() {
            // if(this.recordsTotal <= 0) {
            //     this.$vs.notify({
            //         color: "warning",
            //         title: "Data not found",
            //         text: "Can't generating data",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-x-circle"
            //     });

            //     return true;
            // }

            // this.$vs.notify({
            //     color: "primary",
            //     title: "Processing . . . .",
            //     text: "Generating "+this.recordsTotal+" data",
            //     position: "top-right",
            //     iconPack: "feather",
            //     icon: "icon-x-circle"
            // });
            this.$vs.notify({
                color: "primary",
                title: "Processing . . . .",
                text: "Generating data",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
            });

            var parameter = {
                territory_code: this.territory,
                customer_category_id: this.customerCategory,
                progress_status: this.progress,
                progress_restriction: this.progressRestriction,
                is_cancel: this.isCancel,
                is_closed: this.isClosed,
                is_billed: this.isBilled,
                sales_personal_id: this.salesPersonal.id,
                search: this.search,
                limit: 100000,
                offset: 0,
                start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                start_posting_date: this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                end_posting_date: this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
            };

            if(this.progressRestriction == false) {
                parameter.approval_status = 0;
                parameter.approval_manager = 0;
                parameter.approval_release = 0;
                parameter.validation_status = 0;
                parameter.allocation_status = 0;
            }

            this.$http.get("/api/v1/report/o2cflow", {
                params: parameter,
                responseType: "arraybuffer",
                headers: {"Accept": "application/vnd.ms-excel"},
            }).then(resp => {
                let dateNow = new Date();
                let blob = new Blob([resp], {type: "application/csv"})

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = this.dateFormat(dateNow)+"_o2cflow.xlsx";
                link.click();
                
                this.$vs.loading.close();

                // this.$vs.notify({
                //     color: "primary",
                //     title: "Downloaded",
                //     text: "Total "+this.recordsTotal+" data generated",
                //     position: "top-right",
                //     iconPack: "feather",
                //     icon: "icon-x-circle"
                // });
                this.$vs.notify({
                    color: "primary",
                    title: "Downloaded",
                    text: "Data generated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            });
        },

        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY')
            }
        },

        formatCurrency(nominal) {
            if(nominal) { // nominal > 0 untuk handle return NaN
                if(nominal > 0) {
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                } else {
                    nominal = nominal * -1
                    nominal = (nominal/1).toFixed(2).replace('.', ',');
                    return "-"+nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            } else {
                return 0;
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        getSOLine () {
            const search = this.salesOrderCode;
            if (search) {
                this.selectedSO = this.dataSalesOrder.filter(so => so.Code.indexOf(search) > -1)[0] // filter + get 1st record
            }
        },

        clickSOCode(salesOrder) {
            this.closeDetail();
            setTimeout(function() {}, 3000);
            this.salesOrderCode = salesOrder.Code;
            this.getSOLine();
            this.detail = true;
            this.scrollToTop();
        },

        closeDetail() {
            this.detail = false;
            this.$emit("selectSalesOrder", null);
        },

        page(page) {
            // this.getData(page);
            return page;
        },

        changeLimit(limit) {
            if (limit == "All") {
                limit = 0
            }
            this.limitShow = limit;
            // this.getData(this.isActive);
        },

        getData(page = 1) {
            if(this.territory==null || this.territory=="" || this.territory==0 || this.territory==undefined) {
                return true;
            }
            this.isActive = page;
            this.$vs.loading();

            var parameter = {
                territory_code: this.territory,
                customer_category_id: this.customerCategory,
                progress_status: this.progress,
                progress_restriction: this.progressRestriction,
                is_cancel: this.isCancel,
                is_closed: this.isClosed,
                is_billed: this.isBilled,
                sales_personal_id: this.salesPersonal.id,
                search: this.search,
                limit: this.limitShow,
                offset: this.limitShow * (page - 1),
                start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
                end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
                start_posting_date: this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
                end_posting_date: this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
            };

            if(this.progressRestriction == false) {
                parameter.approval_status = 0;
                parameter.approval_manager = 0;
                parameter.approval_release = 0;
                parameter.validation_status = 0;
                parameter.allocation_status = 0;
            }

            this.$http.get("/api/v1/pod-data", {
            // this.$http.get("/api/v1/report/so-do-gi-bill-v2", {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataSalesOrder = resp.data.SalesOrders;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.checkedAll = false;
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>