<template>
  <div>
    <vx-card title="Credit Note Temporary">
      <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px"> -->
      <div class="flex flex-col gap-6">
        <div class="flex gap-6 w-full">
          <div class="flex flex-col gap-4 sm:w-1/2 w-full">
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Customer</span>
              </div>
              <div class="vx-col sm:w-full w-full">
                <multiselect
                  v-validate="'required'"
                  name="customer"
                  class="selectExample"
                  v-model="create.customer"
                  :options="customerOption"
                  :multiple="false"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="160"
                  :limit="4"
                  placeholder=" Type to search"
                  track-by="ID"
                  label="name"
                  :loading="loading.customer"
                  :custom-label="queryCustomer"
                  @search-change="getOptionCustomer"
                  @select="onCustomerSelected"
                  @remove="onCustomerRemoved"
                  :disabled="!isCreate"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <span class="option__title"
                          >({{ props.option.code }}),
                          {{ props.option.name }}</span
                        >
                      </span>
                    </div>
                  </template>

                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                      <span>{{ props.option.name }}</span>
                      <i
                        class="multiselect__tag-icon"
                        @click.prevent
                        @mousedown.prevent.stop="
                          props.remove(props.option, $event)
                        "
                      />
                    </div>
                  </template>
                </multiselect>
                <span
                  v-show="errors.has('customer')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("customer") }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Territory</span>
              </div>
              <div class="vx-col sm:w-full w-full">
                <vs-input
                  class="w-full"
                  v-model="territoryHO"
                  disabled="true"
                />
                <!-- <multiselect
                  v-validate="'required'"
                  name="territory"
                  class="selectExample"
                  v-model="territory"
                  :options="optionTerritory"
                  :multiple="false"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="160"
                  :limit="4"
                  placeholder=" Type to search"
                  track-by="id"
                  label="name"
                  :disabled="false"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <span class="option__title"
                          >({{ props.option.code }}),
                          {{ props.option.name }}</span
                        >
                      </span>
                    </div>
                  </template>

                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                      <span>{{ props.option.name }}</span>
                      <i
                        class="multiselect__tag-icon"
                        @click.prevent
                        @mousedown.prevent.stop="
                          props.remove(props.option, $event)
                        "
                      />
                    </div>
                  </template>
                </multiselect>
                <span
                  v-show="errors.has('territory')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("territory") }}
                </span> -->
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Document Date</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <datepicker
                  class="w-full"
                  v-validate="'required'"
                  :inline="false"
                  name="date"
                  v-model="create.date"
                  :danger="errors.has('date')"
                  :disabled="viewOnly"
                  :disabled-dates="{ from: new Date() }"
                ></datepicker>
                <span
                  v-show="errors.has('date')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("date") }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <datepicker
                  class="w-full"
                  v-validate="'required'"
                  :inline="false"
                  name="posting date"
                  v-model="create.postingDate"
                  :danger="errors.has('posting date')"
                  :disabled="viewOnly"
                  :disabled-dates="{ from: new Date() }"
                ></datepicker>
                <span
                  v-show="errors.has('posting date')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("posting date") }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4 sm:w-1/2 w-full">
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Invoice Code</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <multiselect
                  v-validate="'required'"
                  name="invoice number"
                  v-model="create.invoices"
                  :options="invoiceOptions"
                  :multiple="true"
                  :max-height="160"
                  :limit="3"
                  placeholder="Type Invoice Code"
                  label="code"
                  :taggable="true"
                  :loading="loading.invoice"
                  track-by="code"
                  @search-change="onAddTag"
                  @tag="onTagInput"
                  ref="tagInput"
                  :disabled="viewOnly"
                >
                  <template slot="tag" slot-scope="props">
                    <div class="multiselect__tag">
                      <span>{{ props.option.code }}</span>
                      <i
                        class="multiselect__tag-icon"
                        @click.prevent
                        @mousedown.prevent.stop="
                          props.remove(props.option, $event)
                        "
                      />
                    </div>
                  </template>
                </multiselect>
                <span
                  v-show="errors.has('invoice number')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("invoice number") }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Header Note</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <vs-input
                  class="w-full"
                  v-model="create.note"
                  :disabled="viewOnly"
                />
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Import Invoice</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <div class="flex items-center gap-3">
                  <vs-input
                    :v-validate="isCreate ? 'required' : ''"
                    v-model="importInv"
                    @change="onFileImportChange"
                    type="file"
                    multiple
                    class="w-full"
                    :disabled="viewOnly"
                    name="attachment"
                  />
                  <vs-button
                    color="primary"
                    iconPack="feather"
                    icon="icon-upload"
                    :disabled="viewOnly"
                    @click="onClickImportFiles"
                  >Import</vs-button>
                </div>
                <span
                  v-show="errors.has('attachment')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("attachment") }}
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <div class="vx-col sm:w-ful w-full">
                <span>Attachment</span>
              </div>
              <div class="vx-col sm:w-ful w-full">
                <div class="flex items-center gap-3">
                  <vs-input
                    :v-validate="isCreate ? 'required' : ''"
                    v-model="fileName"
                    @change="onFileChange"
                    type="file"
                    multiple
                    class="w-full"
                    :disabled="viewOnly"
                    name="attachment"
                  />
                  <vs-button
                    color="danger"
                    iconPack="feather"
                    icon="icon-x"
                    @click="clearFiles"
                    :disabled="viewOnly"
                  />
                </div>
                <span
                  v-show="errors.has('attachment')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("attachment") }}
                </span>
              </div>
            </div>
            <div
              v-if="
                !isCreate &&
                creditNote.Attachment &&
                creditNote.Attachment.length > 0
              "
              class="flex flex-col gap-1 w-full"
            >
              <div class="vx-col sm:w-ful w-full">
                <span>List Attachment</span>
              </div>
              <div
                class="flex w-full justify-between items-center"
                v-for="(file, i) in creditNote.Attachment"
                :key="i"
              >
                <span class="font-bold">{{ file.NameFile }}</span>
                <div class="flex gap-3">
                  <vx-tooltip text="Delete">
                    <vs-button
                      v-if="!viewOnly"
                      color="danger"
                      iconPack="feather"
                      icon="icon-trash-2"
                      @click="handleShowDeletePrompt(file)"
                      :disabled="viewOnly"
                      type="border"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Download">
                    <vs-button
                      type="border"
                      icon-pack="feather"
                      icon="icon-download"
                      @click.stop="downloadFileAwsS3(file.PathFile)"
                    />
                  </vx-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-between items-center mt-4">
          <div class="vx-col w-full gap-3">
            <vs-table stripe :data="validatedInvoices">
              <template slot="thead">
                <vs-th> # </vs-th>
                <vs-th> Invoice Territory </vs-th>
                <vs-th> Invoice Code </vs-th>
                <vs-th> Value</vs-th>
                <vs-th> Remaining </vs-th>
                <vs-th> Payment Allocate </vs-th>
                <vs-th> Reason </vs-th>
                <vs-th> Note </vs-th>
                <vs-th v-if="!viewOnly"></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="{ position: 'relative', zIndex: 100 - indextr }">
                  <vs-td>
                    {{ indextr + 1 }}
                  </vs-td>
                  <vs-td>
                    {{ territoryData[indextr].code + " - " + territoryData[indextr].name }}
                      <!-- {{ indextr + 1 }} -->
                  </vs-td>
                  <vs-td>
                    {{ tr.Code }}
                  </vs-td>
                  <vs-td>
                    {{ priceFormat(tr.Total) }}
                  </vs-td>
                  <vs-td>
                    {{ priceFormat(tr.Status == 1 ? tr.Total : tr.Remaining) }}
                  </vs-td>
                  <vs-td>
                    <div>
                      <vue-number
                        :name="tr.Code"
                        v-validate="
                          `required|between:1,${
                            tr.Status == 1
                              ? parseFloat(
                                  priceFormat(tr.Total).replace(/,/g, '')
                                )
                              : parseFloat(
                                  priceFormat(tr.Remaining).replace(/,/g, '')
                                )
                          }`
                        "
                        :customClass="`w-full`"
                        v-model="allocateModels[indextr].paid"
                        :masked="true"
                        :disabled="viewOnly"
                      ></vue-number>
                      <span
                        v-show="errors.has(tr.Code)"
                        class="text-danger text-sm help is-danger"
                      >
                        {{ errors.first(tr.Code) }}
                      </span>
                    </div>
                  </vs-td>
                  <vs-td>
                    <multiselect
                      ref="dropdowns"
                      v-validate="'required'"
                      name="reason"
                      class="selectExample"
                      v-model="tr.reason"
                      :options="optionReasons"
                      :multiple="false"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="120"
                      :limit="3"
                      placeholder="Type to search"
                      track-by="reason"
                      label="reason"
                      :disabled="viewOnly"
                      @open="handleDropdownOpen(indextr)"
                      :style="{ zIndex: 9999, position: 'relative' }"
                      append-to-body
                    >
                    </multiselect>
                    <span
                      v-show="errors.has('reason')"
                      class="text-danger text-sm help is-danger"
                    >
                      {{ errors.first("reason") }}
                    </span>
                  </vs-td>
                  <vs-td>
                    <vs-input
                      class="w-full"
                      v-validate="'required'"
                      name="item note"
                      v-model="tr.note"
                      :disabled="viewOnly"
                    />
                    <span
                      v-show="errors.has('item note')"
                      class="text-danger text-sm help is-danger"
                    >
                      {{ errors.first("item note") }}
                    </span>
                  </vs-td>
                  <vs-td v-if="!viewOnly">
                    <vs-button
                      v-if="indextr != 0"
                      class="mr-2"
                      style="display: table-cell;"
                      v-on:click="handleRemoveItem(indextr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                    ></vs-button>
                    <vs-button
                      style="display: table-cell;"
                      v-on:click="handleAddItem(indextr)"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td>
                    <b>Total</b>
                  </vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td> {{ priceFormat(totalInvoice) }} </vs-td>
                  <vs-td>
                    <b>{{ priceFormat(totalRemainingInvoice) }}</b>
                  </vs-td>
                  <vs-td>
                    <b>{{ priceFormat(totalPaidInvoice) }}</b>
                  </vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td v-if="!viewOnly"></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <vs-divider
          v-if="
            !isCreate &&
            Object.keys(creditNote).length > 0 &&
            creditNote.Info.used_credit_notes.length > 0
          "
        />
        <div
          v-if="
            !isCreate &&
            Object.keys(creditNote).length > 0 &&
            creditNote.Info.used_credit_notes.length > 0
          "
          class="vx-col w-full flex flex-col gap-3"
        >
          <span>Clearing Credit Notes</span>
          <vs-table :data="creditNote.Info.used_credit_notes">
            <template slot="thead">
              <vs-th> Code </vs-th>
              <vs-th> Type </vs-th>
              <vs-th> Clearing Value </vs-th>
              <vs-th> Invoice </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <!-- {{ tr.code }} -->
                    {{ tr.id == undefined ? tr.coa_code : tr.code }}
                </vs-td>
                <vs-td>
                  <!-- {{ tr.type == "Off Invoice Principal MT MIX" ? "Claim" : (tr.type == "Off Invoice Principal GT MIX" ? "Claim" : tr.type) }} -->
                    {{ tr.id == undefined ? "Coa ("+tr.coa_name+")" : (tr.type == "Off Invoice Principal MT MIX" ? "Claim" : (tr.type == "Off Invoice Principal GT MIX" ? "Claim" : tr.type)) }}
                </vs-td>
                <vs-td>
                  {{ priceFormat(tr.clearing_value) }}
                </vs-td>
                <vs-td>
                  Code : {{ tr.invoice_code }} <br/>
                  Reason : {{ tr.invoice_reason }} <br/>
                  Note : {{ tr.invoice_note }} <br/>
                  Payment Code : {{ tr.payment_code }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col w-full">
          <br />
          <!-- display: block -->
          <template>
            <vs-button
              class="mb-2 mr-2"
              color="danger"
              v-on:click="navigateBack()"
            >
              Close
            </vs-button>
            <vs-button
              v-if="!viewOnly"
              class="mb-2 mr-2"
              v-on:click="handleSubmit(false)"
            >
              Save
            </vs-button>
            <vs-button
              v-if="!viewOnly"
              class="mb-2"
              v-on:click="handleSubmit(true)"
            >
              Save & Confirm
            </vs-button>
          </template>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="flex flex-col gap-4 w-full items-end">
        <div><vs-button>Close</vs-button></div>
      </div> -->
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="handleCloseDeletePrompt"
        @accept="deleteAttachment"
        @close="handleCloseDeletePrompt"
        :active.sync="showDeletePrompt"
      >
        <p>Are you sure want to delete this attachment?</p>
      </vs-prompt>
      <vs-prompt
        color="success"
        title="Success"
        @cancel="handleAcceptCreatedPrompt"
        @close="handleAcceptCreatedPrompt"
        :active.sync="createdPrompt"
        :buttons-hidden="true"
      >
        <p>Credit Note {{ createdData.Code }} already created</p>
      </vs-prompt>
    </vx-card>
  </div>
</template>
<script>
import debounce from "debounce";
import "flatpickr/dist/flatpickr.css";
import Datepicker from "vuejs-datepicker";
import VueNumber from "@/components/vue-number/component.vue";
import defaultOptions from "@/components/vue-number/options";
import NumberFormat from "@/components/vue-number/number-format";
import moment from "moment";
import * as XLSX from 'xlsx';

export default {
  components: {
    VueNumber,
    datepicker: Datepicker,
  },
  created() {},
  data() {
    return {
      debounceFunction: null,
      create: {
        customer: {},
        invoices: [],
        date: null,
        postingDate: null,
        note: "",
      },
      customerOption: [],
      invoiceOptions: [],
      validatedInvoices: [],

      loading: {
        customer: false,
        shipto: false,
        invoice: false,
      },
      creditNote: {},
      viewOnly: false,
      isCreate: true,
      isQueryDone: false,
      allocateModels: [],
      territoryData: [],
      files: [],
      optionTerritory: [],
      fileName: "",
      importInv: "",
      importFiles: [],
      importData: [],
      showDeletePrompt: false,
      createdPrompt: false,
      createdData: {},
      selectedFile: null,
      territory: null,
      optionReasons: [
        { id: 1, reason: "PPH" },
        { id: 2, reason: "Return" },
        { id: 3, reason: "Promo" },
      ],
      territoryHO: "Head Office",
      loadValidateInvoices: false,
    };
  },
  computed: {
    totalInvoice() {
      let total = 0.0;
      this.validatedInvoices.forEach((v) => {
        total += parseFloat(v.Total);
      });
      return total;
    },
    totalRemainingInvoice() {
      let total = 0.0;
      this.validatedInvoices.forEach((v) => {
        total += v.Status == 1 ? parseFloat(v.Total) : parseFloat(v.Remaining);
      });
      return total;
    },
    totalPaidInvoice() {
      const temp = [...this.allocateModels];
      return temp
        .map((v) => parseFloat(v.paid.replace(/,/g, "")))
        .reduce((acc, curr) => acc + curr, 0);
    },
    totalAvailable() {
      if (
        this.create.creditNote.length === 0 &&
        this.create.custDeposit.length === 0
      ) {
        if (this.create.amountWithoutFormat > 0) {
          return this.create.amountWithoutFormat;
        }
        return 0;
      }
      console.log(this.create.creditNote, this.create.custDeposit, "cek ini");
      const cn = this.create.creditNote
        .map((v) => parseInt(v.available))
        .reduce((acc, curr) => acc + curr, 0);
      console.log(cn, "cn");
      if (cn > 0) {
        return cn;
      }
      const cd = this.create.custDeposit
        .map((v) => parseInt(v.DepositValue))
        .reduce((acc, curr) => acc + curr, 0);
      if (cd > 0) {
        return cd;
      }

      return 0;
    },
    paidInvoice() {
      return {
        get(index) {
          return this.$store.state.arPaymentReceive.selectedInvoice[index];
        },
        set(index, value) {
          this.$store.commit("arPaymentReceive/setPaidWithoutFormat", {
            index: index,
            value: value,
          });
        },
      };
    },
    // selectedInvoices() {
    //   return {
    //     get() {
    //       return this.$store.state.arPaymentReceive.selectedInvoice;
    //     },
    //     set(value) {
    //       this.$store.commit("arPaymentReceive/setselectedInvoice", value);
    //     },
    //   };
    // },
  },
  async mounted() {
    this.loading.invoice = true;
    await this.getInvoiceOption();
    // await this.getOptionOutstanding();
    await this.getOptionTerritory();
    this.loading.invoice = false;
    if (this.$route.params.id) {
      const id = atob(this.$route.params.id);
      const path = this.$router.currentRoute.path.toLowerCase();
      if (path.includes("view")) {
        this.viewOnly = true;
        this.isCreate = false;
      } else if (path.includes("edit")) {
        this.viewOnly = false;
        this.isCreate = false;
      }
      await this.getCreditNote(id);
      await this.loadData();

      this.loadValidateInvoices = true;
    } else {
      this.create.date = new Date();
      this.create.postingDate = new Date();
    }
  },
  watch: {
    "create.invoices": function () {
      if (this.create.invoices.length === 0) {
        this.validatedInvoices = [];
        this.allocateModels = [];
        this.territoryData = [];
        this.create.customer = {};
        return;
      }
      console.log(this.loadValidateInvoices, "watch create invoice");
      if (this.isCreate || this.loadValidateInvoices == true) {
        this.validateInvoices();
      }
      // this.validateInvoices();
      console.log("watch create invoice");
    },
  },
  methods: {
    navigateBack() {
      return this.$router.push({ name: "cn_temporary.index" });
    },
    async loadData() {
      try {
        console.log(this.creditNote, "creditNote");
        let resp = await this.fetchCustomer(
          this.creditNote.CustomerCode,
          this.creditNote.CustomerCode,
          this.creditNote.CustomerID
        );
        if (resp.code < 299) {
          this.create.customer = resp.data[0];

          this.getInvoiceOption();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
        }

        this.create.date = new Date(
          moment(this.creditNote.Date).format("YYYY-MM-DD")
        );
        this.create.postingDate = new Date(
          moment(this.creditNote.PostingDate).format("YYYY-MM-DD")
        );
        this.create.note = this.creditNote.Note;

        const ids = [];
        const codes = [];

        this.creditNote.Info.invoices.forEach((v) => {
          ids.push(v.ID);
          codes.push(v.Code);
          this.create.invoices.push({
            ID: v.ID,
            code: v.Code,
          });
        });

        this.territory = this.optionTerritory.find(v => v.id == this.creditNote.TerritoryID)

        await this.validateInvoices();

        console.log(this.allocateModels, "allocateModels");
      } catch (e) {
        console.log(e);
      }
    },
    amountWithoutFormat(value) {
      console.log(value, "amountWithoutFormat");
      this.create.amountWithoutFormat = value;
    },
    async getValidatedInvoice(params) {
      return this.$http.get("/api/v1/payment/validate/code-invoice", {
        params,
      });
    },
    fillAllocateModels() {
      let formatNumber = new NumberFormat(defaultOptions);
      this.validatedInvoices.forEach((v) => {
        this.allocateModels.push({
          paid: formatNumber.format(v.Remaining),
        });
      });
    },

    loadImport() {
      // console.log(this.$store.state.arPaymentReceive.filter.reason, "reason");
      // console.log(this.validatedInvoices, "invoices");

      let reasonList = this.optionReasons;

      this.importData.forEach((row)=> {

        //check reason
        let reasonText = row[2].toLowerCase().trim();

        console.log(reasonText, "reasonText");
        let matchingReason = reasonList.find(x => {
          return x.reason.toLowerCase() === reasonText;
        });

        console.log(matchingReason, "reasonText");

        let invoiceIndex = this.validatedInvoices.findIndex(x => x.Code == row[0]);

        if (invoiceIndex !== -1) {

          this.allocateModels[invoiceIndex].paid = this.priceFormat(row[1]);
          this.validatedInvoices[invoiceIndex].note = row[3];

          if (matchingReason) {
            this.validatedInvoices[invoiceIndex].reason = matchingReason;
          }
        }
      })
      
      this.clearImportFiles();
    },

    async validateInvoices() {
      try {
        console.log(this.create.invoices, "param invoice");
        const isCustomerSelected =
          this.create.customer && Object.keys(this.create.customer).length > 0;

        const path = this.$router.currentRoute.path.toLowerCase();
        const isView = path.includes("view");
        const param = {
          invoice_code: this.create.invoices.map((i) => i.code),
          customer_code:
            this.create.customer && isCustomerSelected
              ? this.create.customer.code
              : undefined,
          status_in: isView ? [0, 1, 2, 3, 4] : [1, 2, 3],
        };

        this.$vs.loading();
        const resp = await this.getValidatedInvoice(param);
        if (resp.code < 299) {
          const temp = [...resp.data.invoices];
          // const temp = []
          console.log(resp.data.invoices, "note nya disini atas");
          console.log(temp, "note nya disini atas");
          this.allocateModels = [];
          this.territoryData = [];

          // this.validatedInvoices = temp;

          if (temp.length === 0) {
            this.$vs.notify({
              title: "Error",
              text: "No invoice found",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
            this.$vs.loading.close();
            return;
          }
          
          const clientCode = temp[0].ClientCode;
          const clientName = temp[0].ClientName;
          const clientID = temp[0].ClientID;
          for (let v of temp) {
            if (
              v.ClientCode !== clientCode ||
              v.ClientName !== clientName ||
              v.ClientID !== clientID
            ) {
              this.$vs.notify({
                title: "Error",
                text: "Please select invoice with same customer",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x",
              });
              this.validatedInvoices = [];
              this.allocateModels = [];
              this.territoryData = [];
              return;
            }
          }

          let formatNumber = new NumberFormat(defaultOptions);

          if (this.isCreate) {
            for (let [idx, v] of temp.entries()) {
              this.allocateModels.push({
                paid:
                  v.Status === 1
                    ? formatNumber.format(v.Total)
                    : formatNumber.format(v.Remaining),
              });

              const territory = await this.fetchTerritory(v.TerritoryID)
              console.log(territory, "territory disini");
              this.territoryData.push({
                code: territory.code,
                name: territory.name,
              });
              
              // this.validatedInvoices[idx].territory = territory
              // console.log(v, "territory disini");
            }

            this.validatedInvoices = temp
          } else {
            console.log(temp, "territory disini 43");
            let invPush = []

            for (let [idx, v] of temp.entries()) {
              console.log(this.creditNote.Info.invoices, "invoices disini");
              const matchingInvoices = this.creditNote.Info.invoices.filter(
                (i) => i.Code === v.Code
              );

              for (let inv of matchingInvoices) {
                if (inv) {
                  this.allocateModels.push({
                    paid: formatNumber.format(inv.paid),
                  });
                } else {
                  this.allocateModels.push({
                    paid: formatNumber.format(v.Remaining),
                  });
                }
                console.log(inv, "notenya disini");

                const territory = await this.fetchTerritory(inv.TerritoryID)
                console.log(territory, "territory disini");

                this.territoryData.push({
                  code: territory.code,
                  name: territory.name,
                });

                invPush.push({
                  ...v,
                  note: inv.note,
                  reason: inv.reason,
                  territory: territory
                });
              }

              this.validatedInvoices = invPush
            }
          }

          console.log("validateinvoice: ", this.validatedInvoices);

          if (!isCustomerSelected) {
            const resp = await this.fetchCustomer(
              clientCode,
              clientCode,
              clientID
            );

            if (resp.code < 299) {
              this.create.customer = resp.data[0];
            } else {
              this.$vs.notify({
                title: "Error",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x",
              });
            }
          }

          if (this.importFiles.length > 0) {
            this.loadImport();
            // console.log(this.importData, "import");
          }

          console.log(this.allocateModels, "allocateModels");
          console.log(this.territoryData, "territoryData");
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
        }

        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x",
        });
      }
    },
    resetValue() {
      this.validate.paymentAccount = { required: false };
      this.validate.custDeposit = { required: false };
      this.validate.creditNote = { required: false };
      this.validate.operatingUnitBank = { required: false };
      this.validate.reason = { required: false };
      this.available = 0;
      this.create.creditNote = [];
      this.create.custDeposit = [];

      console.log(this.validate.creditNote, "resetValue");
    },

    // getOptionCustomer: _.debounce(function (val) {
    //     console.log("search")
    //     }, 2000),
    async onCustomerSelected(value) {
      this.getInvoiceOption(value.name, 1);
    },
    async onCustomerRemoved() {
      this.getInvoiceOption(null, 1);
    },
    async getCustomers(val, customer_id = undefined, code = undefined) {
      try {
        let param = {
          search: val,
          ids: [customer_id],
          code,
        };
        this.loading.customer = true;
        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterCustomer",
          param
        );
        this.loading.customer = false;
      } catch (e) {
        console.log(e);
        this.loading.customer = false;
      }
    },
    async getShipToForView(value) {
      try {
        this.$vs.loading();
        const params = {
          id: value.ID,
          search: "",
        };

        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterShipTo",
          params
        );
        await this.$store.dispatch(
          "arPaymentReceive/fetchFilterPaymentAccount",
          value.ID
        );
        await this.$store.dispatch(
          "arPaymentReceive/fetchCreditNote",
          value.ID
        );
        await this.$store.dispatch(
          "arPaymentReceive/fetchCustDeposit",
          value.ID
        );
        await this.$store.dispatch("arPaymentReceive/fetchOuBank");
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    getOptionOutstanding() {
      return new Promise((resolve) => {
        this.$store.dispatch("arPaymentReceive/fetchReason").then((res) => {
          resolve(true);
        });
      });
    },
    async fetchTerritory(territoryID = 0) {
      try {
        const response = await this.$http.get("api/v1/master/territory/" + territoryID);
        return response.data;
      } catch (error) {
        console.error("Error fetching territory:", error);
        return null;
      }
    },
    async fetchCustomer(
      search = null,
      customer_code = null,
      customer_id = null
    ) {
      return this.$http.get("api/v1/master/customer/options", {
        params: {
          search,
          codes: customer_code ? [customer_code] : null,
          ids: customer_id ? [customer_id] : null,
        },
      });
    },
    async fetchInvoice(search = null, invoice_code = null, invoice_id = null) {
      const params = {
        search,
        customer_codes:
          this.create.customer && Object.keys(this.create.customer).length > 0
            ? [this.create.customer.code]
            : [],
        codes: invoice_code ? invoice_code : null,
        ids: invoice_id ? invoice_id : null,
        status_in: [1, 3],
      };

      return this.$http.get("/api/v1/invoice/for-options", {
        params,
      });
    },
    async getInvoiceOption(query, time = 1000) {
      if (query === "") {
        return;
      }
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        try {
          this.loading.invoice = true;
          const resp = await this.fetchInvoice(query);
          if (resp.code < 299) {
            this.invoiceOptions = resp.data;
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
          }
          this.loading.invoice = false;
        } catch (e) {
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
          this.loading.invoice = false;
        }
      }, time);
      this.debounceFunction(query);
    },
    async getOptionCustomer(query) {
      // console.log(query, "query1");
      if (query === "") {
        return;
      }
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        try {
          this.loading.customer = true;
          const resp = await this.fetchCustomer(query);
          console.log("resp", resp);
          if (resp.code < 299) {
            this.customerOption = resp.data;
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x",
            });
          }
          this.loading.customer = false;
        } catch (e) {
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x",
          });
          this.loading.customer = false;
        }
      }, 1000);
      this.debounceFunction(query);
    },
    getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory/head-office", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log(resp.data)
            // this.optionTerritory = resp.data.records;
            this.territory = resp.data
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getgetOptionCustomerAndApply(query) {},

    handleSubmit(isWithConfirm = false) {
      this.$validator.validateAll().then((res) => {
        if (!res) {
          return;
        }

        const invoices = this.validatedInvoices.map((v, i) => {
          return {
            ...v,
            Paid: parseFloat(this.allocateModels[i].paid.replace(/,/g, "")),
          };
        });

        // const temp = new Set(territory_ids);
        // territory_ids = Array.from(temp);

        const posting_date = moment(this.create.postingDate).format(
          "YYYY-MM-DD"
        );
        const date = moment(this.create.date).format("YYYY-MM-DD");

        const data = {
          customer_id: this.create.customer.ID,
          customer_code: this.create.customer.code,
          note: this.create.note,
          posting_date,
          date,
          invoices,
          total_value: this.totalPaidInvoice,
          is_with_confirm: isWithConfirm,
          // territory_id: this.territory.id,
          // territory_area_id: this.territory.territory_area_id,
          // territory_code: this.territory.code,
        };

        const form = new FormData();
        form.append("data", JSON.stringify(data));

        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            form.append("files", this.files[i]);
          }
        }

        this.$vs.loading();
        if (this.isCreate) {
          this.$http
            .post("/api/v1/credit-note/temporary", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.$vs.loading.close();
              if (res.code < 299) {
                this.$vs.notify({
                  title: "Success",
                  text: "Credit Note Temporary has been created",
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check-circle",
                  position: "top-right",
                });
                this.handleShowCreatedPrompt(res.data);
                // this.$router.push({ name: "cn_temporary.index" });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: res.message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-x",
                  position: "top-right",
                });
              }
              // this.$vs.loading.close();
            })
            .catch((e) => {
              console.error(e);
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-x",
                position: "top-right",
              });
              this.$vs.loading.close();
            });
        } else {
          this.$http
            .put(`/api/v1/credit-note/temporary/${this.creditNote.ID}`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.$vs.loading.close();
              if (res.code < 299) {
                this.$vs.notify({
                  title: "Success",
                  text: "Credit Note Temporary has been edited",
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check-circle",
                  position: "top-right",
                });
                this.handleShowCreatedPrompt(res.data);
                // this.$router.push({ name: "cn_temporary.index" });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: res.message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-x",
                  position: "top-right",
                });
              }
              // this.$vs.loading.close();
            })
            .catch((e) => {
              console.error(e);
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-x",
                position: "top-right",
              });
              this.$vs.loading.close();
            });
        }
      });
    },
    queryCustomer({ code, name }) {
      if (!code || !name) return "Select customer";
      return `(${code}), ${name}`;
    },

    async getCreditNote(id) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(`api/v1/credit-note/temporary/${id}`);
        if (resp.code < 299) {
          this.creditNote = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-x",
            position: "top-right",
          });
          this.$vs.loading.close();
        }
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },

    withoutFormat(val, index) {
      console.log("emitted value", val, index);
      this.$store.commit("arPaymentReceive/setPaidWithoutFormat", {
        index: index,
        value: parseFloat(val),
      });
    },
    onTagInput(tag) {
      const tagObject = {
        ID: this.create.invoices.length + 1,
        code: tag,
      };
      this.create.invoices.push(tagObject);
    },
    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            ID: this.create.invoices.length + 1,
            code: tags[i],
          };
          this.create.invoices.push(tagObject);
        }

        this.$refs.tagInput.deactivate();
        this.loading.invoice = true;

        await this.$store.dispatch("arPaymentReceive/fetchInvoiceOptions", {
          codes: this.create.invoices.map((v) => v.Code),
          status_in: [1, 3],
          customer_codes:
            this.create.customer && Object.keys(this.create.customer).length > 0
              ? [this.create.customer.code]
              : [],
        });

        await this.validateInvoices();

        this.loading.invoice = false;
      } else {
        if (tag.length < 3 && tag.length > 0) {
          return;
        }

        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }
        this.debounceFunction = debounce(async () => {
          this.loading.invoice = true;
          this.loading.invoice = false;
        }, 500);
        this.debounceFunction();
      }
    },
    async onInvoiceSelected() {
      this.loading.invoice = true;
      await this.validateInvoices();
      this.loading.invoice = false;
    },
    onFileChange(e) {
      this.files = e.target.files;
    },
    onFileImportChange(e) {
      this.importFiles = e.target.files;
    },
    clearFiles() {
      this.files = [];
      this.fileName = "";
    },
    clearImportFiles() {
      this.importFiles = [];
      this.importInv = "";
    },
    onClickImportFiles() {
      const files = this.importFiles;
      if (files.length === 0) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the first sheet is the one you want
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // let excelData = jsonData.slice(1).map((row) => ({
          // inv: row[0],
          // alokasi: row[1],
          // reason: row[2],
          // note: row[3],
        // }));
        this.create.customer = {};

        jsonData.slice(1).forEach((row) => {
          let invoiceMatch = this.create.invoices.find(x => x.code == row[0])

          if (invoiceMatch) {
            this.$vs.notify({
              title: "Error",
              text: `Invoice `+row[0]+` already selected`,
              color: "danger",
              iconPack: "feather",
              icon: "icon-x",
              position: "top-right",
            });

            return
          }
          
          const tagObject = {
            ID: this.create.invoices.length + 1,
            code: row[0],
          };

          this.create.invoices.push(tagObject);
          this.importData.push(row);

          // this.allocateModels.push(row[1])
          // let idx = this.create.invoices.length - 1;
          // this.validatedInvoices[idx].paid = this.priceFormat(row[1])
          // this.validatedInvoices[idx].note = row[3];
        });

        // this.validatedInvoices[idx].reason = inv.reason

        console.log(this.create.invoices, "Invoices");
      };


      reader.readAsArrayBuffer(files[0]);

      this.$vs.notify({
        title: "Success",
        text: "Invoice imported successfully",
        color: "success",
        iconPack: "feather",
        icon: "icon-check-circle",
        position: "top-right",
      });
    },
    deleteAttachment() {
      this.$vs.loading();
      this.$http
        .delete(
          `/api/v1/credit-note/temporary/attachment/${this.creditNote.ID}`,
          {
            params: {
              list_id: this.selectedFile.ListID,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (res) => {
          if (res.code < 299) {
            this.$vs.notify({
              title: "Success",
              text: "Attachment has been deleted",
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
              position: "top-right",
            });
            const id = atob(this.$route.params.id);
            await this.getCreditNote(id);
            await this.loadData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: res.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-x",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          console.error(e);
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-x",
            position: "top-right",
          });
          this.$vs.loading.close();
        });
    },
    handleAddItem(index){
      console.log("invoice: ", this.validatedInvoices);
      // this.validatedInvoices = [...this.validatedInvoices];
      // this.validatedInvoices.splice(index+1, 0, {})

      const newAllocate = { ...this.allocateModels[index] };
      const newTerritory = { ...this.territoryData[index] };
      const newItem = { ...this.validatedInvoices[index] }; 

      // const remaining = this.validatedInvoices[index].Status == 1 ? this.validatedInvoices[index].Total : this.validatedInvoices[index].Remaining
      // const allocate = remaining - parseFloat(this.allocateModels[index].paid.replace(/,/g, ""))

      // newAllocate.paid = this.priceFormat(allocate)

      this.allocateModels.splice(index + 1, 0, newAllocate);
      this.territoryData.splice(index + 1, 0, newTerritory);
      this.validatedInvoices.splice(index + 1, 0, newItem);
    },
    handleRemoveItem(indextr){
      this.allocateModels.splice(indextr, 1);
      this.territoryData.splice(indextr, 1);
      this.validatedInvoices.splice(indextr, 1);
      // console.log("invoice: ", this.validatedInvoices);
    },
    handleShowDeletePrompt(file) {
      this.selectedFile = file;
      this.showDeletePrompt = true;
    },
    handleCloseDeletePrompt() {
      this.showDeletePrompt = false;
      this.selectedFile = null;
    },
    handleShowCreatedPrompt(data) {
      this.createdData = data;
      this.createdPrompt = true;
    },
    handleCloseCreatedPrompt() {
      this.createdPrompt = false;
      this.createdData = null;
    },
    handleAcceptCreatedPrompt() {
      this.createdPrompt = false;
      this.$router.push({ name: "cn_temporary.index" });
    },
    handleDropdownOpen(indextr) {
      // 3. Access the dropdown for the specific multiselect instance
      const dropdown = this.$refs.dropdowns[indextr].$el.querySelector('.multiselect__content-wrapper');
      
      if (!dropdown) return;  // Ensure the dropdown exists
      
      // Get the dropdown's position relative to the viewport
      const dropdownRect = dropdown.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // 4. Adjust the dropdown's position if it would overflow the viewport
      if (dropdownRect.bottom > viewportHeight) {
        dropdown.style.position = 'absolute';
        dropdown.style.bottom = '100%';
        dropdown.style.top = 'auto';
      } else {
        dropdown.style.position = 'absolute';
        dropdown.style.top = '100%';
        dropdown.style.bottom = 'auto';
      }

      // 5. Set a high z-index to ensure the dropdown appears on top
      dropdown.style.zIndex = 9999;
    }
  },
  destroyed() {
    this.$store.commit("arPaymentReceive/clearState");
  },
};
</script>
<style scoped>
.vertical-divider {
  border-right: 1px solid #626262;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
</style>
