<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide,
      ]"
    >
      <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th width="5%"></vs-th>
          <vs-th>Supplier</vs-th>
          <vs-th sort-key="dn_code">DN Number</vs-th>
          <vs-th sort-key="po_code">PO Number</vs-th>
          <vs-th sort-key="received_date">Received Date</vs-th>
          <vs-th>Warehouse</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Show">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(tr, indextr, data[indextr].remaining_dn_value)
                    "
                    icon="icon-eye"
                  />
                </vx-tooltip>
                <vx-tooltip text="Print">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="printPDF(tr)"
                    icon="icon-printer"
                  />
                </vx-tooltip>
              </template>
              <!-- <template v-if="data[indextr].total_dn == data[indextr].remaining_dn_value">
                <vx-tooltip text="Reversal">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    color="red"
                    icon="icon-corner-up-left"
                    @click.stop="
                      handleReversal(
                        data[indextr].dn_id
                      )
                    "
                  />
                </vx-tooltip>
              </template> -->
            </vs-td>
            <vs-td :data="data[indextr].supplier_name">
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_code }} -
                  {{ data[indextr].supplier_name }}</span
                >
              </div>
              <div class="vx-row ml-0">
                <span class="w-5/5"
                  >{{ data[indextr].supplier_address }},
                  {{ data[indextr].supplier_city }} ({{
                    data[indextr].supplier_contact_name
                  }}
                  - {{ data[indextr].supplier_phone }})</span
                >
              </div>
            </vs-td>
            <vs-td>
              <!-- {{ data[indextr].po_code ? data[indextr].dn_code : "-" }} -->
              DN Number : {{ data[indextr].dn_code }} <br />
              SCN Number :
              {{ data[indextr].cn_number ? data[indextr].cn_number : "-"
              }}<br />
              SR Number : {{ getSupplierReturnNumber(data[indextr].sr_number)
              }}<br />
              <br />
              Total Value :
              {{
                data[indextr].total_dn
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </vs-td>
            <vs-td>
              {{ data[indextr].po_code ? data[indextr].po_code : "-" }}
            </vs-td>
            <vs-td :data="data[indextr].posting_date">
              {{
                formatDateTable(
                  data[indextr].posting_date ? data[indextr].posting_date : "-"
                )
              }}
            </vs-td>
            <vs-td :data="data[indextr].warehouse_code">
              {{ data[indextr].warehouse_code }} <br />
              {{ data[indextr].warehouse_name }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            class="vertical-divider"
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <!-- form component -->
          <detail
            @close="handleClose"
            :dn_id="dn_id"
            :dn_code="dn_code"
            :cn_number="cn_number"
            :cn_date="cn_date"
            :tr_number="tr_number"
            :tr_date="tr_date"
            :remaining_dn_value="remaining_dn_value"
            :notes="notes"
            :tax_invoice_date="tax_invoice_reference_date"
            :tax_invoice_reference="tax_invoice_reference"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
    <vs-popup
      fullscreen
      title="Print"
      :active.sync="showPdf"
      :button-close-hidden="true"
    >
      <div class="flex flex-col gap-6" style="height: 80dvh">
        <iframe :src="pdfUrl" class="w-full" style="height: 95%" />
        <div class="flex gap-3 w-full justify-end">
          <vs-button color="danger" @click="handleClosePopUp">Cancel</vs-button>
          <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import Detail from "./detail.vue";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";
export default {
  components: {
    Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      tax_invoice_reference: "",
      tax_invoice_reference_date: "",
      notes: "",
      remaining_dn_value: 0,
      showPdf: false,
      pdfUrl: "",
      pdfProxy: null,
      selectedDebitNote: {},
      supplierReturnDetails: null,
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
            status: 1,
            type: "return",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    handleReversal(id) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/reversal/" + id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(data, index, remaining) {
      this.dn_id = data.dn_id;
      this.notes = data.notes;
      this.dn_code = data.dn_code;
      this.cn_number = data.cn_number;
      this.cn_date = data.cn_date == "0001-01-01" ? "" : data.cn_date;
      this.tr_number = data.tr_number;
      this.tr_date = data.tr_date == "0001-01-01" ? "" : data.tr_date;
      this.remaining_dn_value = remaining;
      this.table.data = [];
      this.table.data = this.data;
      this.tax_invoice_reference_date = data.tax_invoice_reference_date;
      this.tax_invoice_reference = data.tax_invoice_reference_ref_text;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
    handlePrint(tr) {
      window.open(
        `/account-payable/dn-with-or-without-po-ref/print-tax-return/${tr.dn_id}`
      );
    },
    getSupplierReturnNumber(sr_number) {
      if (sr_number.includes("; ")) {
        const temp = sr_number.split(";");
        if (temp.length === 2) {
          return temp[0];
        } else {
          return temp.join(", ");
        }
      } else {
        return sr_number;
      }
    },
    handlePrintPDFPreview() {
      const fileName = `Nota retur_${this.selectedDebitNote.debitNotes.Code}.pdf`;
      this.pdfProxy.save(fileName);
      this.handleClosePopUp();
    },

    handleShowPopUp() {
      this.showPdf = true;
    },

    handleClosePopUp() {
      this.pdfProxy = false;
      this.showPdf = false;
      this.pdfUrl = "";
    },
    generateHeader(
      doc,
      pageWidth,
      pageHeight,
      padding,
      debitNote,
      taxInvoiceReference,
      company,
      supplier
    ) {
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, 0, padding),
        this.setPos(pageWidth, pageWidth, padding),
        1.8
      );

      let posY = this.setPos(pageHeight, 0.5, padding);
      const title = "NOTA RETUR";
      const titleLength = doc.getTextDimensions(title).w;
      const innerPadding = 0.3;
      doc.setFontSize(15);
      doc.setFont("helvetica", "bold");
      doc.text(
        title,
        this.setPos(pageWidth, pageWidth / 2 + titleLength / 4, padding),
        this.setPos(pageHeight, posY, 0),
        "center"
      );

      const spacing = 0.7;

      posY += 0.5;

      doc.setFontSize(10);

      doc.setFont("helvetica", "normal");
      const number = `Nomor`;
      doc.text(
        number,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const numberValue = `: ${debitNote.Code}`;

      doc.text(
        numberValue,
        this.setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      const taxNumber = "Atas Faktur Pajak Nomor";
      doc.text(taxNumber, this.setPos(pageWidth, innerPadding, padding), posY);

      const taxNumberValue = `: ${
        debitNote &&
        Object.keys(debitNote).length > 0 &&
        debitNote.TaxInvoiceReferenceRefText
          ? debitNote.TaxInvoiceReferenceRefText
          : taxInvoiceReference.TaxReturnNumber
      }`;

      doc.text(
        taxNumberValue,
        this.setPos(pageWidth, innerPadding + 4.3, padding),
        posY
      );

      doc.text(taxNumber, this.setPos(pageWidth, innerPadding, padding), posY);

      const date = `tgl`;
      doc.text(
        date,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const dateValue = `: ${this.formatDate(
        debitNote.TaxInvoiceReferenceRefDate
          ? debitNote.TaxInvoiceReferenceRefDate
          : debitNote.TaxReturnDate,
        true
      )}`;

      doc.text(
        dateValue,
        this.setPos(pageWidth, pageWidth - pageWidth / 3 + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      const startLine = posY + 0.3;
      posY += 0.9;

      doc.text(
        "PEMBELI",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "Nama",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${company.Name}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      const addrHeader = "Alamat";
      const addrWidth = doc.getTextDimensions(addrHeader).w;
      const addrHeight = doc.getTextDimensions(addrHeader).h;
      const companyAddr = company.Address;
      const companyAddrLines = doc.splitTextToSize(
        companyAddr,
        pageWidth - addrWidth - 1
      );

      doc.text(
        addrHeader,
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        ":",
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        companyAddrLines,
        this.setPos(pageWidth, innerPadding + 2.25, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += addrHeight * companyAddrLines.length + 0.35;

      doc.text(
        "NPWP",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${company.Npwp}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "KEPADA PENJUAL",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      doc.text(
        "Nama",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${supplier.Name}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      const supplierAddr = `${supplier.Address}, ${supplier.SubDistrict}, ${supplier.District}, ${supplier.City}, ${supplier.Province}`;
      const supplierAddrHeight = doc.getTextDimensions(supplierAddr).h;
      const supplierAddrLines = doc.splitTextToSize(
        supplierAddr,
        pageWidth - addrWidth - 2
      );

      doc.text(
        addrHeader,
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        ":",
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        supplierAddrLines,
        this.setPos(pageWidth, innerPadding + 2.25, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += supplierAddrLines.length * supplierAddrHeight + 0.35;

      doc.text(
        "NPWP",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      doc.text(
        `: ${supplier.Npwp}`,
        this.setPos(pageWidth, innerPadding + 2, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += spacing;

      //Draw right line
      doc.line(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startLine, 0),
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0)
      );

      //Draw left line
      doc.line(
        pageWidth + padding,
        this.setPos(pageHeight, startLine, 0),
        pageWidth + padding,
        this.setPos(pageHeight, posY, 0)
      );

      return posY;
    },
    generateReturnLine(doc, posY, pageHeight, lines, debitNote, padding) {
      const header = [
        { header: "No. Urut", dataKey: "no" },
        { header: "Macam dan Jenis Barang Kena Pajak", dataKey: "item_name" },
        { header: "Kuantum", dataKey: "quantity" },
        { header: "Harga Satuan menurut Faktur Pajak", dataKey: "price" },
        { header: "Harga BKP yang dikembalikan (Rp)", dataKey: "value" },
      ];

      // const temp = [
      //   {
      //     no: 1,
      //     ItemName: "kue madu",
      //     DNQuantity: 2,
      //     DNValue: 20000,
      //     UnitName: "PCS",
      //     ItemUnitID: 1,
      //   },
      //   {
      //     no: 2,
      //     ItemName: "kue madu",
      //     DNQuantity: 3,
      //     DNValue: 30000,
      //     UnitName: "PCS",
      //     ItemUnitID: 1,
      //   },
      //   {
      //     no: 3,
      //     ItemName: "kue madu",
      //     DNQuantity: 4,
      //     DNValue: 40000,
      //     UnitName: "PCS",
      //     ItemUnitID: 1,
      //   },
      //   {
      //     no: 12,
      //     ItemName: "kue jawa",
      //     DNQuantity: 13,
      //     DNValue: 130000,
      //     UnitName: "PCS",
      //     ItemUnitID: 2,
      //   },
      // ];

      // const body = [...Array(21)].map((_, index) => {
      //   return {
      //     no: index + 1,
      //     item_name: "Dummy Item",
      //     quantity: "2 pcs",
      //     price: 20000,
      //     value: 20000,
      //   };
      // });

      const body = this.skuGrouping(lines);

      // let itemCount = 0;

      autoTable(doc, {
        startY: posY,
        columns: header,
        body: body,
        theme: "plain",
        styles: {
          lineWidth: 0,
          fontSize: 10,
          font: "helvetica",
          fontStyle: "normal",
          valign: "middle",
          lineColor: [0, 0, 0], //black
        },

        showHead: "everyPage",
        margin: {
          left: padding,
          right: padding,
          bottom: padding,
        },
        columnStyles: {
          no: { cellWidth: 1.5, cellPadding: 0 },
          item_name: { cellWidth: 9, cellPadding: 0 },
          quantity: { cellWidth: 3.5, cellPadding: 0 },
          price: { cellPadding: 0 },
          value: { cellWidth: 6, cellPadding: 0 },
        },
        didParseCell: (data) => {
          const columnIndex = data.column.index;
          const rowIndex = data.row.index;

          data.cell.styles.cellPadding = 0.14;

          if (data.section === "head") {
            if (columnIndex !== 1) {
              data.cell.styles.halign = "center";
              data.cell.styles.valign = "middle";
            } else {
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "middle";
            }
          } else {
            if (columnIndex === 1) {
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "middle";
            } else if (columnIndex === 2) {
              data.cell.styles.halign = "center";
              data.cell.styles.valign = "middle";
            } else {
              data.cell.styles.halign = "right";
              data.cell.styles.valign = "middle";
            }

            if (rowIndex < body.length - 1) {
              return;
            }

            // if (columnIndex === 0) {
            //   data.cell.styles.halign = "left";
            //   data.cell.colSpan = 4;
            // }
          }
        },
        didDrawCell(data) {
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (data.row.section === "head") {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);
            //draw top line for all cells
            doc.line(cellX, cellY, cellX + data.cell.width, cellY);

            //draw bottom line for all cells
            doc.line(
              cellX,
              cellY + data.cell.height,
              cellX + data.cell.width,
              cellY + data.cell.height
            );

            //draw left line
            if (data.column.index === 0) {
              doc.line(cellX, cellY, cellX, cellY + data.cell.height);
            }

            //draw right line
            doc.line(
              cellX + data.cell.width,
              cellY,
              cellX + data.cell.width,
              cellY + data.cell.height
            );
          } else {
            //draw left line
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            if (data.column.index === 0) {
              doc.line(cellX, cellY, cellX, cellY + data.cell.height);
            }

            //draw right line
            doc.line(
              cellX + data.cell.width,
              cellY,
              cellX + data.cell.width,
              cellY + data.cell.height
            );

            const posY = cellY + data.cell.height;
            const rowIndex = data.row.index;

            console.log("row index", rowIndex, rowIndex % 35);
            // if (columnIndex === 0) {
            //   itemCount++;
            // }

            // if (itemCount > 0 && itemCount % 36 === 0) {
            //   doc.addPage();
            //   itemCount = 0;
            // }

            if (posY >= pageHeight + 0.5) {
              doc.setLineWidth(0.07);
              doc.setDrawColor(0, 0, 0);

              doc.line(
                cellX,
                cellY + data.cell.height,
                cellX + data.cell.width,
                cellY + data.cell.height
              );
            }

            if (rowIndex === body.length - 1) {
              //draw top line for all cells
              // doc.line(cellX, cellY, cellX + data.cell.width, cellY);

              //draw bottom line for all cells
              doc.line(
                cellX,
                cellY + data.cell.height,
                cellX + data.cell.width,
                cellY + data.cell.height
              );
            }
          }
        },
      });

      return doc.lastAutoTable.finalY;
    },

    skuGrouping(lines) {
      const filteredItems = {};
      const body = [];
      lines.forEach((line, index) => {
        const price = line.DNValue / line.DNQuantity;

        if (index === 0) {
          const obj = {};
          obj[price] = {
            quantity: line.DNQuantity,
            value: line.DNValue,
            price: price,
            unit: line.UnitName,
            name: line.ItemName,
          };
          filteredItems[line.ItemUnitID] = obj;
          return; //next iteration
        }

        if (line.ItemUnitID in filteredItems) {
          let found = false;

          for (const priceKey in filteredItems[line.ItemUnitID]) {
            if (priceKey === price) {
              filteredItems[line.ItemUnitID][priceKey].quantity +=
                line.DNQuantity;
              filteredItems[line.ItemUnitID][priceKey].value += line.DNValue;
              found = true;
              break;
            }
          }

          if (!found) {
            filteredItems[line.ItemUnitID][price] = {
              quantity: line.DNQuantity,
              value: line.DNValue,
              price: price,
              unit: line.UnitName,
              name: line.ItemName,
            };
          }
        } else {
          const obj = {};
          obj[price] = {
            quantity: line.DNQuantity,
            value: line.DNValue,
            price: price,
            unit: line.UnitName,
            name: line.ItemName,
          };
          filteredItems[line.ItemUnitID] = obj;
          // const obj = {
          //   price: [],
          // };
          // filteredItems[line.ItemUnitID] = obj;
          // filteredItems[line.ItemUnitID][price].push({
          //   quantity: line.DNQuantity,
          //   value: line.DNValue,
          //   price: price,
          //   unit: line.UnitName,
          //   name: line.ItemName,
          // });
        }
      });

      // Object.keys(filteredItems).forEach((key, index) => {
      //   filteredItems[key].forEach((item) => {
      //     item.forEach((data) => {
      //       body.push({
      //         no: index + 1,
      //         item_name: data.name,
      //         quantity: `${data.quantity} ${data.unit}`,
      //         price: this.priceFormat(data.price),
      //         value: this.priceFormat(data.value),
      //       });
      //     });
      //   });
      // });
      let index = 0;
      for (const itemUnitID in filteredItems) {
        for (const price in filteredItems[itemUnitID]) {
          index++;
          body.push({
            no: index,
            item_name: filteredItems[itemUnitID][price].name,
            quantity: `${filteredItems[itemUnitID][price].quantity} ${filteredItems[itemUnitID][price].unit}`,
            price: this.priceFormat(filteredItems[itemUnitID][price].price),
            value: this.priceFormat(filteredItems[itemUnitID][price].value),
          });
        }
      }

      return body;
    },

    generateFooter(doc, posY, debitNote, padding) {
      const header = [
        { header: "", dataKey: "placeholder" },
        { header: "", dataKey: "divider" },
        { header: "", dataKey: "value" },
      ];

      const body = [
        {
          placeholder: "Jumlah Harga BKP yang dikembalikan\n",
          divider: ":",
          value: this.priceFormat(debitNote.DNValue),
        },
        {
          placeholder: "Dikurangi Potongan Harga",
          divider: ":",
          value: "0",
        },
        {
          placeholder: "Dasar Pengenaan Pajak",
          divider: ":",
          value: this.priceFormat(debitNote.DNValue),
        },
        {
          placeholder: "Pajak Pertambahan Nilai yang diminta kembali",
          divider: ":",
          value: this.priceFormat(debitNote.TaxValue),
        },
        {
          placeholder: "Pajak Penjualan Atas Barang Mewah yang diminta kembali",
          divider: ":",
          value: "",
        },
      ];

      autoTable(doc, {
        startY: posY,
        columns: header,
        body: body,
        theme: "plain",
        styles: {
          lineWidth: 0,
          fontSize: 10,
          font: "helvetica",
          fontStyle: "normal",
          valign: "middle",
          lineColor: [0, 0, 0], //black
        },
        showHead: "never",
        margin: {
          left: padding,
          right: padding,
          bottom: padding,
        },
        columnStyles: {
          placeholder: { cellWidth: 9.94, cellPadding: 0.14 },
          divider: { cellWidth: 10.312, cellPadding: 0.14 },
          value: { cellPadding: 0.14 },
        },
        didParseCell(data) {
          if (data.section === "head") {
            return;
          }

          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;

          if (columnIndex === header.length - 1) {
            data.cell.styles.halign = "right";
          }

          if (rowIndex === 0) {
            data.cell.styles.valign = "top";
            //draw bottom line for all cells
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);
          }

          if (
            rowIndex === body.length - 1 ||
            posY >= doc.internal.pageSize.height - 3
          ) {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            doc.line(
              cellX,
              cellY + data.cell.height,
              cellX + data.cell.width,
              cellY + data.cell.height
            );
          }
        },
        didDrawCell(data) {
          if (data.section === "head") {
            return;
          }

          const rowIndex = data.row.index;
          const columnIndex = data.column.index;

          const cellX = data.cell.x;
          const cellY = data.cell.y;
          const cellWidth = data.cell.width;
          const cellHeight = data.cell.height;

          doc.setLineWidth(0.07);
          doc.setDrawColor(0, 0, 0);

          if (rowIndex === 0) {
            doc.setLineWidth(0.07);
            doc.setDrawColor(0, 0, 0);

            //draw top line for all cells
            doc.line(cellX, cellY, cellX + cellWidth, cellY);
            //draw bottpm line for all cells
            doc.line(
              cellX,
              cellY + cellHeight,
              cellX + cellWidth,
              cellY + cellHeight
            );
          }

          if (columnIndex === 0) {
            //draw left line
            doc.line(cellX, cellY, cellX, cellY + cellHeight);
          } else {
            doc.line(
              cellX + cellWidth,
              cellY,
              cellX + cellWidth,
              cellY + cellHeight
            );
          }

          // if (rowIndex === body.length - 1 || rowIndex === 0) {
          //   doc.setLineWidth(0.07);
          //   doc.setDrawColor(0, 0, 0);

          //   //draw bottom line for all cells
          //   doc.line(
          //     cellX,
          //     cellY + cellHeight,
          //     cellX + cellWidth,
          //     cellY + cellHeight
          //   );
          // }
        },
      });

      return doc.lastAutoTable.finalY;
    },

    generateSign(doc, posY, pageWidth, pageHeight, padding, debitNote) {
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, pageWidth, padding),
        4
      );

      const endY = posY + 4;

      const date = `Jakarta, ${
        debitNote.TaxReturnDate
          ? this.formatDate(debitNote.TaxReturnDate, true)
          : "-"
      }`;

      // TaxInvoiceReferenceRefDate
      const dateWidth = doc.getTextDimensions(date).w;

      const buyer = "Pembeli";
      const buyerWidth = doc.getTextDimensions(buyer).w;

      const name = "(     Harsinto     )";
      const nameWidth = doc.getTextDimensions(name).w;

      const director = "Direktur";
      const directorWidth = doc.getTextDimensions(director).w;

      posY += 0.5;
      const startPos = pageWidth - pageWidth / 3;
      const buyerPos = startPos + (dateWidth - buyerWidth) / 2;
      const namePos = startPos + (dateWidth - nameWidth) / 2;
      const directorPos = startPos + (dateWidth - directorWidth) / 2;

      doc.text(
        date,
        this.setPos(pageWidth, pageWidth - pageWidth / 3, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        buyer,
        this.setPos(pageWidth, buyerPos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 2;

      doc.text(
        name,
        this.setPos(pageWidth, namePos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.3;

      doc.setLineWidth(0.03);
      doc.line(
        this.setPos(pageWidth, startPos - 0.5, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, startPos + dateWidth + 0.5, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        director,
        this.setPos(pageWidth, directorPos, padding),
        this.setPos(pageHeight, posY, 0)
      );

      return endY;
    },

    generateOtherInfo(doc, posY, pageWidth, pageHeight, padding) {
      const innerPadding = 0.3;
      doc.setLineWidth(0.07);
      doc.rect(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, posY, 0),
        this.setPos(pageWidth, pageWidth, padding),
        1.5
      );

      posY += 0.5;

      doc.text(
        "Lembar ke-1 untuk Pengusaha Kena Pajak yang menerbitkan Faktur Pajak",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      posY += 0.5;

      doc.text(
        "Lembar ke-2 untuk Pembeli",
        this.setPos(pageWidth, innerPadding, padding),
        this.setPos(pageHeight, posY, 0)
      );

      return posY;
    },
    getPrice(i) {
      const dnValue = this.selectedDebitNote.lines[i].DNValue;
      const qty = this.selectedDebitNote.lines[i].DNQuantity;

      return dnValue / qty;
    },
    async getDebitNote(id) {
      this.$vs.loading();
      // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
      let resp = await this.$http.get(`/api/v1/debit-note/return/detail`, {
        params: {
          id: id,
        },
      });
      if (resp.code == 200) {
        this.selectedDebitNote = resp.data;
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    async getSupplierReturnDetails() {
      this.$vs.loading();
      let pr_ids = [];
      let sr_ids = [];
      this.selectedDebitNote.lines.forEach((line) => {
        if (line.SupplierReturnID) {
          sr_ids.push(line.SupplierReturnID);
        } else if (line.PurchaseReturnID) {
          pr_ids.push(line.PurchaseReturnID);
        }
      });

      pr_ids = [...new Set(pr_ids)];
      sr_ids = [...new Set(sr_ids)];
      const params = {
        pr_ids,
        sr_ids,
        is_edit: true,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return/detail",
          {
            params,
          }
        );
        this.supplierReturnDetails = response.data;
        console.log("supl", this.supplierReturnDetails);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
    async printPDF(tr) {
      try {
        console.log(tr);
        await this.getDebitNote(tr.dn_id);
        await this.getSupplierReturnDetails();

        const padding = 1.2;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        pdf.setFontSize(11);

        let cursorY = this.generateHeader(
          pdf,
          pageWidth,
          pageHeight,
          padding,
          this.selectedDebitNote.debitNotes,
          this.selectedDebitNote.taxInvoiceReference,
          this.selectedDebitNote.company,
          this.selectedDebitNote.supplier
        );

        cursorY = this.generateReturnLine(
          pdf,
          cursorY,
          pageHeight,
          this.selectedDebitNote.lines,
          this.selectedDebitNote.debitNotes,
          padding
        );

        const currentPage = pdf.internal.getCurrentPageInfo().pageNumber;
        console.log("cursor Y", cursorY, height - padding);
        const buffer = currentPage === 1 ? 11.5 : 11.1;
        console.log("cursorY plus", buffer, cursorY + buffer);
        if (cursorY + buffer >= height - padding) {
          pdf.addPage();
          cursorY = padding;
        }

        cursorY = this.generateFooter(
          pdf,
          cursorY,
          this.selectedDebitNote.debitNotes,
          padding
        );

        cursorY = this.generateSign(
          pdf,
          cursorY,
          pageWidth,
          pageHeight,
          padding,
          this.selectedDebitNote.debitNotes
        );

        cursorY = this.generateOtherInfo(
          pdf,
          cursorY,
          pageWidth,
          pageHeight,
          padding
        );

        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `${i}/${pageCount}`,
            this.setPos(pageWidth, pageWidth - 2, padding),
            pdf.internal.pageSize.height - 1
          );
        }
        const url = pdf.output("dataurlstring");
        this.pdfUrl = url;
        this.pdfProxy = pdf;
        this.handleShowPopUp();
        this.$vs.loading.close();
        // pdf.save(docName);
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },

    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },

    formatDate(val, long = null) {
      const momentDate = moment.utc(val);
      return momentDate.format(long ? "DD.MM.YYYY" : "DD MMMM YYYY");
    },

    formatDateTable(val) {
      const momentDate = moment.utc(val);
      return momentDate.format("YYYY-MM-DD");
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
