<template>
  <div class="vx-row" style="margin-top: 10px" ref="outside">
    <div class="vx-col md:w-1/1 w-full" style="margin-bottom: 160px;padding-top: 10px;">
      <div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formInput.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Operating Unit</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formOu.inputs" @handlerSearch="handlerSearchMS"
            @update-forminput="this.updateforminput" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTerritory.inputs" />
          <!-- <formInputs :components="this.formTerritory.inputs" @update-forminput="() => getInvoiceCode(formPostingDate.inputs.value)" /> -->
        </div>
      </div>

      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Invoice Period</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formMonth.inputs" />
          <!-- <formInputs :components="this.formMonth.inputs" @update-forminput="this.changeMonth" /> -->
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formYear.inputs" />
          <!-- <formInputs :components="this.formYear.inputs" @update-forminput="this.changeYear" /> -->
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Tax Type</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formTaxInvoiceType.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Posting Date</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <!-- <formInputs v-if="render" :components="this.formPostingDate.inputs" @update-forminput="getInvoiceCode" /> -->
          <formInputs v-if="render" :components="this.formPostingDate.inputs" />
        </div>
        <!-- <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formFromDate.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formToDate.inputs" />
        </div> -->
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Invoice Number</span>
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formFromCode.inputs" />
        </div>
        <div class="vx-col sm:w-1/5 w-full">
          <formInputs :components="this.formToCode.inputs" />
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Search</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <formInputs :components="this.formSelectInvoice.inputs" />
          <!-- <vs-input v-model="table.search" class="w-full" /> -->
        </div>
      </div>
      <div class="vx-row mb-6 ml-4 mr-4">
        <div class="vx-col sm:w-4/5 w-full text-center">
          <vs-button class="mr-4" @click="handleView()" color="primary" icon-pack="feather">View
          </vs-button>
          <vs-button v-if="tabs == 0" :disabled="!this.itemRefs.some((dt) => dt.inputs[1].components.value)"
            @click="handleSubmit()" color="danger" icon-pack="feather" type="border">Generate
          </vs-button>
          <!-- <vs-button
            v-else-if="tabs == 1"
            @click="handleExport()"
            color="danger"
            icon-pack="feather"
            type="border"
            >Export
          </vs-button> -->
        </div>
      </div>
      <vs-row class="mb-6 ml-4 mr-4" vs-w="12">
        <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
          <span>Total Invoice : {{ this.table.total }}</span>
        </vs-col>
        <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-align="center" vs-w="8">
          <span>Total SN Available : {{ this.total_sn_available }}</span>
        </vs-col>
      </vs-row>
      <vs-divider class="mb-2"></vs-divider>
      <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab @click="
          colorx = 'success';
        tabClick(gen);
        " label="SN Generate">
          <div class="con-tab-ejemplo">
            <datatable :itemRefs="this.itemRefs" :tablep="this.table" :modelItemRefInput="this.tmpitemRef"
              :hideSearch="true" @change-length="handleChangelength" @handleChangePage="handleChangePage"
              @update-itemref="updateitemref" @input-itemref="oninput" @handleSort="handleSort" />
          </div>
        </vs-tab>
        <vs-tab @click="
          colorx = 'warning';
        tabClick(2);
        " label="Data Generate">
          <div class="con-tab-ejemplo">
            <vs-row class="mb-6 ml-4 mr-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <datatable class="w-full" :itemRefs="this.dtGen" :tablep="this.table" :modelItemRefInput="this.tmpdtGen"
                  :hideSearch="true" @change-length="handleChangelength" @update-itemref="updateitemref"
                  @handleChangePage="handleChangePage" @input-itemref="oninput" @click="onclick"
                  @handleSort="handleSort" />
              </vs-col>
            </vs-row>

          </div>
        </vs-tab>
        <vs-tab @click="
          colorx = 'warning';
        tabClick(data_gen);
        " label="Waiting Cancel">
          <div class="con-tab-ejemplo">
            <vs-row class="mb-6 ml-4 mr-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <datatable class="w-full" :itemRefs="this.dtGen" :tablep="this.table" :modelItemRefInput="this.tmpdtGen"
                  :hideSearch="true" @change-length="handleChangelength" @update-itemref="updateitemref"
                  @handleChangePage="handleChangePage" @input-itemref="oninput" @click="onclick"
                  @handleSort="handleSort" />
              </vs-col>
            </vs-row>

          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'
        tabClick(data_gen);
        " label="Canceled">
          <div class="con-tab-ejemplo">
            <vs-row class="mb-6 ml-4 mr-4">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <datatable class="w-full" :itemRefs="this.dtGen" :tablep="this.table" :modelItemRefInput="this.tmpdtGen"
                  :hideSearch="true" @change-length="handleChangelength" @handleChangePage="handleChangePage"
                  @handleSort="handleSort" />
              </vs-col>
            </vs-row>
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'warning'
        tabClick(data_gen);
        " label="Generate Histories">
          <div class="con-tab-ejemplo">
            <div class="flex">
              <div :class="[edit ? 'vx-col w-full mb-base md:w-2/3' : '', 'core vx-col w-full mb-base md:w-1/1']">
                <datatable class="w-full" :itemRefs="this.dtGenHistory" :tablep="this.table"
                  :modelItemRefInput="this.tmpDtGenHistory" :hideSearch="true" @change-length="handleChangelength"
                  @handleChangePage="handleChangePage" @handleSort="handleSort" @click="onclickHistory" />
              </div>
              <transition name="detail-fade">
                <div class="vertical-divider vx-col w-full mb-base" v-if="edit"
                  :class="[edit ? 'md:w-1/3' : 'close md:w-1/1']">
                  <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="right" vs-align="right"
                    vs-w="4">
                    <vs-button class="ml-auto min-h-0" size="small" v-on:click="handleClose" color="grey"
                      icon-pack="feather" icon="icon-x-square">Close</vs-button>
                  </vs-col>
                  <vs-col class="flex flex-row align-middle min-h-0">
                    <div class="flex items-center w-9">
                      <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                        icon="icon-x-square"></vs-button>
                    </div>
                    <div class="flex-1 mx-2">
                      Detail Generate History
                      <div v-for="(line, idx) in historyLine" :key="idx" class="border-2 rounded">
                        <div class="vx-row mb-6">
                          <div class="vx-col w-1/3">
                            <vs-input class="w-full" label="DN Code" name="dncode" disabled="true"
                              v-model="line.Code" />
                          </div>
                          <div class="vx-col w-1/3">
                            <vs-input class="w-full" label="Status" name="status" disabled="true"
                              v-model="line.Status" />
                          </div>
                          <div class="vx-col w-1/3">
                            <vs-input class="w-full" label="Message" name="message" disabled="true"
                              v-model="line.Message" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </vs-col>
                </div>
                <div v-else></div>
              </transition>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import datatable from "../datatable.vue";
import formInputs from "../form_inputs.vue";
import moment from "moment";
export default {
  components: {
    datatable,
    formInputs,
  },
  props: {
    taxNumberLength: Number,
  },
  data() {
    var baseUrl = window.location.origin
    return {
      render: true,
      edit: false,
      historyLine: [],
      invoiceCodes: [],
      baseUrl: baseUrl,
      selected: {},
      responseData: {},
      users: [],
      type: 0,
      gen: 0,
      data_gen: 1,
      colorx: "success",
      id_inv: [],
      data: {
        npwp: "",
      },
      table: {
        start: 0,
        end: 0,
        page: 0,
        stripe: false,
        length: 10,
        search: "",
        order: "id",
        sst: true,
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],

      },
      tabs: 0,
      formTaxEntity: {
        id: 1,
        inputs: {
          disabled: false,
          validate: "required",
          name: "taxt_entity",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formOu: {
        id: 2,
        inputs: {
          disabled: false,
          validate: "required",
          name: "operating_unit",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          internal_search: false,
          clear_on_select: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      tempDataOu: [],
      formTerritory: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "territory",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formTaxInvoiceType: {
        id: 4,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_inv_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "ID",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formInvoiceType: {
        id: 5,
        inputs: {
          disabled: false,
          validate: "required",
          name: "invoice_type",
          placeholder: "Type to search",
          type: "multiselect",
          value: {
            id: 0,
            text: "AR Invoice"
          },
          option: [
            {
              id: 0,
              text: "AR Invoice"
            },
            {
              id: 1,
              text: "Debit Note"
            }
          ],
          multiple: false,
          allowempty: false,
          track_by: "id",
        },
      },
      formInput: {
        id: 6,
        inputs: {
          disabled: false,
          validate: "required",
          name: "tax_entity",
          placeholder: "Tax Entity",
          type: "input",
          value: "",
        },
      },
      formYear: {
        id: 7,
        inputs: this.forminputyear(),
      },
      formMonth: {
        id: 8,
        inputs: this.forminputmonth(),
      },
      formSelectInvoice: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "selectInvoice",
          placeholder: "Type to search",
          type: "multiselect",
          value: [],
          option: [],
          multiple: true,
          allowempty: true,
          track_by: "id",
        }
      },
      formFromCode: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "from_code",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "id",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formToCode: {
        id: 3,
        inputs: {
          disabled: false,
          validate: "required",
          name: "to_code",
          placeholder: "Type to search",
          type: "multiselect",
          value: {},
          option: [],
          multiple: false,
          allowempty: false,
          track_by: "id",
          onSelectEvent: (selected) => {
            console.log(selected);
          },
        },
      },
      formPostingDate: {
        id: 9,
        inputs: this.formInputPostingDate(),
      },
      formFromDate: {
        id: 9,
        inputs: this.formInputFromDate(),
      },
      formToDate: {
        id: 10,
        inputs: this.formInputToDate(),
      },
      itemRefs: [],
      tmpitemRef: {
        id: 2,
        action: false,
        inputs: this.getInputsHead(),
      },
      dtGen: [],
      tmpdtGen: {
        id: 2,
        action: false,
        inputs: this.getInputsHeadDataGen(),
      },
      dtGenHistory: [],
      tmpDtGenHistory: {
        id: 3,
        action: false,
        inputs: this.getInputsHeadDataGenHistory(),
      },
      DataOperatingUnit: [],
      DataTerritory: [],
      data_head: {},
      total_sn_available: 0,
      initCurrentPage: 0,
    };
  },
  watch: {
    "formTaxInvoiceType.inputs.value": function (val) {
      this.getInvoiceCode(this.formPostingDate.inputs.value)
    },
    "formPostingDate.inputs.value": function (val) {
      this.getInvoiceCode(val)
    },
    "formTerritory.inputs.value": function (val) {
      this.getInvoiceCode(this.formPostingDate.inputs.value)
    },
    "formMonth.inputs.value": function (val) {
      console.log("month watch", val)
      if (this.formYear.inputs.value) {
        console.log("yes")
        const min = new Date(this.formYear.inputs.value.getFullYear(), this.formMonth.inputs.value.getMonth(), 1)
        const max = new Date(this.formYear.inputs.value.getFullYear(), this.formMonth.inputs.value.getMonth() + 1, 0)
        this.formPostingDate.inputs.minDate = min
        this.formPostingDate.inputs.maxDate = max
        this.formPostingDate.inputs.value.startDate = min
        this.formPostingDate.inputs.value.endDate = max
        this.getInvoiceCode(this.formPostingDate.inputs.value)
        // this.formPostingDate.inputs.dateformat = (classes, date) => {
        //   if (!classes.disabled) {
        //     classes.disabled = date.getTime() < min && date.getTime() > max
        //   }
        //   return classes
        // }
        this.render = false
        this.$nextTick(() => this.render = true)
      }
    },
    "formYear.inputs.value": function (val) {
      console.log("month watch", val)
      if (this.formMonth.inputs.value) {
        console.log("yes")
        const min = new Date(this.formYear.inputs.value.getFullYear(), this.formMonth.inputs.value.getMonth(), 1)
        const max = new Date(this.formYear.inputs.value.getFullYear(), this.formMonth.inputs.value.getMonth() + 1, 0)
        this.formPostingDate.inputs.minDate = min
        this.formPostingDate.inputs.maxDate = max
        this.formPostingDate.inputs.value.startDate = min
        this.formPostingDate.inputs.value.endDate = max
        this.getInvoiceCode(this.formPostingDate.inputs.value)
        // this.formPostingDate.inputs.dateformat = (classes, date) => {
        //   if (!classes.disabled) {
        //     classes.disabled = date.getTime() < min && date.getTime() > max
        //   }
        //   return classes
        // }
        this.render = false
        this.$nextTick(() => this.render = true)
      }
    },
  },
  mounted() {
    this.getData();
    this.getTaxType();
    console.log("Router: ", this.$router);
  },
  computed: {
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
    formatDate: () => {
      return (val) => moment(val).utc().format('YYYY-MM-DD')
    }
  },
  methods: {
    // changeMonth(val) {
    //   console.log("month", val)
    // },
    // changeYear(val) {
    //   console.log("year", val)
    // },
    handleClose() {
      this.historyLine = []
      this.edit = false
    },
    updateDokumenPendukung(data) {
      console.log(data)
      if (data.tax_type == "07" && data.kode_dokumen_pendukung.length < 11) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Kode Dokumen Pendukung must be 11 characters",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.$refs.outside.click()
        return
      }
      this.$vs.loading
      this.$http
        .put(`api/v1/master/tax-invoice/generate`, data)
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Update description success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$refs.outside.click()
            this.handleView()
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getInvoiceCode(selected) {
      var startDate = moment(selected.startDate).format("yyyy-MM-DD")
      var endDate = moment(selected.endDate).format("yyyy-MM-DD")
      var territory = this.DataTerritory[this.formTerritory.inputs.value.ID]
      if (!territory) {
        return
      }
      let status
      switch (this.tabs) {
        case 0:
        case 3:
          status = 0
          break;
        case 1:
          status = 1
          break
        case 2:
          status = 2
          break
        default:
          break;
      }
      const params = {
        territory_id: territory.TerrID,
        start_date: startDate,
        end_date: endDate,
        tax_type: this.formTaxInvoiceType.inputs.value.ID,
        tax_type_code: this.formTaxInvoiceType.inputs.value.code,
        is_generate: [1, 2, 3].includes(this.tabs),
        status

      }
      console.log("params", params, this.tabs)
      this.$vs.loading();
      this.$http
        .get(`api/v1/master/tax-invoice/invoice-code`, { params })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.invoiceCodes = resp.data
            const first = resp.data[0]
            const last = resp.data[resp.data.length - 1]
            console.log("first", first)
            console.log("last", last)
            if (first) {
              this.formFromCode.inputs.value = { id: first.id, text: first.code }
            } else {
              this.formFromCode.inputs.value = null
            }
            this.formFromCode.inputs.option = resp.data.map(d => ({ id: d.id, text: d.code }))
            if (first) {
              this.formToCode.inputs.value = { id: last.id, text: last.code }
            } else {
              this.formToCode.inputs.value = null
            }
            this.formToCode.inputs.option = resp.data.map(d => ({ id: d.id, text: d.code }))

            this.formSelectInvoice.inputs.option = resp.data.map(d => ({ id: d.id, text: d.code }))
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    leadingZero(value, fixLength) {
      console.log("leadingZero", value)
      return "0".repeat(fixLength - value.length) + value
    },
    forminputyear() {

      return {
        disabled: false,
        validate: "required",
        name: "year",
        placeholder: "Select Year",
        type: "date",
        min_view: "year",
        format: 'yyyy',
        value: new Date(),
        // option: date_year,
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleSearch(search) {
      this.table.search = search
      this.handleView()
    },
    handlerSearchMS(comp, search) {
      if (comp.name == "operating_unit") {
        console.log("search ou:", search)
        this.formOu.inputs.option = this.tempDataOu.filter(v => {
          var tempText = v.text.toLowerCase()
          var tempSearch = search.toLowerCase()
          return tempText.search(tempSearch) >= 0
        })
      }
      console.log("formOu", this.formOu)
    },
    forminputmonth() {

      return {
        disabled: false,
        validate: "required",
        name: "month",
        placeholder: "Select Month",
        type: "date",
        value: new Date(),
        min_view: "month",
        format: 'MM',
        // type: "multiselect",
        // value: value,
        // option: dtselect,
        multiple: false,
        allowempty: false,
        track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    formInputPostingDate() {
      const today = new Date();
      return {
        disabled: false,
        validate: "required",
        name: "date",
        placeholder: "Invoice Date",
        type: "daterange",
        value: {
          startDate: new Date(today.getFullYear(), today.getMonth(), 1),
          endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
        },
        // min_view:"date",
        format: 'yyyy-MM-dd',
        // type: "multiselect",
        // value: value,
        // option: dtselect,
        multiple: false,
        allowempty: false,
        // track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    formInputFromDate() {
      return {
        disabled: false,
        validate: "required",
        name: "date",
        placeholder: "From Date",
        type: "date",
        value: "",
        min_view: "date",
        format: 'yyyy-MM-dd',
        // type: "multiselect",
        // value: value,
        // option: dtselect,
        multiple: false,
        allowempty: false,
        // track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    formInputToDate() {
      return {
        disabled: false,
        validate: "required",
        name: "date",
        placeholder: "To Date",
        type: "date",
        value: "",
        min_view: "date",
        format: 'yyyy-MM-dd',
        // type: "multiselect",
        // value: value,
        // option: dtselect,
        multiple: false,
        allowempty: false,
        // track_by: "ID",
        onSelectEvent: (selected) => {
          console.log(selected);
        },
      };
    },
    handleChangelength(item) {
      console.log(item)
      if (item == "All") {
        this.table.end = this.table.total
      } else {
        this.table.end = item
      }
      this.table.length = this.table.end
      this.handleView()
    },
    resetTable() {
      this.table.total = 0
      this.table.totalPage = 0
      this.table.totalSearch = 0
      this.table.length = 10
      this.table.start = 0
      this.table.end = 0
      this.table.stripe = false
    },
    tabClick(i) {
      this.resetTable()
      this.formSelectInvoice.inputs.value = []
      const tabs = this.tabs
      switch (tabs) {
        case 0:
          this.itemRefs = []
          // this.getDataSN()
          break;
        case 1:
        case 2:
        case 3:
          this.dtGen = []
          this.table.stripe = true
          break
        case 4:
          this.dtGenHistory = []
          this.table.stripe = true
          this.getDataGenHistories()

          break
        default:
          break;
      }
    },
    oninputSearch() {
      // this.getDatGen()
      const tabs = this.tabs
      switch (tabs) {
        case 0:
          this.getDataSN()

          break;
        case 1:
          this.getDatGen(1)
          break
        case 2:
          this.getDatGen(2)
          break
        case 3:
          this.getDatGen(0)
          break
        default:
          break;
      }
    },
    oninput(data, type) {
      console.log(data);
      if (type == "itemref") {
        var numb = data.value
        console.log("numb", numb)
        if (data.name == "start_number") {
          // this.itemRefs[data.id].inputs[4].components.value = numb
          // total_sn =
          //   this.itemRefs[data.id].inputs[5].components.value - numb;
          // if (total_sn < 0) {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          // } else {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          // }
        } else if (data.name == "end_number") {
          // this.itemRefs[data.id].inputs[5].components.value = numb
          // total_sn =
          //   numb - this.itemRefs[data.id].inputs[4].components.value;
          // if (total_sn < 0) {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + 0;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + 0;
          // } else {
          //   this.itemRefs[data.id].inputs[6].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[7].components.value = "" + total_sn;
          //   this.itemRefs[data.id].inputs[8].components.value = "" + total_sn;
          // }
        } else if (data.name == "checkbox") {
          var id = data.id_invoice
          if (!id) {
            id = 0
          }
          if (data.head_val != undefined) {
            this.data_head = data;
            this.id_inv = []
            if (data.head_val == true) {
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                    this.itemRefs[i].inputs[1].components.value = true;
                    this.id_inv.push(this.itemRefs[i].id_invoice)
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                    this.dtGen[i].inputs[1].components.value = true;
                    this.id_inv.push(this.dtGen[i].id_invoice)
                  }
                });
              }
            } else if (data.head_val == false) {
              if (this.tabs == 0) {
                this.itemRefs.forEach((dt, i) => {
                  if (!this.itemRefs[i].inputs[1].components.disabled) {
                    this.itemRefs[i].inputs[1].components.value = false;
                  }
                });
              } else if (this.tabs == 1) {
                this.dtGen.forEach((dt, i) => {
                  if (!this.dtGen[i].inputs[1].components.disabled) {
                    this.dtGen[i].inputs[1].components.value = false;
                  }
                });
              }
            }
          } else {
            if (data.value) {
              this.id_inv.push(id)
            } else {
              this.id_inv = this.id_inv.filter((v) => {
                return v != id;
              });
            }
            if (data.value == false) {
              if (this.data_head) {
                this.data_head.head_val = false;
              }
            }
          }
        }
      }
    },
    updateitemref(data, status) {
      if (status == "add") {
        let i_new = this.itemRefs.length;
        this.itemRefs.push(data);
        for (let i = 0; i < this.itemRefs[i_new].inputs.length; i++) {
          this.itemRefs[i_new].inputs[i].components.id = i_new;
          if (i != 0 && i != 1 && i != 2) {
            this.itemRefs[i_new].inputs[i].components.value = "";
          } else {
            this.itemRefs[i_new].inputs[i].components.value =
              this.itemRefs[i_new - 1].inputs[i].components.value;
          }
        }
      } else if (status == "remove") {
        this.itemRefs = this.itemRefs.filter((v, i) => {
          return i != data;
        });
      }
    },
    onclick(e) {
      console.log("e", e)
      const dt = e.data
      console.log("dt", dt)
      this.confirmCancel(dt.TaxNumberID)
    },
    onclickHistory(e) {
      this.edit = true
      const dt = e.data
      this.getDetailHistory(dt.ID)
    },
    async getDetailHistory(historyId) {
      try {
        this.$vs.loading();
        const response = await this.$http.get(`api/v1/master/tax-invoice/view-generate-history/${historyId}`)

        this.historyLine = response.data.records.Data.invoices.map((item) => ({
          ID: item.invoice_id,
          Code: item.invoice_code,
          Status: item.status,
          Message: item.status.toLowerCase() == "success" ? item.tax_number : item.message
        }))
      } catch (error) {
        console.log(error)
      } finally {
        this.$vs.loading.close();
      }
    },
    confirmCancel(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: "Delete Confirmation",
        text: "Are you sure to delete this Serial Number?",
        accept: () => this.cancelTaxNumber(id),
        "accept-text": "Yes",
      })
    },
    cancelTaxNumber(id) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/master/tax-invoice/cancel`, { id, invoice_type: 0 })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.getDatGen(1)
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getInputs(data = null, i = 0, no = 1) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var status_config = "";
      var tax_invoice_type = "";
      // var type_inv ="";
      var temp_kode_dokumen_pendukung = ""
      var custRoute = ""
      var invRoute = ""
      var checkbox = false
      var id_inv = 0
      if (data) {
        periode = data.PostingDate;
        if (periode) {
          periode = moment(periode).format("YYYY-MM-DD");
        }
        invoice_no = data.Code;
        customer_code = data.CustomerCode;
        customer_name = data.CustomerName;
        status_config = data.StatusReady;
        // type_inv = this.formInvoiceType.inputs.value.text //ar invoice
        temp_kode_dokumen_pendukung = data.KodeDokumenPendukung
        custRoute = this.$router.resolve({
          name: 'customers-edit',
          params: { id: data.CustomerID },
        })
        id_inv = data.ID
        // custRoute = this.baseUrl+custRoute.href
        custRoute = custRoute.href
        var temp_id_inv = this.id_inv.filter(v => {
          return v == data.ID
        })
        if (temp_id_inv.length > 0) {
          checkbox = true
        }
        // tax_invoice_type = `${data.TaxTypeCode} ${data.TaxTypeName}`;
        tax_invoice_type = data.TaxType;
      }
      // if (this.formTaxInvoiceType) {
      //   tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      // }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 1,
          disabled: status_config.toLowerCase() != "ready".toLowerCase(),
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "title", //checkbox/title
          type: "checkbox",
          id_invoice: id_inv,
          value: checkbox,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Invoice No",
          title: "Invoice No",
          target: "_blank",
          href: invRoute,
          type: "text-link",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Invoice Type",
          title: "Tax Invoice Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      // inputs.push({
      //   components: {
      //     id: i,
      //     id_input: 4,
      //     disabled: true,
      //     validate: "required",
      //     name: "invoice_type",
      //     placeholder: "Invoice Type",
      //     title: "Invoice Type",
      //     type: "text",
      //     value: type_inv,
      //   },
      // });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "client_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          target: "_blank",
          type: "text-link",
          href: custRoute,
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "client_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "text",
          value: customer_name,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "kode_dokumen_pendukung",
          placeholder: "Kode Dokumen Pendukung",
          title: "Kode Dokumen Pendukung",
          type: "text",
          value: temp_kode_dokumen_pendukung,
          temp_value: temp_kode_dokumen_pendukung,
          isNumeric: true,
          editable: true,
          editCallback: (ref) => {
            console.log("ref", ref)
            this.updateDokumenPendukung({ invoice_id: ref.id_invoice, invoice_type: 0, tax_type: ref.inputs[4].components.value, kode_dokumen_pendukung: ref.inputs[7].components.temp_value })
          }
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          target: "_blank",
          type: status_config == "Ready" ? "text" : "text-link",
          href: custRoute,
          value: status_config,
        },
      });

      return inputs;
    },
    getInputsDataGen(data = null, i = 0, no = 0) {
      var inputs = [];
      var periode = "";
      var invoice_no = "";
      var customer_code = "";
      var customer_name = "";
      var tax_invoice_type = "";
      var tax_number = "";
      // var type_inv ="";
      var checkbox = false
      var id_inv = 0
      var generateDate = ""
      if (data) {
        periode = this.formatDate(data.Date);
        // invoice_no = data.invoice_reference_code;
        invoice_no = data.Code;
        customer_code = data.CustomerCode;
        customer_name = data.CustomerName;
        // tax_number = this.leadingZero(data.tax_number, 12);
        tax_number = this.leadingZero(data.TaxNumber, this.taxNumberLength);
        // type_inv = "AR Invoice"
        // tax_invoice_type = `${data.TaxTypeCode} ${data.TaxTypeName}`;
        tax_invoice_type = data.TaxType;
        id_inv = data.ID
        var temp_id_inv = this.id_inv.filter(v => {
          return v == data.ID
        })
        if (temp_id_inv.length > 0) {
          checkbox = true
        }
        generateDate = data.TaxNumberGenerateDate
      }
      // if (this.formTaxInvoiceType) {
      //   tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      // }

      // inputs.push({
      //   components: {
      //     id: i,
      //     id_input: 0,
      //     disabled: true,
      //     validate: "",
      //     name: "no",
      //     width: 40,
      //     text_align: "text-center",
      //     placeholder: "0",
      //     title: "No",
      //     type: "text",
      //     value: "" + no,
      //   },
      // });
      if (this.tabs == 1) {
        console.log("tab", 1)
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            disabled: false,
            validate: "",
            name: "action",
            placeholder: "Action",
            title: "Action",
            type: "button",
            icon: "icon-x",
            color: "danger",
            text: "Cancel",
            value: "",
            data: data,
          },
        });
      } else if (this.tabs == 2) {
        console.log("tab", 2)
        inputs.push({
          components: {
            id: i,
            id_input: 0,
            disabled: true,
            validate: "required",
            name: "no",
            width: 40,
            text_align: "text-center",
            placeholder: "0",
            title: "No",
            type: "text",
            value: "" + no,
          },
        });
      }
      // inputs.push({
      //   components: {
      //     id: i,
      //     id_input: 1,
      //     disabled: false,
      //     validate: "required",
      //     name: "checkbox",
      //     placeholder: "Checkbox",
      //     title: "checkbox",
      //     head: "title", //checkbox/title
      //     type: "checkbox",
      //     id_invoice:id_inv,
      //     value: checkbox,
      //   },
      // });
      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax umber",
          title: "Tax Number",
          type: "text",
          value: tax_number,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "invoice_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "text",
          value: periode,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "invoice_no",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "text",
          value: invoice_no,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "tax_invoice_type",
          placeholder: "Tax Type",
          title: "Tax Type",
          type: "text",
          value: tax_invoice_type,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "customer_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "text",
          value: customer_code,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "customer_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "text",
          value: customer_name,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "generate_date",
          placeholder: "Generate Data",
          title: "Generate Data",
          type: "text",
          value: generateDate,
        },
      });

      return inputs;
    },
    getInputsDataGenHistory(data = null, i = 0, no = 0) {
      console.log(data)
      var inputs = [];
      let createdAt = ""
      let createdBy = ""
      let zDoc = 0
      let zSuccess = 0
      let zFailed = 0
      let status = ""
      let description = ""

      if (data) {
        createdAt = moment(data.CreatedAt).utc().format('YYYY-MM-DD HH:mm:ss')
        createdBy = data.CreatedBy
        zDoc = data.Data.invoices.length
        zSuccess = data.Data.invoices.filter(invoice => invoice.status.toLowerCase() == "success").length
        zFailed = data.Data.invoices.filter(invoice => invoice.status.toLowerCase() == "failed").length
        status = data.Status
        description = data.Message
      }
      if (this.tabs == 1) {
        console.log("tab", 1)
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            disabled: false,
            validate: "",
            name: "action",
            placeholder: "Action",
            title: "Action",
            type: "button",
            icon: "icon-x",
            color: "danger",
            text: "Cancel",
            value: "",
            data: data,
          },
        });
      } else if (this.tabs == 2) {
        console.log("tab", 2)
        inputs.push({
          components: {
            id: i,
            id_input: 0,
            disabled: true,
            validate: "required",
            name: "no",
            width: 40,
            text_align: "text-center",
            placeholder: "0",
            title: "No",
            type: "text",
            value: "" + no,
          },
        });
      }

      inputs.push({
        components: {
          id: i,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "no",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "text",
          value: "" + no,
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: false,
          validate: "",
          name: "action",
          placeholder: "Action",
          title: "Action",
          type: "button",
          icon: "icon-eye",
          color: "warning",
          class: "p-3 pr-2",
          // text: "Delete",
          value: "",
          data: data,
        },
      });

      inputs.push({
        components: {
          id: i,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_at",
          placeholder: "Created At",
          title: "Created At",
          type: "text",
          value: createdAt,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "created_by",
          placeholder: "Created By",
          title: "Created By",
          type: "text",
          value: createdBy,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "z_document",
          placeholder: "Z Document",
          title: "Z Document",
          type: "text",
          value: zDoc,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "z_success",
          placeholder: "Z Success",
          title: "Z Success",
          type: "text",
          value: zSuccess,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "z_failed",
          placeholder: "Z Failed",
          title: "Z Failed",
          type: "text",
          value: zFailed,
        },
      });
      inputs.push({
        components: {
          id: i,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          type: "text",
          value: status,
        },
      });

      return inputs;
    },
    getTaxType() {
      this.$http
        .get(`api/v1/master/tax-type`)
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            resp.data.records.sort((a, b) => a.code - b.code)
            this.formTaxInvoiceType.inputs.option = [{ ID: 0, text: "ALL", code: "ALL" }, ...resp.data.records.map((t) => ({ ID: t.ID, text: `${t.code} ${t.name}`, code: t.code }))];
            this.formTaxInvoiceType.inputs.value = { ID: 0, text: "ALL", code: "ALL" }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getInputsHead() {
      var inputs = [];

      inputs.push({
        components: {
          id: 0,
          id_input: 0,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "checkbox",
          placeholder: "Checkbox",
          title: "checkbox",
          head: "checkbox", //checkbox/title
          head_val: false, //for head=checkbox
          type: "checkbox",
          value: false,
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Type",
          title: "Tax Type",
          type: "input",
          value: "",
        },
      });
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 4,
      //     disabled: true,
      //     validate: "required",
      //     name: "invoice_type",
      //     placeholder: "Invoice Type",
      //     title: "Invoice Type",
      //     type: "input",
      //     value: "",
      //   },
      // });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "client_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "client_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: true,
          validate: "required",
          name: "kode_dokumen_pendukung",
          placeholder: "Kode Dokumen Pendukung",
          title: "Kode Dokumen Pendukung",
          type: "text",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });

      return inputs;
    },
    getInputsHeadDataGenHistory() {
      const inputs = []

      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "No",
          type: "input",
          value: "1",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 1,
          disabled: true,
          validate: "required",
          name: "",
          width: 40,
          text_align: "text-center",
          placeholder: "0",
          title: "Action",
          type: "input",
          value: "1",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_at",
          placeholder: "Created At",
          title: "Created At",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "created_by",
          placeholder: "Created By",
          title: "Created By",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_document",
          placeholder: "Document",
          title: "Document",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_success",
          placeholder: "Success",
          title: "Success",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "z_failed",
          placeholder: "Failed",
          title: "Failed",
          type: "input",
          value: "",
        },
      });

      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "status",
          placeholder: "Status",
          title: "Status",
          type: "input",
          value: "",
        },
      });

      return inputs
    },
    getInputsHeadDataGen() {
      var inputs = [];

      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 0,
      //     disabled: true,
      //     validate: "required",
      //     name: "no",
      //     width: 40,
      //     text_align: "text-center",
      //     placeholder: "0",
      //     title: "No",
      //     type: "input",
      //     value: "",
      //   },
      // });
      if (this.tabs == 1) {
        console.log("tabs1", this.tabs)
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            disabled: false,
            validate: "",
            name: "action",
            placeholder: "Action",
            title: "Action",
            type: "button",
            icon: "icon-x",
            color: "danger",
            text: "Delete",
            value: "",
          },
        });
      } else if (this.tabs == 2) {
        console.log("tabs2", this.tabs)
        inputs.push({
          components: {
            id: 0,
            id_input: 0,
            disabled: true,
            validate: "required",
            name: "",
            width: 40,
            text_align: "text-center",
            placeholder: "0",
            title: "No",
            type: "text",
            value: "",
          },
        });
      }
      // inputs.push({
      //   components: {
      //     id: 0,
      //     id_input: 1,
      //     disabled: false,
      //     validate: "required",
      //     name: "checkbox",
      //     placeholder: "Checkbox",
      //     title: "checkbox",
      //     head: "checkbox", //checkbox/title
      //     head_val: false, //for head=checkbox
      //     type: "checkbox",
      //     value: false,
      //     // name: "checkbox",
      //     // placeholder: "Checkbox",
      //     // title: "checkbox",
      //     // head: "checkbox", //checkbox/title
      //     // head_val: false, //for head=checkbox
      //     // type: "checkbox",
      //     // value: false,
      //   },
      // });
      inputs.push({
        components: {
          id: 0,
          id_input: 2,
          disabled: true,
          validate: "required",
          name: "tax_number",
          placeholder: "Tax Number",
          title: "Tax Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 3,
          disabled: true,
          validate: "required",
          name: "posting_date",
          placeholder: "Invoice Date",
          title: "Invoice Date",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 4,
          disabled: false,
          validate: "required",
          name: "code",
          placeholder: "Invoice No",
          title: "Invoice No",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 5,
          disabled: true,
          validate: "required",
          name: "",
          placeholder: "Tax Type",
          title: "Tax Type",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 6,
          disabled: true,
          validate: "required",
          name: "client_code",
          placeholder: "Customer Code",
          title: "Customer Code",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 7,
          disabled: true,
          validate: "required",
          name: "client_name",
          placeholder: "Customer Name",
          title: "Customer Name",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          id: 0,
          id_input: 8,
          disabled: true,
          validate: "required",
          name: "tax_number_generate_date",
          placeholder: "Generate Date",
          title: "Generate Date",
          type: "input",
          value: "",
        },
      });

      console.log("inputs", inputs)
      return inputs;
    },
    getIDItemRef() {
      return 0;
    },
    // data-taxallocation
    getDataTaxAllocation() {
      const year = this.formYear.inputs.value ? this.formYear.inputs.value.getFullYear() : null
      // if (!year) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Error",
      //     text: "Fill the period first",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return
      // }
      const params = {
        tax_year: year,
      };
      this.$http
        .get(`api/v1/master/data-reference`, { params })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            // this.total_sn_available = resp.data.count;
            const snAvailable = resp.data.records.reduce((acc, curr) => acc + curr.Remaining, 0)
            this.total_sn_available = snAvailable;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleSort(order) {
      if (this.table.order == order) {
        if (this.table.sort == "desc") this.table.sort = "asc"
        else this.table.sort = "desc"
      }
      this.table.order = order;
      this.handleView()
    },
    handleChangePage(page) {
      // if (this.tabs==this.gen){
      //   this.table.page = page;
      //   this.handleView()
      // }
      this.getInvoiceCode(this.formPostingDate.inputs.value)

      this.table.page = page;
      if (page > 1) {
        this.initCurrentPage = 1 // Untuk cek change page load awal atau tidak
      }

      if (this.initCurrentPage) {
        this.handleView()
      }
      // if (this.tabs == 0) {
      //   console.log(3)
      //   // this.getDataSN()
      // } else {
      //   console.log(3)
      //   // this.getDatGen()
      //   // this.handleView()
      // }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getDataSN() {
      console.log("a", this.formPostingDate.inputs.value)
      console.log(this.formFromCode.inputs.value)
      console.log(this.formToCode.inputs.value)
      console.log(this.formToCode)
      var startDate = moment(this.formPostingDate.inputs.value.startDate).format("yyyy-MM-DD")
      var endDate = moment(this.formPostingDate.inputs.value.endDate).format("yyyy-MM-DD")
      var startID = this.formFromCode.inputs.value ? this.formFromCode.inputs.value.id : 0
      var endID = this.formToCode.inputs.value ? this.formToCode.inputs.value.id : 0
      console.log("start", startDate)
      console.log("end", endDate)
      var month = this.formMonth.inputs.value ? this.formMonth.inputs.value.getMonth() : null
      var year = this.formYear.inputs.value ? this.formYear.inputs.value.getFullYear() : null

      if (!this.formToCode.inputs.option.length || !this.formFromCode.inputs.option) {
        return
      }

      const search = this.formSelectInvoice.inputs.value.length ? this.formSelectInvoice.inputs.value.map(invoice => invoice.id) : []

      if ((month === null || month === undefined)|| !year)  {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Fill the period first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      if (!(startID && endID)) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Fill the Invoice Number First",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      if (this.DataTerritory[this.formTerritory.inputs.value.ID]) {
        this.$vs.loading();
        // if (this.formMonth.inputs.value) {
        //   month =this.formMonth.inputs.value.getMonth()
        // }
        // if (this.formYear.inputs.value) {
        //   year =this.formYear.inputs.value.getFullYear()
        // }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search,
          order: this.table.order,
          sort: this.table.sort,
          invoice_type: this.formInvoiceType.inputs.value.id,// ar_invoice=>0
          territory_id:
            this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID,
          // tax_periode: year + "-" + ((month + 1)<10?"0"+(month + 1):""+(month + 1)),
          start_date: startDate,
          end_date: endDate,
          start_id: startID,
          end_id: endID,
          tax_type_code: this.formTaxInvoiceType.inputs.value.code,
        };

        console.log(params.search)
        this.$http
          .get(`api/v1/master/tax-invoice/view-invoice`, { params })
          .then((resp) => {
            console.log(resp)
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.records.forEach((dt, i) => {
                // if (i < 1000) {
                let id = 0
                id = dt.id
                dataInvoice.push({
                  id: i,
                  id_invoice: dt.ID,
                  action: false,
                  inputs: this.getInputs(dt, i, no),
                });
                no++
                // }
              });


              this.itemRefs = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    handleView() {
      const tabs = this.tabs
      const statusCanceled = 0

      switch (tabs) {
        case 0:
          this.getDataSN()
          break;
        case 1:
          this.getDatGen(1)
          break
        case 2:
          this.getDatGen(2)
          break
        case 3:
          this.getDataCancelInvoice(statusCanceled)
          break
        case 4:
          this.getDataGenHistories()
          break
        default:
          break;
      }
    },
    async getDataCancelInvoice() {
      try {
        this.$vs.loading();

        const search = this.formSelectInvoice.inputs.value.length ? this.formSelectInvoice.inputs.value.map(invoice => invoice.id) : []

        const params = {
          search
        }

        const response = await this.$http
          .get(`api/v1/master/tax-invoice/canceled-approval`, { params })

        if (response.code == 500) {
          throw new Error(response.message);
        }

        console.log(response.data)
        const data = response.data.records

        this.tmpdtGen.inputs = this.getInputsHeadDataGen()
        this.table.total = response.data.total_record
        this.table.totalPage = response.data.total_page;
        this.table.totalSearch = response.data.total_record_search;
        this.table.length = response.data.total_record_per_page;
        this.setStartEnd()
        let no = this.table.start

        console.log(data)
        const dataInvoice = data.map((item, index) => (
          {
            id: index,
            id_invoice: item.ID,
            action: false,
            inputs: this.getInputsDataGen(item, index, no++)
          }
        ))

        this.dtGen = [...dataInvoice]

      } catch (error) {
        console.log(error)
      } finally {
        this.$vs.loading.close();
      }
    },
    getDatGen(status) {
      var terr_id = 0
      var month = this.formMonth.inputs.value ? this.formMonth.inputs.value.getMonth() : null
      var year = this.formYear.inputs.value ? this.formYear.inputs.value.getFullYear() : null
      var startDate = moment(this.formPostingDate.inputs.value.startDate).format("yyyy-MM-DD")
      var endDate = moment(this.formPostingDate.inputs.value.endDate).format("yyyy-MM-DD")
      var startID = this.formFromCode.inputs.value ? this.formFromCode.inputs.value.id : 0
      var endID = this.formToCode.inputs.value ? this.formToCode.inputs.value.id : 0
      const search = this.formSelectInvoice.inputs.value.length ? this.formSelectInvoice.inputs.value.map(invoice => invoice.id) : []


      if (!this.formToCode.inputs.option.length || !this.formFromCode.inputs.option) {
        return
      }

      if (!startID || !endID) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Fill the invoice number first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      if (this.formTerritory.inputs.value) {
        terr_id = this.formTerritory.inputs.value.ID
      }
      // if (!(month && year)) {
      //   this.$vs.notify({
      //     color: "danger",
      //     title: "Error",
      //     text: "Fill the period first",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return
      // }
      if (this.DataTerritory[terr_id]) {
        this.$vs.loading();
        // if (this.formMonth.inputs.value) {
        //   month = this.formMonth.inputs.value.getMonth()
        // }
        // if (this.formYear.inputs.value) {
        //   year =this.formYear.inputs.value.getFullYear()
        // }

        const params = {
          length: this.table.length,
          page: this.table.page,
          search,
          order: this.table.order,
          sort: this.table.sort,
          territory_id:
            this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID,
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
          start_date: startDate,
          end_date: endDate,
          start_id: startID,
          end_id: endID,
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
          tax_type_code: this.formTaxInvoiceType.inputs.value.code,
          invoice_type: this.formInvoiceType.inputs.value.text,//ar invoice
          status: status,
        };
        this.$http
          .get(`api/v1/master/tax-invoice/data-generate`, { params })
          .then((resp) => {
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              this.tmpdtGen.inputs = this.getInputsHeadDataGen()
              var dataInvoice = [];
              this.table.total = resp.data.total_record
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.setStartEnd()
              var no = this.table.start
              resp.data.dataInvoice.forEach((dt, i) => {
                if (i < 1000) {
                  let id = 0
                  id = dt.id

                  dataInvoice.push({
                    id: i,
                    id_invoice: id,
                    action: false,
                    inputs: this.getInputsDataGen(dt, i, no),
                  });
                }
                no++
              });
              this.dtGen = dataInvoice;
              if (this.data_head) {
                this.data_head.head_val = false;
              }
              this.getDataTaxAllocation();
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
    async getDataGenHistories() {
      try {
        const params = {
          length: this.table.length,
          page: this.table.page,
          territory_id:
            this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID,
          operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
            ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
            : 0,
        };

        const response = await this.$http
          .get(`api/v1/master/tax-invoice/get-history-tax-generate`, { params })

        if (response.code == 500) {
          throw new Error(response.message)
        }

        const data = response.data.records

        this.table.total = response.data.total_record
        this.table.totalPage = response.data.total_page
        this.table.totalSearch = response.data.total_record_per_page
        this.table.length = response.data.total_record_per_page

        this.setStartEnd()

        this.tmpDtGenHistory.inputs = this.getInputsHeadDataGenHistory()


        let no = this.table.start
        const dataInvoice = data.map((item, index) => (
          {
            id: index,
            id_invoice: item.ID,
            action: false,
            inputs: this.getInputsDataGenHistory(item, index, no++)
          }
        ))

        this.dtGenHistory = [...dataInvoice]

      } catch (error) {
        console.log(error)
      }

    },
    handleSubmit() {
      this.$vs.loading();
      var month = "00"
      var year = "2022"
      if (this.formMonth.inputs.value) {
        month = this.formMonth.inputs.value.getMonth()
      }
      if (this.formYear.inputs.value) {
        year = this.formYear.inputs.value.getFullYear()
      }

      // var data = this.itemRefs.filter((dt) => {
      //   return dt.inputs[1].components.value;
      // });
      var dt = this.itemRefs.filter((c) => c.inputs[1].components.value).map((m) => ({ id: m.inputs[1].components.id_invoice }))
      // console.log(data);
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.ID;
      }
      var territory_id = 0
      if (this.formTerritory.inputs.value) {
        territory_id = this.DataTerritory[this.formTerritory.inputs.value.ID]
          ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
          : 0
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const params = {
        operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
          ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
          : 0,
        territory_id: this.DataTerritory[this.formTerritory.inputs.value.ID]
          ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
          : 0,
        tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
        invoice_ids: this.id_inv,
        data: dt,
        tax_invoice_type: tax_invoice_type,
        invoice_type: this.formInvoiceType.inputs.value.text,//ar invoice
      };
      console.log("params:", params)
      this.$vs.loading();
      this.$http
        .post(`api/v1/master/tax-invoice/generate`, params)
        .then((resp) => {
          this.$vs.loading.close();
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            if (resp.data) {
              this.id_inv = []
              resp.data.forEach((v) => {
                this.id_inv.push(v)
              })
              this.handleView()
            }

          } else if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Process in background, please check in tab generate histories",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    handleExport() {
      this.$vs.loading();
      var month = "00"
      var year = "2022"
      if (this.formMonth.inputs.value) {
        month = this.formMonth.inputs.value.getMonth()
      }
      if (this.formYear.inputs.value) {
        year = this.formYear.inputs.value.getFullYear()
      }
      var data_id = "";
      var item = this.dtGen.filter((dt) => {
        return dt.inputs[1].components.value;
      });

      item.forEach((dt, i) => {
        if (dt.inputs[1].components.value) {
          data_id += dt.id_invoice;
          if (i < item.length - 1) {
            data_id += ",";
          }
        }
      });
      var tax_invoice_type = "";
      if (this.formTaxInvoiceType) {
        tax_invoice_type = this.formTaxInvoiceType.inputs.value.text;
      }
      const params = {
        params: {
          operating_unit_id: this.DataOperatingUnit[this.formOu.inputs.value.ID]
            ? this.DataOperatingUnit[this.formOu.inputs.value.ID].ID
            : 0,
          territory_id: this.DataTerritory[this.formTerritory.inputs.value.ID]
            ? this.DataTerritory[this.formTerritory.inputs.value.ID].TerrID
            : 0,
          tax_periode: year + "-" + ((month + 1) < 10 ? "0" + (month + 1) : "" + (month + 1)),
          tax_invoice_type: tax_invoice_type,
          type_exp: "csv",
          invoice_type: this.formInvoiceType.inputs.value.id,//arinvoice=>0
          tax_type: this.formTaxInvoiceType.inputs.value.ID,
          tax_type_code: this.formTaxInvoiceType.inputs.value.code,
          data_id: data_id,
        },
      };
      this.$http
        .get(`api/v1/master/tax-invoice/export`, params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleView();
            this.getDataTaxAllocation();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    handleEdit() { },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getData() {
      this.$vs.loading();
      const params = {
        // id: this.create.id,
        load_operating_unit: true,
      }
      // console.log(this.formInput)
      this.$http
        .get(`api/v1/master/tax-invoice`, { params })
        .then((resp) => {
          // console.log(this.option.event, 'option')
          this.$vs.loading.close();
          if (resp.code == 500) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else if (resp.code == 200) {
            this.formInput.inputs.value = resp.data.company;
            this.formInput.inputs.disabled = true;
            console.log(this.formInput);
            var dtou = resp.data.dtOu;
            this.DataOperatingUnit = resp.data.dtOu;
            if (dtou) {
              // let selectedOu = {}
              let dataSelect = [];
              dtou.forEach((dt, i) => {
                // if (dt.OperatingUnitTerritory.TerritoryID == resp.data.personal_territory_id) {
                //   selectedOu = {
                //     ID: i,
                //     text: dt.Code + " - " + dt.Name,
                //   }
                // }
                dataSelect.push({
                  ID: i,
                  text: dt.Code + " - " + dt.Name,
                });
              });
              this.formOu.inputs.value = dataSelect[0];
              // if (selectedOu.ID) {
              //   this.formOu.inputs.value = selectedOu;
              // } else {
              //   this.formOu.inputs.value = dataSelect[0];
              // }
              this.getDataTerritory(this.formOu.inputs.value);
              this.tempDataOu = dataSelect
              this.formOu.inputs.option = dataSelect;
            }
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log("error boss:", err);
        });
    },
    updateforminput(selected, component) {
      if (component.name == "operating_unit") {
        this.getDataTerritory(selected);
      } else if (component.name == "allocation_type") {
        this.automateInputTaxNumber(selected);
      }
    },
    getDataTerritory(selected = null) {
      this.$vs.loading();
      if (selected) {
        const params = {
          id_ou: this.DataOperatingUnit[selected.ID].ID,
        };
        // console.log(this.formInput)
        this.$http
          .get(`api/v1/master/tax-invoice/territory`, { params })
          .then((resp) => {
            this.formTerritory.inputs.value = null
            this.formTerritory.inputs.option = []
            this.formFromCode.inputs.value = null
            this.formToCode.inputs.value = null
            this.formSelectInvoice.inputs.option = []

            console.log("getDataTerritory", resp.data.dataTerr);
            // console.log(this.option.event, 'option')
            this.$vs.loading.close();
            if (resp.code == 500) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else if (resp.code == 200) {
              var dataSelect = [];
              if (resp.data.dataTerr.length) {
                this.DataTerritory = resp.data.dataTerr;
                this.DataTerritory.forEach((dt, i) => {
                  dataSelect.push({
                    ID: i,
                    text: dt.Code + " - " + dt.Name,
                  });
                });
              }
              if (dataSelect.length) {
                this.formTerritory.inputs.value = dataSelect[0];
                this.formTerritory.inputs.option = dataSelect;
                this.getInvoiceCode(this.formPostingDate.inputs.value)
              }
            }
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: err,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            console.log("error boss:", err);
          });
      }
    },
  },
};
</script>
