<template>
  <vx-card>
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="OCR Data">
              <div class="tab-text">
                <div class="vx-row mb-6" style="width: 50%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Doc Type</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="docType"
                      :options="optionsDocType"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="120"
                      :limit="3"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 50%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Scan Date</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                      style="min-height: 40px"
                      class="w-full"
                      ref="picker"
                      opens="center"
                      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="true"
                      v-model="filteDate"
                      :linkedCalendars="true"
                      :ranges="false"
                    >
                      <template v-slot:input="picker" style="min-width: 350px">
                        {{ dateFormat(picker.startDate) }} -
                        {{ dateFormat(picker.endDate) }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <!-- <div class="vx-row mb-6" style="width: 50%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-button color="success" @click="handleDrawTable()"
                      >Get Data</vs-button
                    >
                  </div>
                </div> -->
                <data-table
                  :baseUrl="this.baseUrl"
                  :detail="this.detail"
                  :startDate="this.filteDate.startDate"
                  :endDate="this.filteDate.endDate"
                  :doc-type="this.docType"
                  :draw="draw"
                ></data-table>
              </div>
            </vs-tab>
            <vs-tab label="Export">
              <div class="tab-text">
                <data-table-export></data-table-export>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTable from "./DataTable.vue";
import DataTableExport from "./DataTableExport.vue";
import moment from "moment";
export default {
  components: {
    DataTableExport,
    DataTable,
    DateRangePicker
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      docType: "all",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/v1/report/ocr",

      filteDate: {
        startDate: null,
        endDate: null
      },
      optionsDocType: [
        'all',
        'single',
        'multiple',
      ],
      draw: 0
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("MMM DD, YYYY");
      }
      return a;
    },
    handleDrawTable() {
      this.draw++;
    }
  },
  mounted() {
    const today = new Date();
    this.filteDate.startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    this.filteDate.endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    this.draw++;
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
