<template>
  <div class="m-5">
    <div v-if="detail == false">
      <vs-button
        v-if="status == 1"
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <div class="vx-row w-full m-5">
        <div class="vx-row w-1/2 mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-2/3">
              <!-- <multiselect
                class="selectExample"
                v-model="selectedTerritory"
                :options="optionTerritory"
                :show-labels="false"
                :multiple="true"
                :allow-empty="true"
                :group-select="false"
                :max-height="160"
                placeholder=" Type to search"
                track-by="id"
                label="name"
                name="territory"
                @input="selectedSomeTerritory"
              /> -->

              <territory-filter :full="true" :showTitle="false" />
            </div>
          </div>
        </div>
        <div class="vx-row w-1/2 mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-2/3">
              <multiselect
                class="selectExample"
                v-model="selectedCustomerCategory"
                :options="optionCustomerCategory"
                :multiple="true"
                :allow-empty="true"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                track-by="ID"
                label="classification"
                @input="selectSomeCustomerCategory"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row w-full m-5">
        <div class="vx-row w-1/2 mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-2/3">
              <multiselect
                class="selectExample"
                v-model="selectedCustomer"
                :options="optionCustomer"
                :multiple="true"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                :internal-search="false"
                track-by="id"
                label="code"
                @search-change="handlerSearchCustomer"
                :disabled="false"
              />
            </div>
          </div>
        </div>
        <div class="vx-row w-1/2 mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span> Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-2/3">
              <vs-input class="w-full" v-model="date" type="date" />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row ml-5">
        <div class="mr-5">
          <vs-button
            @click="onClickBtnFilter"
            size="medium"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Filter
          </vs-button>
        </div>
        <div>
          <vs-button
            @click="onClickBtnClearFilter"
            size="medium"
            color="primary"
            icon-pack="feather"
            icon="icon-trash-2"
            title="Search"
          >
            Clear
          </vs-button>
        </div>
      </div>
    </div>
    <div v-else>
      <vs-button
        class="mt-2"
        color="danger"
        type="border"
        v-on:click="handleClose"
        >Close</vs-button
      >
    </div>
    <div class="vx-row mb-12">
      <div v-if="detail == false" class="w-full">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="code">Action</vs-th>
            <vs-th sort-key="code">CL Request ID</vs-th>
            <vs-th sort-key="name">Customer Code</vs-th>
            <vs-th sort-key="valid_from">Current CL/TOP</vs-th>
            <vs-th sort-key="valid_to">Request CL/TOP</vs-th>
            <vs-th sort-key="updated_at">Valid From</vs-th>
            <vs-th sort-key="updated_by">Reason</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <template class="flex">
                  <vx-tooltip text="Edit" v-if="status == 1 || status == 3">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickEdit(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip
                    text="View"
                    v-if="
                      status == 2 || status == 4 || status == 5 || status == 6
                    "
                  >
                    <feather-icon
                      icon="EyeIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickView(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Cancel" v-if="status == 1 || status == 3">
                    <feather-icon
                      icon="XIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickCancel(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Accept" v-if="status == 1 || status == 3">
                    <feather-icon
                      icon="CheckIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-4"
                      @click="clickAccept(tr)"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>

              <vs-td :data="data[indextr].customer_code">{{
                data[indextr].customer_code
              }}</vs-td>
              <vs-td>
                {{
                  data[indextr].current_credit_limit
                    ? data[indextr].current_credit_limit
                    : 0
                }}
                /
                {{
                  data[indextr].current_payment_term
                    ? data[indextr].current_payment_term
                    : "-"
                }}
              </vs-td>
              <vs-td>
                {{
                  data[indextr].credit_limit_request
                    ? data[indextr].credit_limit_request
                    : "-"
                }}
                /
                {{
                  data[indextr].payment_term ? data[indextr].payment_term : "-"
                }}
              </vs-td>
              <vs-td :data="data[indextr].valid_from">{{
                data[indextr].valid_from | formatDate
              }}</vs-td>
              <vs-td :data="data[indextr].reason">{{
                data[indextr].reason
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div v-if="detail" class="w-full">
          <div>
            <!-- form component -->
            <form-component
              v-if="isEdit == false && isShow == false"
              @close="handleClose"
              :id="id"
              :url="baseUrl"
            ></form-component>
            <edit
              v-if="isEdit == true && isShow == false"
              @close="handleClose"
              :id="id"
              :url="baseUrl"
              :title="title"
            ></edit>
            <show
              v-if="isShow == true"
              @close="handleClose"
              :id="id"
              :url="baseUrl"
              :title="title"
            ></show>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import FormComponent from "../form/form.vue";
import Edit from "../form/formEdit.vue";
import Show from "../form/formShow.vue";
import territoryFilter from "../../../../../components/operatingUnitFilter/territoryFilter.vue";

export default {
  props: {
    selected: Object,
    status: Number,
  },
  components: {
    FormComponent,
    Edit,
    Show,
    "territory-filter": territoryFilter,
  },

  data() {
    return {
      baseUrl: "/api/v1/master/payment-term",
      detailShow: "core vx-col md:w-1/2 w-1/2 mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      id: null,
      isEdit: false,
      isShow: false,
      table: this.tableDefaultState(),
      date: moment().format("YYYY-MM-DD"),
      optionTerritory: [],
      selectedTerritory: [],
      optionCustomer: [],
      selectedCustomer: [],
      optionCustomerCategory: [],
      selectedCustomerCategory: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        status: null,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
      this.isEdit = false;
    },
    getData() {
      this.$vs.loading();
      if (this.status) {
        this.table.status = this.status;
      }
      // let terr_id = [];
      // if (this.selectedTerritory.length > 0) {
      //   if (this.selectedTerritory[0].id != 0) {
      //     terr_id = this.selectedTerritory.map((element) => element.id);
      //   }
      // }
      const terr_id =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      let cust_id = [];
      if (this.selectedCustomer.length > 0) {
        if (this.selectedCustomer[0].id != 0) {
          cust_id = this.selectedCustomer.map((element) => element.id);
        }
      }

      this.$http
        .get("/api/v1/master/credit-limit-requests", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: this.table.status,
            territory_id: terr_id,
            customer_id: cust_id,
            date: this.date,
          },
        })
        .then((resp) => {
          this.table.data = resp.data.records;
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.setStartEnd();
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch != 0) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    clickEdit(item) {
      this.id = item.id;
      this.detail = true;
      this.isEdit = true;
    },
    clickView(item) {
      this.id = item.id;
      this.detail = true;
      this.isShow = true;
    },
    clickCancel(item) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/credit-limit-requests/status", {
          id: item.id,
          status: 6,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data has been updated",
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            this.getData();
          }
        });
    },
    clickAccept(item) {
      this.$vs.loading();
      let instatus;
      if (this.status == 1) {
        instatus = 2;
      } else if (this.status == 2) {
        instatus = 4;
      } else if (this.status == 3) {
        instatus = 2;
      } else if (this.status == 5) {
        instatus = 3;
      }
      this.$http
        .post("/api/v1/master/credit-limit-requests/status", {
          id: item.id,
          customer_id: item.customer_id,
          status: instatus,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data has been updated",
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            this.getData();
          }
        });
    },
    getAllTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            let all = {
              id: 0,
              name: "All",
            };
            resp.data.records.unshift(all);
            this.optionTerritory = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    selectedSomeTerritory() {
      this.optionCustomer = [];
      this.getAllCustomerByTerritory();
    },
    selectSomeCustomerCategory() {
      this.optionCustomer = [];
      this.getAllCustomerByTerritory();
    },
    getCustomerCategory() {
      this.$http
        .get("/api/v1/master/customer-category-all", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            let all = {
              id: 0,
              classification: "All",
            };
            resp.data.unshift(all);
            this.optionCustomerCategory = resp.data;
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllCustomerByTerritory(query) {
      let terr_id = [];
      if (this.selectedTerritory.length > 0) {
        if (this.selectedTerritory[0].id != 0) {
          terr_id = this.selectedTerritory.map((element) => element.id);
        }
      }
      console.log("terr_id", terr_id);

      let cust_cat = [];
      if (this.selectedCustomerCategory.length > 0) {
        if (this.selectedCustomerCategory[0].ID != 0) {
          cust_cat = this.selectedCustomerCategory.map((element) => element.ID);
        }
      }
      console.log("cust_cat", cust_cat);

      this.$http
        .get("/api/v1/master/customer-by-territories-v2", {
          params: {
            territory_id: terr_id,
            customer_category_id: cust_cat,
            // status: this.table.status,
            search: query ? query : "",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            let all = {
              id: 0,
              code: "All",
              name: "-",
            };
            resp.data.unshift(all);
            this.optionCustomer = resp.data;
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handlerSearchCustomer(searching) {
      this.optionCustomer = [];
      this.search = searching;
      this.getAllCustomerByTerritory(searching);
    },
    customLabelCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    onClickBtnFilter() {
      this.getData();
    },
    onClickBtnClearFilter() {
      this.selectedTerritory = [];
      this.selectedCustomerCategory = [];
      this.selectedCustomer = [];
      this.date = null;
      this.getData();
    },
  },
  watch: {
    selectedTerritory: function () {
      if (this.selectedTerritory.length > 1) {
        for (let i = 0; i < this.selectedTerritory.length; i++) {
          if (this.selectedTerritory[i].id == 0) {
            this.selectedTerritory.splice(i, 1);
          }
        }
      }
    },
    selectedCustomerCategory: function () {
      if (this.selectedCustomerCategory.length > 1) {
        for (let i = 0; i < this.selectedCustomerCategory.length; i++) {
          if (this.selectedCustomerCategory[i].id == 0) {
            this.selectedCustomerCategory.splice(i, 1);
          }
        }
      }
    },
    selectedCustomer: function () {
      if (this.selectedCustomer.length > 1) {
        for (let i = 0; i < this.selectedCustomer.length; i++) {
          if (this.selectedCustomer[i].id == 0) {
            this.selectedCustomer.splice(i, 1);
          }
        }
      }
    },
  },
  mounted() {
    this.getAllTerritory();
    this.getCustomerCategory();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
