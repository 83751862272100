<template>
  <multiselect
    :options="[]"
    :multiple="true"
    placeholder="Type to search"
    ref="refInvoice"
    track-by="id"
    class="w-full h-full"
    :max-height="130"
    :taggable="true"
    v-model="internalValue"
    @search-change="onSearchChange"
    label="name"
    @tag="addTag"
  >
    <!-- <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">
          <span class="option__title"
            >({{ props.option.Code }}), {{ props.option.Name }}</span
          >
        </span>
      </div>
    </template> -->

    <template slot="tag" slot-scope="props">
      <div class="multiselect__tag">
        <span>{{ props.option.name }}</span>
        <i
          class="multiselect__tag-icon"
          @click.prevent
          @mousedown.prevent.stop="props.remove(props.option, $event)"
        />
      </div>
    </template>
  </multiselect>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tagDelimiter: {
      type: String,
      default: ";",
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addTag(tag) {
      const currentIndex = this.internalValue.length + 1;
      const obj = {
        id: currentIndex,
        name: tag,
      };
      const updatedValue = [...this.value, obj];
      console.log("updatedValue", updatedValue);
      this.$emit("input", updatedValue);
    },
    onSearchChange(search) {
      if (!search.includes(this.tagDelimiter)) {
        return;
      }
      if (search === this.tagDelimiter) {
        return;
      }

      const tags = search.split(this.tagDelimiter);
      const currentIndex = this.internalValue.length + 1;
      const temp = tags.map((tag) => {
        return {
          id: currentIndex,
          name: tag,
        };
      });
      const updatedValue = [...this.internalValue, ...temp];
      this.$emit("input", updatedValue);
      this.$refs.refInvoice.search = "";
      this.$refs.refInvoice.deactivate();
    },
  },
};
</script>
