<template>
  <vs-input
    class="w-11/12"
    v-model="displayValue"
    type=""
    @blur="onblur"
    @keyup="onkeyup"
    :disabled="isDisbaled"
  />
</template>

<script>
export default {
  computed: {
    displayValue: {
      get() {
        return this.value ? this.formatPrice(this.value.replace(/,/g, "")) : "";
      },
      set(val) {
        this.value = val;
        const temp = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.debitNotePaidValues)
        );
        const index = temp.findIndex((item) => item.id === this.debitNoteID);
        if (index > -1) {
          const tempVal = val.replace(/,/g, "");
          temp[index].clearing_value = parseFloat(tempVal ? tempVal : 0);
          this.$store.commit("clearingDN/setDebitNotePaidValues", temp);
        } else {
          return;
        }
      },
    },
  },
  data() {
    return { value: "" };
  },
  mounted() {
    const temp = JSON.parse(
      JSON.stringify(this.$store.state.clearingDN.debitNotePaidValues)
    );
    const dn = temp.find((item) => item.id === this.debitNoteID);
    if (!dn) {
      this.value = this.formatPrice(
        !this.debitNoteValue ? 0 : this.debitNoteValue
      );
    } else {
      this.value = this.formatPrice(!dn.clearing_value ? 0 : dn.clearing_value);
    }
  },
  watch: {
    debitNoteValue() {
      const temp = JSON.parse(
        JSON.stringify(this.$store.state.clearingDN.debitNotePaidValues)
      );
      const dn = temp.find((item) => item.id === this.debitNoteID);
      if (!dn) {
        this.value = this.formatPrice(
          !this.debitNoteValue ? 0 : this.debitNoteValue
        );
      } else {
        this.value = this.formatPrice(
          !dn.clearing_value ? 0 : dn.clearing_value
        );
      }
    },
    isDisbaled() {
      const temp = JSON.parse(
        JSON.stringify(this.$store.state.clearingDN.debitNotePaidValues)
      );
      const dn = temp.find((item) => item.id === this.debitNoteID);
      if (!dn) {
        this.value = this.formatPrice(
          !this.debitNoteValue ? 0 : this.debitNoteValue
        );
      } else {
        this.value = this.formatPrice(
          !dn.clearing_value ? 0 : dn.clearing_value
        );
      }
    },
  },
  props: {
    debitNoteValue: {
      type: Number,
      default: 0,
    },
    debitNoteID: {
      type: Number,
      default: 0,
    },
    isDisbaled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onblur() {
      const temp = this.value.replace(/,/g, "");
      this.value = temp ? this.priceFormat(temp) : "";
    },
    onkeyup() {
      const temp = this.value.replace(/,/g, "");
      this.value = temp ? this.formatPrice(temp) : "";
    },
  },
};
</script>
