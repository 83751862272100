<template>
  <div class="tab-text">
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="btn-group ml-5">
              <vs-button
                size="small"
                color="success"
                type="border"
                @click="handleExport('excel')"
                >Excel</vs-button
              >
              <!-- <vs-button size="small" color="primary" type="border" @click="handleExport('pdf')">PDF</vs-button> -->
            </div>
          </template>
          <template slot="thead">
            <vs-th sort-key="date">Shipment Date</vs-th>
            <vs-th sort-key="item_code">Shipment No</vs-th>
            <vs-th sort-key="value">Status Shipment</vs-th>
            <vs-th sort-key="item_name">Jumlah PI</vs-th>
            <vs-th sort-key="item_name">Jumlah Customer</vs-th>
            <vs-th sort-key="item_name">Ownership</vs-th>
            <vs-th sort-key="item_name">Driver Type</vs-th>
            <vs-th sort-key="item_name">Trans Plan Point</vs-th>
            <vs-th sort-key="item_name">Driver Name</vs-th>
            <vs-th sort-key="item_name">Vehicle No</vs-th>
            <vs-th sort-key="item_name">Vehicle Type</vs-th>
            <vs-th sort-key="item_name">Driver/Vendor Code</vs-th>
            <vs-th sort-key="item_name">Driver/Vendor Name</vs-th>
            <vs-th sort-key="item_name">KM Berangkat</vs-th>
            <vs-th sort-key="item_name">KM Kembali</vs-th>
            <vs-th sort-key="item_name">Distance</vs-th>
            <vs-th sort-key="item_name">Distance UOM</vs-th>
            <vs-th sort-key="item_name">Shipment Cost No</vs-th>
            <vs-th sort-key="item_name">Shipment Cost Value</vs-th>
            <vs-th sort-key="item_name">Cash Out Doc No(Odoo)</vs-th>
            <vs-th sort-key="item_name">Cash Out Date (Odoo)</vs-th>
            <vs-th sort-key="item_name">Cash Out Value (Odoo)</vs-th>
            <vs-th sort-key="item_name">Biaya Kuli</vs-th>
            <vs-th sort-key="item_name">Biaya Parkir</vs-th>
            <vs-th sort-key="item_name">Biaya Keamanan</vs-th>
            <vs-th sort-key="item_name">Retribusi</vs-th>
            <vs-th sort-key="item_name">Biaya Toll</vs-th>
            <vs-th sort-key="item_name">BBM</vs-th>
            <vs-th sort-key="item_name">Biaya Lain-lain</vs-th>
            <vs-th sort-key="item_name">Nilai Realisasi Shipemnt Cost</vs-th>
            <vs-th sort-key="item_name">Refund</vs-th>
            <vs-th sort-key="item_name">No Doc Realisasi (Odoo)</vs-th>
            <vs-th sort-key="item_name">Status Realisasi Shipment Cost</vs-th>
            <vs-th sort-key="item_name">Realisasi Date (Odoo)</vs-th>
          </template>

          <template v-for="(dt, index) in table.data">
            <vs-tr :key="index">
              <vs-td>{{ dt.shipment_date }}</vs-td>
              <vs-td>{{ dt.shipment_number }}</vs-td>
              <vs-td>{{ dt.shipment_status }}</vs-td>
              <vs-td>{{ dt.jml_do }}</vs-td>
              <vs-td>{{ dt.jml_customer }}</vs-td>
              <vs-td>{{ dt.ownership }}</vs-td>
              <vs-td>{{ dt.shipment_type }}</vs-td>
              <vs-td>{{ dt.trans_plan_point }}</vs-td>
              <vs-td>{{ dt.driver_name }}</vs-td>
              <vs-td>{{ dt.vehicle_number }}</vs-td>
              <vs-td>{{ dt.ship_type_desc }}</vs-td>
              <vs-td>{{ dt.fwd_agent_no }}</vs-td>
              <vs-td>{{ dt.fwd_agent_name }}</vs-td>
              <vs-td>{{ dt.km_berangkat }}</vs-td>
              <vs-td>{{ dt.km_kembali }}</vs-td>
              <vs-td>{{ dt.distance }}</vs-td>
              <vs-td>{{ "KM" }}</vs-td>
              <vs-td>{{ dt.shipment_cost_no }}</vs-td>
              <vs-td>{{ dt.shipment_cost_value }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_no }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_date }}</vs-td>
              <vs-td>{{ dt.cash_out_doc_value }}</vs-td>
              <vs-td>{{ dt.biaya_kuli }}</vs-td>
              <vs-td>{{ dt.biaya_parkir }}</vs-td>
              <vs-td>{{ dt.biaya_keamanan }}</vs-td>
              <vs-td>{{ dt.biaya_retribusi }}</vs-td>
              <vs-td>{{ dt.biaya_toll }}</vs-td>
              <vs-td>{{ dt.biaya_bbm }}</vs-td>
              <vs-td>{{ dt.biaya_other }}</vs-td>
              <vs-td>{{ dt.nilai_realisasi_shipment_cost }}</vs-td>
              <vs-td>{{ dt.refund }}</vs-td>
              <vs-td>{{ dt.no_doc_realisasi_odoo }}</vs-td>
              <vs-td>{{ dt.status_shipment }}</vs-td>
              <vs-td>{{ dt.date_realisasi_odoo }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    isFilters: {
      type: Boolean,
      default: false,
    },
    warehouseID: Number,
    warehouseCode: String,
    dateStart: String,
    dateEnd: String,
  },
  components: {},
  data() {
    return {
      title: "Shipment Planning",
      baseUrl: "/api/v1/report/shipment-planning",
      table: this.tableState(),
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).utcOffset(0).format("YYYY-MM-DD");
      }
    },
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      // this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "";
        this.table.sort = "";
      }
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date_from: this.dateFormat(this.dateStart),
            date_to: this.dateFormat(this.dateEnd),
            warehouse_id: this.warehouseID,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleExport(file) {
      // this.$vs.loading();
      this.$http
        .get("/api/v1/report/shipment-planning-export", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            warehouse_id: this.warehouseID,
            date_from: this.dateFormat(this.dateStart),
            date_to: this.dateFormat(this.dateEnd),
            file: file,
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          var fileURL = window.URL.createObjectURL(new Blob([resp]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          if (file == "excel") {
            fileLink.setAttribute(
              "download",
              this.warehouseCode + "_shipment_planning.xlsx"
            );
          } else {
            fileLink.setAttribute(
              "download",
              this.warehouseCode + "_shipment_planning.pdf"
            );
          }
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    isFilters(val) {
      if (val) {
        // this.getData();
        this.$emit("isFilters", false);
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  /* min-height: 800px; */
  padding: 5px;
}
</style>
