var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"500px"}},[(
      (this.payment.Status == 2 &&
        (this.payment.PaymentMethod == 'Cheque' ||
          this.payment.PaymentMethod == 'Transfer' ||
          this.payment.PaymentMethod == 'Giro')) ||
      (this.payment.Status == 6 &&
        this.payment.PaymentMethod != 'Cheque' &&
        this.payment.PaymentMethod != 'Transfer' &&
        this.payment.PaymentMethod != 'Write-off' &&
        this.payment.PaymentMethod != 'Giro')
    )?_c('div',[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Reversal Date")]),_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],key:_vm.dateKey,staticClass:"w-full",attrs:{"inline":false,"name":"Invoice Date","placeholder":"Date Clearing","disabled-dates":_vm.disabledDates},model:{value:(_vm.reversalDate),callback:function ($$v) {_vm.reversalDate=$$v},expression:"reversalDate"}})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Note")]),_c('vs-textarea',{staticClass:"w-full",attrs:{"label":"Note"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"vx-col w-full"},[_c('br'),_c('vs-button',{staticClass:"mb-2",attrs:{"color":"red"},on:{"click":_vm.handleReversal}},[_vm._v("Reversal Payment")])],1)])]):_vm._e(),_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.payment),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[_vm._v(" "+_vm._s(tr.InvoiceCode)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.InvoicePaymentAmount))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.Note)+" ")])],1)}),1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }